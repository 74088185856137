<style >
	.templateFile div > div div:first-child > div > span > .file-error-wrapper {
        visibility: hidden;
    }
    .td-text-color {
        color: white;
    }
    .th-fixed-width {
        width: 200px !important;
    }
    .grp-text-area-cust {
	    margin-bottom: 5px !important;
    }
    .grp-text-area-cust textarea {
        resize: none;
        height: 35px;
    }
    .clss-pnl-w-permission {
        border: 1px solid gray;
        padding: 15px;
        border-radius: 5px;
    }
    .td-fxd-wdth {
        width: 72px !important;
    }
    .td-fxd-wdth-upldd {
        width: 130px !important;
    }
    .td-actn-fxd-wdth {
        width: 150px !important
    }
    .parent-row td {
        border-left: none;  /* Remove left border of each cell */
        border-right: none; /* Remove right border of each cell */
        cursor: pointer;
    }
    .parent-row-light {
        background: #79d5b9;
    }
    .parent-row-light:hover {
        background: #79d5b9 !important;
    }
    .parent-row-dark {
        background: #2a2173;
    }
    .parent-row-dark:hover {
        background: #2a2173 !important;
    }
    .parent-row-light td:first-child {
        border-left: 1px solid #D8DBE0; /* Add left border to the first cell */
    }
    .parent-row-light td:last-child {
        border-right: 1px solid #D8DBE0; /* Add right border to the last cell */
    }
    .parent-row-dark td:first-child {
        border-left: 1px solid #3A3B44; /* Add left border to the first cell */
    }
    .parent-row-dark td:last-child {
        border-right: 1px solid #3A3B44; /* Add right border to the last cell */
    }
    
</style>
<template>
    <div class="animated fadeIn">
        <div class="wrapper">
            <div >
                <CRow>
                    <CCol lg="12">
                      
                        <CButton
                            size="sm"
                            color="warning" 
                            @click="goBack()"
                        > 
                            <font-awesome-icon icon="arrow-left"/> Back to List
                        </CButton>
                    </CCol>
                </CRow>
                <br>
                <CRow>
                    <CCol lg="12">
                        <CTabs justified variant="pills" :active-tab="child_tab" @update:activeTab="changeTab" >
                            <br>
                            <CTab title="Document Information">
                                <CRow v-if="data_loaded">
                                    <CCol xl="12" lg="12" md="12" sm="12">
                                        <CCard>
                                            <CCardHeader>
                                                <font-awesome-icon icon="paper-plane"/>  Manage Document Routing
                                                <template v-if="parent_tab == 0">
                                                    <CButton 
                                                        size="sm"
                                                        color="danger" 
                                                        class="float-right" 
                                                        @click="toggleEdit(), returnInformation()" 
                                                        v-show="edit"
                                                    >
                                                        <font-awesome-icon icon="window-close"/> Cancel
                                                    </CButton>
                                                    
                                                    <!-- :disabled="warnings.length > 0 || !isValid" -->
                                                    <CButton 
                                                        v-if="$store.getters['can']('update-document-routing')"
                                                        size="sm"
                                                        color="success" 
                                                        class="float-right" 
                                                        v-show="edit" 
                                                        @click="updateTemplate()"
                                                    >
                                                        <font-awesome-icon icon="save"/> Update
                                                    </CButton>
                                                    <CButton 
                                                        v-if="$store.getters['can']('edit-document-routing')"
                                                        size="sm"
                                                        color="info" 
                                                        class="float-right" 
                                                        @click="toggleEdit" 
                                                        v-show="!edit"
                                                    >
                                                        <font-awesome-icon icon="edit"/> Edit
                                                    </CButton>
                                                    
                                                    <a-popover title="WARNING!" placement="left">
                                                        <template slot="content">
                                                            <span v-for="(item, index) in warnings" v-bind:key="index">
                                                                {{index + 1}}. {{item}} <br>
                                                            </span>
                                                        </template>
                                                        <font-awesome-icon 
                                                            v-if="warnings.length > 0 && parent_tab == 0"
                                                            style="color: orange; margin-right:50px" 
                                                            icon="triangle-exclamation" 
                                                            class="float-right" 
                                                            size="2xl"/>
                                                    </a-popover>

                                                    <CButton 
                                                        size="sm"
                                                        color="warning" 
                                                        class="float-right" 
                                                        style="margin-right:20px;"
                                                        @click="startRouting()"
                                                        v-if="!edit && $store.getters['can']('start-document-routing')"
                                                    >
                                                        <font-awesome-icon icon="share-square"/> Start Routing
                                                    </CButton>
                                                    <CButton 
                                                        style="margin-right:10px;"
                                                        size="sm"
                                                        color="danger" 
                                                        class="float-right" 
                                                        @click="cancelDocument()"
                                                        v-if="!edit && routing.started_at == null && $store.getters['can']('cancel-document-routing')"
                                                    >
                                                        <font-awesome-icon icon="window-close"/> Cancel Document
                                                    </CButton>
                                                </template>
                                            </CCardHeader>
                                            <CCardBody>
                                                <CRow>
                                                    <CCol lg="6">
                                                        <br v-if="parent_tab == 0">
                                                        <template v-if="parent_tab == 0 || parent_tab == 4">
                                                            <CRow>
                                                                <!-- <CCol lg="12">
                                                                    <h5>Document Status: 
                                                                        <CBadge :color="getColorStatus(routing.status)">
                                                                            {{routing.status.toUpperCase()}}
                                                                        </CBadge>
                                                                    </h5>
                                                                </CCol> -->
                                                                <CCol lg="12">
                                                                    <CInput
                                                                        :disabled="!edit || routing.from_form_data"
                                                                        :lazy="false"
                                                                        :value.sync="$v.routing.reference_number.$model"
                                                                        :isValid="checkIfValid('reference_number')"
                                                                        label="Reference Number" 
                                                                        ondrop="return false;" 
                                                                        type="text" 
                                                                        placeholder="100-8953245" 
                                                                        autocomplete="off"  
                                                                        v-nospecialcharacter
                                                                        v-tocapitalize
                                                                        v-model="routing.reference_number"
                                                                        invalidFeedback="Must be more than five(5) characters."
                                                                        :validFeedback="routing.from_form_data ? 'Link in Form Application. Reference Number Unable to change.' : ''"
                                                                    />
                                                                </CCol>
                                                            </CRow>
                                                            <CRow>
                                                                <CCol lg="12">
                                                                    <CTextarea
                                                                        class="grp-text-area"
                                                                        :disabled="!edit"
                                                                        label="Description" 
                                                                        type="text" 
                                                                        placeholder="Enter brief description..." 
                                                                        autocomplete="off"  
                                                                        v-model="routing.description"
                                                                    />
                                                                </CCol>
                                                            </CRow>
                                                        </template>
                                                        
                                                        <template v-else>
                                                            
                                                            <div :class="$store.getters['can']('update-document-routing-reference-number') && document_creator ? 'clss-pnl-w-permission' : ''">
                                                                <template v-if="$store.getters['can']('update-document-routing-reference-number') && document_creator">
                                                                    <CRow>
                                                                        <CCol lg="12">
                                                                            <CButton 
                                                                                color="danger" 
                                                                                class="float-right" 
                                                                                v-show="editPanel"
                                                                                size="sm"
                                                                                
                                                                                @click="toggleEditPanel(), returnPanel()" 
                                                                            >
                                                                                <font-awesome-icon icon="window-close"/> Cancel
                                                                            </CButton>
                                                                            <CButton 
                                                                                :disabled="!routing.reference_number"
                                                                                color="success" 
                                                                                class="float-right" 
                                                                                size="sm"
                                                                                @click="updateReference()"
                                                                                style="margin-right: 2px"
                                                                                v-show="editPanel" 
                                                                            >
                                                                                <font-awesome-icon icon="save"/> Update
                                                                            </CButton>
                                                                            <CButton 
                                                                                size="sm"
                                                                                color="info" 
                                                                                class="float-right" 
                                                                                v-show="!editPanel"
                                                                                @click="toggleEditPanel()" 
                                                                            >
                                                                                <font-awesome-icon icon="edit"/> Edit
                                                                            </CButton>
                                                                        </CCol>
                                                                    </CRow>
                                                                </template>
                                                                <CRow>
                                                                    <CCol lg="12">
                                                                        <CInput
                                                                            :isValid="routing.reference_number ? true : false"
                                                                            :validFeedback="routing.from_form_data ? 'Link in Form Application. Reference Number Unable to change.' : ''"
                                                                            :disabled="!editPanel || routing.from_form_data"
                                                                            label="Reference Number" 
                                                                            type="text" 
                                                                            placeholder="100-8953245" 
                                                                            ondrop="return false;" 
                                                                            autocomplete="off"  
                                                                            v-nospecialcharacter
                                                                            v-tocapitalize
                                                                            v-model="routing.reference_number"
                                                                            :class="!routing.reference_number ? 'has-inp-error' : ((disablePanel) ? '' : 'has-inp-success')"
                                                                        />
                                                                    </CCol>
                                                                </CRow>
                                                                <CRow>
                                                                    <CCol lg="12">
                                                                        <CTextarea
                                                                            class="grp-text-area"
                                                                            :disabled="!editPanel"
                                                                            label="Description" 
                                                                            type="text" 
                                                                            placeholder="Enter brief description..." 
                                                                            autocomplete="off"  
                                                                            v-model="routing.description"
                                                                        />
                                                                    </CCol>
                                                                </CRow>
                                                            </div>
                                                        </template>

                                                        <CRow>
                                                            <CCol lg="12">
                                                                <label>Signatory Template</label>
                                                            </CCol>
                                                        </CRow>
                                                        <CRow>
                                                            <CCol lg="12">
                                                                <CInput
                                                                    readonly
                                                                    type="text" 
                                                                    autocomplete="off"  
                                                                    v-model="routing.document_template.name"
                                                                />
                                                            </CCol>
                                                        </CRow>
                                                    </CCol>
                                                    <CCol lg="6">
                                                        <!-- <template v-if="can_upload_file_via_url && edit">
                                                            <CButton 
                                                                style="margin-bottom:-4px;"
                                                                size='sm'    
                                                                color="info" 
                                                                @click="openUploadFileViaURLModal()"
                                                            >
                                                                <font-awesome-icon icon="file"/> Get file via URL
                                                            </CButton>
                                                        </template> -->
                                                        
                                                        <template v-if="can_upload_file_via_url && edit">
                                                            <CRow>
                                                                <CCol lg="12">
                                                                    <CAlert show color="info">
                                                                        <h5 class="alert-heading"><font-awesome-icon icon="link"/> Upload file using API.</h5>
                                                                        <hr>
                                                                        <!-- <p class="mb-0"> -->
                                                                            <CButton 
                                                                                style="margin-bottom:-4px;"
                                                                                size='sm'    
                                                                                color="info" 
                                                                                @click="openUploadFileViaURLModal()"
                                                                            >
                                                                                <font-awesome-icon icon="file"/> Get file
                                                                            </CButton>
                                                                        <!-- </p> -->
                                                                    </CAlert>
                                                                </CCol>
                                                            </CRow>
                                                        </template>
                                                        <template v-if="parent_tab == 1">
                                                            <CRow>
                                                                <CCol lg="3">
                                                                    <template v-if="current_signatory.required_signature">
                                                                        <CButton 
                                                                            block
                                                                            style="margin-bottom:-4px;"
                                                                            size="sm"
                                                                            color="success" 
                                                                            @click="signDocument()"
                                                                        >
                                                                            <font-awesome-icon icon="signature"/> Sign Document
                                                                        </CButton>
                                                                    </template>
                                                                    <template v-else-if="!current_signatory.required_signature && (parseInt(current_signatory.user_id) != parseInt(last_signatory_user_id))">
                                                                        <CButton 
                                                                            block
                                                                            style="margin-bottom:-4px;"
                                                                            size="sm"
                                                                            color="success" 
                                                                            @click="submitDocument()"
                                                                        >
                                                                            <font-awesome-icon icon="play"/> Submit Document
                                                                        </CButton>
                                                                    </template>
                                                                    <template v-else-if="!current_signatory.required_signature && (parseInt(current_signatory.user_id) == parseInt(last_signatory_user_id))">
                                                                        <CButton 
                                                                            block
                                                                            style="margin-bottom:-4px;"
                                                                            size="sm"
                                                                            color="success" 
                                                                            @click="completeDocument()"
                                                                        >
                                                                            <font-awesome-icon icon="square-check"/> Complete Document
                                                                        </CButton>
                                                                    </template>
                                                                </CCol>
                                                                <CCol lg="3">
                                                                    <CButton 
                                                                        block
                                                                        style="margin-bottom:-4px;"
                                                                        size="sm"
                                                                        color="dark" 
                                                                        @click="returnCreator()"
                                                                        v-if="$store.getters['can']('return-document-routing')"
                                                                    >
                                                                        <font-awesome-icon icon="undo-alt"/> Return to  {{parseInt(current_signatory.user_id) == routing.user_id ? 'Draft' : 'Creator'}}
                                                                    </CButton>
                                                                </CCol>
                                                                <CCol lg="3">
                                                                    <CButton    
                                                                        block
                                                                        style="margin-bottom:-4px;"
                                                                        :disabled="signed_signatories.length <= 0"
                                                                        size="sm"
                                                                        :title="signed_signatories.length <= 0 ? 'No signatories is signed yet.' : ''"
                                                                        color="warning" 
                                                                        @click="signed_signatories.length > 0 ? return_back_modal = !return_back_modal : ''"
                                                                        v-if="$store.getters['can']('return-document-routing')"
                                                                    >
                                                                        <font-awesome-icon icon="backspace"/> Return to Signatory
                                                                    </CButton>
                                                                </CCol>
                                                                <CCol lg="3">
                                                                    <CButton 
                                                                        block
                                                                        style="margin-bottom:-4px;"
                                                                        v-if="$store.getters['can']('cancel-document-routing')"
                                                                        size="sm"
                                                                        color="danger" 
                                                                        @click="cancelDocument()" 
                                                                    >
                                                                        <font-awesome-icon icon="window-close"/> Cancel Document
                                                                    </CButton> 
                                                                </CCol>
                                                            </CRow>
                                                        </template>
                                                        <template v-if="parent_tab == 2">
                                                            <CRow>
                                                                <CCol lg="3"></CCol>
                                                                <CCol lg="3"></CCol>
                                                                <CCol lg="3"></CCol>
                                                                <CCol lg="3">
                                                                    <CButton 
                                                                        style="margin-bottom:-4px;"
                                                                        block
                                                                        v-if="document_creator && $store.getters['can']('cancel-document-routing')"
                                                                        size="sm"
                                                                        color="danger" 
                                                                        @click="cancelDocument()" 
                                                                    >
                                                                        <font-awesome-icon icon="window-close"/> Cancel Document
                                                                    </CButton> 
                                                                </CCol>
                                                            </CRow>
                                                        </template>
                                                        <br v-if="parent_tab == 1">
                                                        <br v-if="parent_tab == 0 && !edit && can_upload_file_via_url">
                                                        <br v-if="parent_tab == 0 && !can_upload_file_via_url">
                                                        <br v-if="parent_tab == 2">
                                                        <CRow>
                                                            <CCol lg="12"  v-if="parent_tab == 0">
                                                                <label><font-awesome-icon icon="paperclip"/> Upload PDF to Sign &nbsp;</label>
                                                                <a-popover title="NOTE!" placement="left">
                                                                    <template slot="content">
                                                                        <small>Only upload the PDF that will be sign during routing.</small>
                                                                    </template>
                                                                    <font-awesome-icon style="color: #4caf50; " icon="info-circle" size="lg"/>
                                                                </a-popover>
                                                            </CCol>
                                                            <CCol lg="12">
                                                                <VueFileAgent 
                                                                    :class="!main_file_change ? 'templateFile' : ''"
                                                                    :disabled="!edit && parent_tab == 0"
                                                                    ref="_templateFile"
                                                                    :theme="'list'" 
                                                                    :multiple="true"
                                                                    :maxFiles="parent_tab == 0 ? 10 : template_file.length"
                                                                    :maxSize="parent_tab == 0 ? '8MB' : ''"
                                                                    :deletable="parent_tab == 0"
                                                                    :meta="true"
                                                                    :accept="'application/pdf'"
                                                                    :linkable="true"
                                                                    @beforedelete="deleteTemplateFile($event)"
                                                                    v-model="template_file">
                                                                </VueFileAgent>
                                                            </CCol>
                                                        </CRow>
                                                        <br>
                                                        <CRow v-if="(parent_tab == 0 || 
                                                                    parent_tab == 1 ||
                                                                    parent_tab == 2 && document_creator ||
                                                                    parent_tab == 3 && document_creator)
                                                        && attachments_checklist.length > 0">
                                                            <CCol lg="12">
                                                                <label>Attachments Checklist: </label>
                                                                <CDataTable
                                                                    :items="attachment_display_data"
                                                                    :fields="attachments_display_field"
                                                                    border
                                                                    size="sm"
                                                                    hover
                                                                >
                                                                    <template #name="{item}">
                                                                        <td>
                                                                            {{ item.name }}
                                                                        </td>
                                                                    </template>
                                                                    <template #remarks="{item}">
                                                                        <template v-if="parseInt(item.required) == 1">
                                                                            <td style="color:red">
                                                                                <strong>Required</strong>
                                                                            </td>
                                                                        </template>
                                                                        <template v-else>
                                                                            <td>
                                                                                <i>Optional</i>
                                                                            </td>
                                                                        </template>
                                                                    </template>
                                                                    <template #status="{item}">
                                                                       <td :class="item.status == 'used' ? 'checklist-used' : 'checklist-unuse'" style="padding: 0 0 0 5px !important;">
                                                                            <font-awesome-icon
                                                                                size="lg"
                                                                                :icon="item.status == 'used' ? 'square-check' : 'square-xmark'"
                                                                            /> 
                                                                            <!-- style="font-size: 30px;" -->

                                                                            &nbsp;
                                                                            <i>{{ item.status == 'used' ? 'Document added.' : 'No document added.' }}</i>
                                                                       </td>
                                                                    </template>
                                                                </CDataTable>
                                                            </CCol>
                                                        </CRow>


<!-- 
                                                        <CRow>
                                                            <CCol lg="5">
                                                                <a-popover 
                                                                    title="Attachments Checklist:" 
                                                                    placement="left" 
                                                                    v-if="routing && routing.document_template.document_template_attachment_checklist.length > 0"
                                                                >
                                                                    <template slot="content">
                                                                        <CListGroup>
                                                                            <CListGroupItem v-for="(item, index) in routing.document_template.document_template_attachment_checklist" v-bind:key="index">
                                                                            {{index+1}}. <strong>{{item.name}}</strong>
                                                                            </CListGroupItem>
                                                                        </CListGroup>
                                                                    </template>
                                                                    <div style="margin-bottom: 10px;
                                                                                border-radius: 5px; 
                                                                                padding: 5px;
                                                                                background-color:#03a9f4;
                                                                                color: white; 
                                                                                text-align: center;">
                                                                            <font-awesome-icon 
                                                                                v-if="routing && routing.document_template.document_template_attachment_checklist.length > 0"
                                                                                style="color: #4caf50;" 
                                                                                icon="tasks" 
                                                                                size="lg"
                                                                        /> Attachments Checklist
                                                                    </div>
                                                                </a-popover>
                                                            </CCol>
                                                        </CRow> -->
                                                    </CCol>
                                                </CRow>
                                            </CCardBody>
                                        </CCard>
                                    </CCol>
                                    
                                    <CCol xl="12" lg="12" md="12" sm="12">
                                        <CCard >
                                            <CCardBody>
                                                <CRow>
                                                    <CCol lg="12">
                                                        <CTabs variant="pills" justified :active-tab="signatories_and_share_tab" @update:activeTab="signAndShareChangeTab">
                                                            <br>
                                                            <CTab>
                                                                <template slot="title">
                                                                    Signatories & Requirements <CBadge color="success">( {{document_routin_lines_data.filter(x => !x.cancelled_at).length}} )</CBadge>
                                                                </template>
                                                                <template v-if="signatory_locked && 
                                                                                    (parent_tab == 0 && edit 
                                                                                        || parent_tab == 1
                                                                                            || parent_tab == 2 && document_creator)"
                                                                >
                                                                    <CRow>
                                                                        <CCol lg="12">
                                                                            <CAlert show color="danger">
                                                                                <h4 class="alert-heading"><font-awesome-icon icon="lock"/> Template Signatory Lock!</h4>
                                                                                <hr>
                                                                                <p class="mb-0">
                                                                                    Once a routing has been made, the signatory cannot be removed or changed.
                                                                                </p>
                                                                            </CAlert>
                                                                        </CCol>
                                                                    </CRow>
                                                                </template>
                                                                

                                                                <template v-if="(parent_tab == 1
                                                                                    || parent_tab == 2)
                                                                                        && document_creator
                                                                                            && $store.getters['can']('document-routing-add-signatory-list')">
                                                                    <CRow>
                                                                        <CCol lg="12">
                                                                            <CButton 
                                                                                @click="addNewSignatory(document_creator)"
                                                                                color="success">
                                                                                <font-awesome-icon icon="user"/> Add New Signatory
                                                                            </CButton>
                                                                        </CCol>
                                                                    </CRow>

                                                                </template>
                                                                <template v-if="parent_tab == 0 && edit">
                                                                    <CRow>
                                                                        <CCol lg="12">
                                                                            <CButton 
                                                                                class="float-left"
                                                                                @click="addNewSignatory(document_creator)"
                                                                                color="success"
                                                                                v-show="!editOrderNoCT"
                                                                            >
                                                                                <font-awesome-icon icon="user"/> Add New Signatory
                                                                            </CButton>
                                                                            <CButton 
                                                                                color="danger" 
                                                                                class="float-right" 
                                                                                v-show="editOrderNoCT"
                                                                                @click="editOrderNoCT = !editOrderNoCT"
                                                                            >
                                                                                <font-awesome-icon icon="window-close"/> Cancel
                                                                            </CButton>
                                                                            <CButton 
                                                                                color="success" 
                                                                                class="float-right" 
                                                                                v-show="editOrderNoCT" 
                                                                                @click="confirmChangeOrderNoCT()"
                                                                            >
                                                                                <font-awesome-icon icon="save"/> Confirm Changes
                                                                            </CButton>
                                                                            <CButton 
                                                                                color="info" 
                                                                                class="float-right" 
                                                                                @click="editOrderNoCT = !editOrderNoCT" 
                                                                                v-show="!editOrderNoCT"
                                                                            >
                                                                                <font-awesome-icon icon="arrows-alt-v"/> Change Signatory Order
                                                                            </CButton>
                                                                        </CCol>
                                                                    </CRow>
                                                                </template>
                                                                <CRow>
                                                                    <CCol lg="12">
                                                                        <CDataTable
                                                                            hover
                                                                            :items="document_routin_lines_data"
                                                                            :fields="document_routing_lines_field"
                                                                            border
                                                                            size="sm"
                                                                            sorter
                                                                            pagination
                                                                        >
                                                                            <template #required_signature={item}>
                                                                                <td>
                                                                                    <!-- <center>
                                                                                        <p-check 
                                                                                            disabled
                                                                                            class="p-icon p-bigger" 
                                                                                            color="success" 
                                                                                            off-color="danger"
                                                                                            toggle
                                                                                            v-model="item.document_template_signature.required_signature"
                                                                                        >
                                                                                            <font-awesome-icon class="icon" icon="check" slot="extra"/>
                                                                                            <font-awesome-icon class="icon" icon="times" slot="off-extra"/>
                                                                                            
                                                                                            <label slot="off-label"></label>
                                                                                        </p-check>
                                                                                    </center> -->
                                                                                    <i>{{ item.document_template_signature.required_signature ? 'Yes' : 'No' }}</i>
                                                                                </td>
                                                                            </template>
                                                                            <template #name={item}>
                                                                                <td>
                                                                                    {{item.document_template_signature.user.first_name}}
                                                                                    {{item.document_template_signature.user.last_name}}
                                                                                </td>
                                                                            </template>
                                                                            <template #purpose={item}>
                                                                                <td>
                                                                                    <template v-if="!item.document_template_signature.tsmp_id">
                                                                                        {{item.document_template_signature.purpose}}
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        {{item.document_template_signature.purpose_new.purpose}}
                                                                                    </template>
                                                                                </td>
                                                                            </template>
                                                                            <template #order="{item}">
                                                                                <td v-if="editOrderNoCT">
                                                                                    <CInput 
                                                                                        onkeyup="if (/\D/g.test(this.value)) this.value = this.value.replace(/\D/g,'')"
                                                                                        style="max-width: 50px;"
                                                                                        type="text" 
                                                                                        maxlength="2"
                                                                                        v-model="item.order"
                                                                                    />
                                                                                </td>
                                                                                <td v-if="!editOrderNoCT">
                                                                                    {{item.order}}
                                                                                </td>
                                                                            </template>
                                                                            <template #signed={item}>
                                                                                <td>
                                                                                    <template v-if="!item.cancelled_at">
                                                                                        <template v-if="item.signed_at">
                                                                                            
                                                                                            <label style="color: green;">
                                                                                                <font-awesome-icon icon="check" />  
                                                                                                Signed
                                                                                            </label> 
                                                                                            <br>
                                                                                            <small>
                                                                                                <font-awesome-icon icon="calendar-check" /> 
                                                                                                {{changeDateFormat(item.signed_at)}}
                                                                                            </small>
                                                                                        </template>
                                                                                        <template v-if="!item.signed_at">
                                                                                            ----
                                                                                        </template>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <label style="color:red;">
                                                                                            <font-awesome-icon icon="times" /> 
                                                                                            Removed
                                                                                        </label> 
                                                                                        <br>
                                                                                        <small>
                                                                                            <font-awesome-icon icon="calendar-times"/>  
                                                                                            {{changeDateFormat(item.cancelled_at)}}
                                                                                        </small>
                                                                                    </template>
                                                                                </td>
                                                                            </template>
                                                                            <template #requirements="{item, index}">
                                                                                <td v-if="item.document_signature_requirements.length > 0">
                                                                                    <CButton
                                                                                        :color="Boolean(item._toggled) ? 'danger' : 'info'"
                                                                                        shape="pill" 
                                                                                        size="sm"
                                                                                        @click="toggleDetails(item, index)"
                                                                                    >
                                                                                        <font-awesome-icon :icon="Boolean(item._toggled) ? 'eye-slash' : 'eye'"/> {{Boolean(item._toggled) ? 'Hide Requirements' : 'Show Requirements'}}
                                                                                    </CButton>
                                                                                </td>
                                                                                <td v-if="item.document_signature_requirements.length <= 0">
                                                                                    <i>N/a</i>
                                                                                </td>
                                                                            </template>
                                                                            <template #action={item}>
                                                                                <td>
                                                                                    <template v-if="parent_tab == 0 && !signatory_locked">
                                                                                        <template v-if="!item.cancelled_at && !item.signed_at">
                                                                                            <CButton
                                                                                                v-if="item.id != last_signatory_id"
                                                                                                :title="signatory_locked ? 'Template Signatory is Locked' : ''"
                                                                                                block
                                                                                                color="danger"
                                                                                                size="sm"
                                                                                                shape="pill" 
                                                                                                @click="pullOutDocument(item)"
                                                                                            >
                                                                                                <font-awesome-icon icon="times"/> Remove Signatory
                                                                                            </CButton>
                                                                                            <CButton
                                                                                                block
                                                                                                color="dark"
                                                                                                size="sm"
                                                                                                shape="pill" 
                                                                                                @click="changeSignatory(item)"
                                                                                            >
                                                                                                <font-awesome-icon icon="user-slash"/> Change Signatory
                                                                                            </CButton>
                                                                                        </template>
                                                                                        <template v-else>
                                                                                                ----
                                                                                        </template>
                                                                                    </template>
                                                                                    <template v-if="parent_tab == 1">
                                                                                        <template v-if="!item.cancelled_at && !item.signed_at">
                                                                                            <template v-if="document_creator">
                                                                                                <CButton
                                                                                                    v-if="item.id != last_signatory_id"
                                                                                                    block
                                                                                                    color="danger"
                                                                                                    size="sm"
                                                                                                    shape="pill" 
                                                                                                    @click="pullOutDocument(item)"
                                                                                                >
                                                                                                    <font-awesome-icon icon="file"/> Remove Signatory
                                                                                                </CButton>
                                                                                                <CButton
                                                                                                    block
                                                                                                    color="dark"
                                                                                                    size="sm"
                                                                                                    shape="pill" 
                                                                                                    @click="changeSignatory(item)"
                                                                                                >
                                                                                                    <font-awesome-icon icon="user-slash"/> Change Signatory
                                                                                                </CButton>
                                                                                            </template>
                                                                                            <template v-if="document_signatory && !document_creator">
                                                                                                <template v-if="!signatory_locked">
                                                                                                    <template v-if="item.document_template_signature.user_id == parseInt(current_signatory.user_id) ">
                                                                                                        <CButton
                                                                                                            v-if="item.id != last_signatory_id"
                                                                                                            block
                                                                                                            color="danger"
                                                                                                            size="sm"
                                                                                                            shape="pill" 
                                                                                                            @click="pullOutDocument(item)"
                                                                                                        >
                                                                                                            <font-awesome-icon icon="file"/> Remove Signatory
                                                                                                        </CButton>
                                                                                                        <CButton
                                                                                                            block
                                                                                                            color="dark"
                                                                                                            size="sm"
                                                                                                            shape="pill" 
                                                                                                            @click="changeSignatory(item)"
                                                                                                        >
                                                                                                            <font-awesome-icon icon="user-slash"/> Change Signatory
                                                                                                        </CButton>
                                                                                                    </template>
                                                                                                    <template v-else>
                                                                                                            ----
                                                                                                    </template>
                                                                                                </template>
                                                                                                <template v-else>
                                                                                                    <CButton 
                                                                                                        block
                                                                                                        shape="pill" 
                                                                                                        size="sm"
                                                                                                        v-if="item.document_template_signature.user_id == parseInt(current_signatory.user_id)"
                                                                                                        @click="addNewSignatory(document_creator, item.order)"
                                                                                                        color="success">
                                                                                                        <font-awesome-icon icon="user"/> Add Signatory
                                                                                                    </CButton>
                                                                                                </template>
                                                                                            </template>
                                                                                        </template>
                                                                                        <template v-else>
                                                                                                ----
                                                                                        </template>
                                                                                    </template>
                                                                                    <template v-if="parent_tab == 2">
                                                                                        <template v-if="!item.cancelled_at && !item.signed_at">
                                                                                            <CButton
                                                                                                v-if="item.id != last_signatory_id"
                                                                                                block
                                                                                                color="danger"
                                                                                                size="sm"
                                                                                                shape="pill" 
                                                                                                @click="pullOutDocument(item)"
                                                                                            >
                                                                                                <font-awesome-icon icon="file"/> Remove Signatory
                                                                                            </CButton>
                                                                                            <CButton
                                                                                                block
                                                                                                color="dark"
                                                                                                size="sm"
                                                                                                shape="pill" 
                                                                                                @click="changeSignatory(item)"
                                                                                            >
                                                                                                <font-awesome-icon icon="user-slash"/> Change Signatory
                                                                                            </CButton>
                                                                                        </template>
                                                                                        <template v-else>
                                                                                                ----
                                                                                        </template>
                                                                                    </template>
                                                                                </td>
                                                                            </template>
                                                                            <template #details="{item}">
                                                                                <CCollapse :show="Boolean(item._toggled)">
                                                                                    <CCard border-color="info">
                                                                                        <CCardHeader><font-awesome-icon icon="paperclip"/> Signature Requirements for {{item.document_template_signature.user.first_name}} {{item.document_template_signature.user.last_name}}
                                                                                        </CCardHeader>
                                                                                        <CCardBody>
                                                                                            <CRow  v-for="(child_item) in item.document_signature_requirements" v-bind:key="child_item.requirement">
                                                                                                <CCol lg="12">
                                                                                                    <p-check 
                                                                                                        disabled
                                                                                                        class="p-icon p-bigger" 
                                                                                                        color="success" 
                                                                                                        v-model="checked_requirements_old_id"
                                                                                                        :value="child_item.id+'_'+item.id+'_'+child_item.requirement+'_'+item.document_template_signature.user.first_name+' '+item.document_template_signature.user.last_name">
                                                                                                            <font-awesome-icon class="icon" icon="check"/>
                                                                                                            {{child_item.requirement}}
                                                                                                    </p-check>  
                                                                                                    <br>
                                                                                                </CCol>
                                                                                            </CRow>
                                                                                        </CCardBody>
                                                                                    </CCard>
                                                                                </CCollapse>
                                                                            </template>
                                                                        </CDataTable>
                                                                    </CCol>
                                                                </CRow>
                                                            </CTab>
                                                            <CTab
                                                                v-if="$store.getters['can']('view-shared-document-routing')"
                                                                :disabled="parent_tab == 4"
                                                            >
                                                                <template slot="title">
                                                                    Shared Routing  <CBadge color="success" v-if="users_shared_routing_list.length > 0">( {{users_shared_routing_list.length}} )</CBadge>
                                                                </template>
                                                                <!-- || parent_tab == 1
                                                                        || parent_tab == 2 && document_creator
                                                                            || parent_tab == 3 && document_creator -->
                                                                <CRow v-if="parent_tab == 0 && edit
                                                                || parent_tab == 1
                                                                || parent_tab == 2
                                                                    || parent_tab == 3">
                                                                    
                                                                    <CCol lg="12">
                                                                        <a-popover title="NOTE:" placement="topRight">
                                                                            <template slot="content" >
                                                                            <p>If the user you wish to share this routing with does not exist in
                                                                                <br>the list, this user is either a signatory, document creator, or already 
                                                                                <br>on the list.</p>
                                                                            </template>
                                                                                <font-awesome-icon class="float-right" style="color: #4c8baf;" icon="info-circle" size="lg"/>
                                                                        </a-popover>
                                                                        <CButton 
                                                                            v-if="$store.getters['can']('document-routing-add-shared')"
                                                                            color="success" 
                                                                            class="float-left"
                                                                            @click="showUserSelectableModal()">
                                                                            <font-awesome-icon icon="user"/> Add New User
                                                                        </CButton>
                                                                    </CCol>
                                                                </CRow>
                                                                <CRow>
                                                                    <CCol lg="12">
                                                                        <CDataTable
                                                                                :items="users_shared_routing_list"
                                                                                :fields="users_shared_routing_list_fields"
                                                                                :items-per-page="10"
                                                                                border
                                                                                hover
                                                                                size="sm"
                                                                                sorter
                                                                            >
                                                                            
                                                                            <template #date_added="{item, index}">
                                                                                <td>
                                                                                    <small v-if="item.added_by != 'Avante Admin'"> {{ item.date_added }} </small>
                                                                                    <span v-else>{{ item.date_added }} </span>
                                                                                </td>
                                                                            </template>
                                                                            <template #action="{item, index}">
                                                                                <td>
                                                                                    <template v-if="item.added_by != 'Avante Admin'">
                                                                                        <CButton 
                                                                                            block
                                                                                            v-if="$store.getters['can']('document-routing-remove-shared')"
                                                                                            :disabled="item.shared_by_user_id != $store.getters['getUserDetails'].id || (removing_shared ? (item.id == removing_shared_id ? true : false) : false) "
                                                                                            color="danger" 
                                                                                            @click="item.shared_by_user_id == $store.getters['getUserDetails'].id ? removeUserForSharedRouting(item, index) : ''" 
                                                                                            shape="pill" 
                                                                                            size="sm"
                                                                                        >
                                                                                            <font-awesome-icon :icon="removing_shared ? (item.id == removing_shared_id ? 'circle-notch' : 'times') : 'times'" 
                                                                                                :pulse="removing_shared ? (item.id == removing_shared_id ? true : false) : false"/> Remove User
                                                                                        </CButton>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        ----
                                                                                    </template>
                                                                                </td>
                                                                            </template>
                                                                        </CDataTable>
                                                                    </CCol>
                                                                </CRow> 
                                                                <CRow>
                                                                    <CCol lg="12">
                                                                        <CButton 
                                                                            v-if="users_shared_routing_list.some(function(item) { return item.recently_added === true})"
                                                                            color="primary" 
                                                                            class="float-right"
                                                                            size="sm"
                                                                            @click="saveUserShared()"
                                                                        >
                                                                            <font-awesome-icon icon="save"/> Save User Shared
                                                                        </CButton>
                                                                    </CCol>
                                                                </CRow>
                                                            </CTab>
                                                            <CTab>
                                                                <template slot="title">
                                                                    External Attachments <CBadge color="success" v-if="external_files_list.length > 0">( {{external_files_list.length}} )</CBadge>
                                                                </template>
                                                                <template v-if="(parent_tab == 0 && edit)
                                                                                    || (parent_tab == 1)
                                                                                        || (parent_tab == 2 && document_creator)
                                                                                            || (parent_tab == 3 && document_creator)">
                                                                    <CRow>
                                                                        <CCol lg="4">
                                                                            <input 
                                                                                ref="_externalFiles" 
                                                                                type="file" 
                                                                                multiple
                                                                                v-on:change="onFileChange"
                                                                                :accept="'image/*,.pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
                                                                            >
                                                                        </CCol>
                                                                        <CCol lg="5"/>
                                                                        <CCol lg="3">
                                                                            <!-- <a-popover 
                                                                                title="Attachments Checklist:" 
                                                                                placement="left" 
                                                                                v-if="routing && routing.document_template.document_template_attachment_checklist.length > 0"
                                                                            >
                                                                                <template slot="content">
                                                                                    <CListGroup>
                                                                                        <CListGroupItem v-for="(item, index) in routing.document_template.document_template_attachment_checklist" v-bind:key="index">
                                                                                        {{index+1}}. <strong>{{item.name}}</strong>
                                                                                        </CListGroupItem>
                                                                                    </CListGroup>
                                                                                </template>
                                                                                <div style="margin-bottom: 10px;
                                                                                            border-radius: 5px; 
                                                                                            padding: 5px;
                                                                                            background-color:#03a9f4;
                                                                                            color: white; 
                                                                                            text-align: center;">
                                                                                    <font-awesome-icon 
                                                                                        v-if="routing && routing.document_template.document_template_attachment_checklist.length > 0"
                                                                                        style="color: #4caf50;" 
                                                                                        icon="tasks" 
                                                                                        size="lg"
                                                                                /> Attachments Checklist
                                                                            </div>
                                                                        </a-popover> -->
                                                                    </CCol>
                                                                    </CRow>
                                                                </template>
                                                                <CDataTable
                                                                    :key="external_files_key"
                                                                    :items="external_files_list"
                                                                    :fields="external_files_field"
                                                                    border
                                                                    hover
                                                                    sorter
                                                                    size="sm"
                                                                >
                                                                    
                                                                    <template #checklist="{item, index}">
                                                                        <template v-if="(parent_tab == 0 && edit || 
                                                                                        parent_tab == 1 && !item.id ||
                                                                                        parent_tab == 2 && document_creator && !item.id ||
                                                                                        parent_tab == 3 && document_creator)
                                                                                        && attachments_checklist.length > 0">
                                                                            <td>
                                                                                <!-- :selectable="(option) => tagged_attachments_id.filter(x => x == option.id).length <= 0" -->
                                                                                <!-- <v-select 
                                                                                    label="name"
                                                                                    @option:selected="selectChangeChecklistOnListExt(item, index)"
                                                                                    :options="attachments_checklist"
                                                                                    :class="has_required_attachments && !item.checklist ? 'has-error' : 'has-success'"
                                                                                    v-model="item.checklist"
                                                                                /> -->
                                                                                <CRow>
                                                                                    <CCol :lg="item.checklist ? '10' : '12'" :style="item.checklist ? 'padding-right: 0 !important;' : ''">
                                                                                        <v-select 
                                                                                            :clearable="false"
                                                                                            label="name"
                                                                                            @option:selected="selectChangeChecklistOnListExt(item, index)"
                                                                                            :options="attachments_checklist"
                                                                                            :class="has_required_attachments && !item.checklist ? 'has-error' : 'has-success'"
                                                                                            v-model="item.checklist"
                                                                                        />
                                                                                    </CCol>
                                                                                    <CCol lg="2" style="padding-left: 2px !important;" v-show="item.checklist">
                                                                                        <CButton title="Clear selection." color="dark" class="cstm-btn" @click="clearExternalChecklist(item)">
                                                                                            <font-awesome-icon icon="times"/>
                                                                                        </CButton>
                                                                                    </CCol>
                                                                                 </CRow>
                                                                            </td>
                                                                        </template>
                                                                        <template v-else>
                                                                            <td>
                                                                                {{item.checklist?.name}}
                                                                            </td>
                                                                        </template>
                                                                    </template>
                                                                    <template #filetype="{item}"> 
                                                                        <td v-if="item.date_uploaded != 'Recently Added'">
                                                                            <a :href="item.url" target="_blank">
                                                                                <center>
                                                                                    <img class="attachment-icon" :src="'/static/img/image-attachment-placeholder.jpg'"  v-if="item.filetype.split('/')[0] == 'image'"/> 
                                                                                    <img class="attachment-icon" :src="'/static/img/pdf-attachment-placeholder.png'"  v-if="item.filetype == 'application/pdf'"/> 
                                                                                    <img class="attachment-icon" :src="'/static/img/excell-attachment-placeholder.jpg'"  v-if="item.filetype == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || item.filetype == 'application/vnd.ms-excel'"/> 
                                                                                </center>
                                                                            </a>
                                                                        </td>
                                                                        <td v-else>
                                                                            <center>
                                                                                <img class="attachment-icon" :src="'/static/img/image-attachment-placeholder.jpg'"  v-if="item.filetype.split('/')[0] == 'image'"/> 
                                                                                <img class="attachment-icon" :src="'/static/img/pdf-attachment-placeholder.png'"  v-if="item.filetype == 'application/pdf'"/> 
                                                                                <img class="attachment-icon" :src="'/static/img/excell-attachment-placeholder.jpg'"  v-if="item.filetype == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || item.filetype == 'application/vnd.ms-excel'"/> 
                                                                            </center>
                                                                        </td>
                                                                    </template>
                                                                    <template #filename="{item}"> 
                                                                        <td :title="item.filename">
                                                                            {{ item.filename.length > 15 ? item.filename.substring(0, 15) + '...' +  item.filename.split('.')[1] : item.filename}} 
                                                                        </td>
                                                                    </template>
                                                                    <template #remarks="{item}" >
                                                                        <td v-if="parent_tab == 0 && edit
                                                                                    || parent_tab == 1 && item.date_uploaded == 'Recently Added'
                                                                                        || parent_tab == 2 && item.date_uploaded == 'Recently Added'
                                                                                            || parent_tab == 3 && item.date_uploaded == 'Recently Added'">
                                                                            <CTextarea
                                                                                class="grp-text-area"
                                                                                placeholder="Remarks.."
                                                                                autocomplete="off"  
                                                                                v-tocapitalize
                                                                                v-model="item.remarks"
                                                                            /> 
                                                                        </td>
                                                                        <td :title="item.remarks" v-else>
                                                                            {{ item.remarks.length > 15 ? item.remarks.substring(0, 15) + '...' : item.remarks}} 
                                                                        </td>
                                                                        
                                                                    </template>
                                                                    <template #date_uploaded="{item}"> 
                                                                        <td v-if="item.date_uploaded == 'Recently Added'">
                                                                            <small><CBadge color="success">Recently Added</CBadge></small>
                                                                        </td>
                                                                        <td v-else>
                                                                            <small>{{ item.date_uploaded }}</small>
                                                                        </td>
                                                                    </template>
                                                                    <template #uploaded_by="{item}"> 
                                                                        <td v-if="!item.parent">
                                                                            <small>{{ item.uploaded_by }}</small>
                                                                        </td>
                                                                        <td v-else></td>
                                                                    </template>
                                                                    <template #action="{item, index}">
                                                                        <!-- parseInt(item.uploaded) == parseInt($store.getters.getUserDetails.id) ||   -->
                                                                        <td v-if="document_creator">
                                                                            <center>
                                                                                <CButton 
                                                                                    :disabled="item.date_uploaded != 'Recently Added' && parent_tab == 3"
                                                                                    color="danger" 
                                                                                    :title="item.date_uploaded != 'Recently Added' && parent_tab == 3? 'Unable to removed attachment.' : ''"
                                                                                    shape="pill" 
                                                                                    size="sm"
                                                                                    @click="removeExternalAttachment(item, index)"
                                                                                >
                                                                                    <font-awesome-icon icon="times"/> 
                                                                                    <!-- Remove Attachment -->
                                                                                </CButton>
                                                                            </center>
                                                                        </td>
                                                                        <td v-else-if="item.uploaded == $store.getters['getUserDetails'].id">
                                                                            <center>
                                                                                <CButton 
                                                                                    :disabled="item.date_uploaded != 'Recently Added' && parent_tab == 3"
                                                                                    color="danger" 
                                                                                    :title="item.date_uploaded != 'Recently Added' && parent_tab == 3? 'Unable to removed attachment.' : ''"
                                                                                    shape="pill" 
                                                                                    size="sm"
                                                                                    @click="removeExternalAttachment(item, index)"
                                                                                >
                                                                                    <font-awesome-icon icon="times"/> 
                                                                                    <!-- Remove Attachment -->
                                                                                </CButton>
                                                                            </center>
                                                                        </td>
                                                                        <td v-else>
                                                                            ----
                                                                        </td>
                                                                    </template>
                                                                </CDataTable>
                                                                <CRow>
                                                                    <CCol lg="12" >
                                                                        <template v-if="(parent_tab == 1 
                                                                            && (document_creator || document_signatory) 
                                                                                && has_attachment_changes)

                                                                            || (parent_tab == 2 
                                                                                && document_creator
                                                                                    && has_attachment_changes)

                                                                            || (parent_tab == 3 
                                                                                && document_creator
                                                                                    && has_attachment_changes)">
                                                                                <CButton
                                                                                    color="secondary" 
                                                                                    @click="resetAttachmentChanges()"
                                                                                >
                                                                                    <font-awesome-icon icon="eraser"/> Reset Attachment Changes
                                                                                </CButton>
                                                                                &nbsp;
                                                                                <CButton
                                                                                    :disabled="!$store.getters['can']('document-routing-update-external-attachments') || warnings.length > 0"
                                                                                    color="info" 
                                                                                    @click="saveAttachmentChanges()"
                                                                                > 
                                                                                    <font-awesome-icon icon="save"/>  {{external_files_counter > 0 || internal_files_counter > 0 ? 'Update Attachment Changes' : 'Save Attachment Changes'}}  
                                                                                </CButton>
                                                                                <a-popover title="WARNING!" placement="left">
                                                                                    <template slot="content">
                                                                                        <span v-for="(item, index) in warnings" v-bind:key="index">
                                                                                            {{index + 1}}. {{item}} <br>
                                                                                        </span>
                                                                                    </template>
                                                                                    <font-awesome-icon 
                                                                                        v-if="warnings.length > 0"
                                                                                        style="color: orange; margin-left:10px" 
                                                                                        icon="triangle-exclamation" 
                                                                                        size="2xl"/>
                                                                                </a-popover>
                                                                        </template>
                                                                    </CCol>
                                                                </CRow>
                                                            </CTab>
                                                            <CTab>
                                                                <template slot="title">
                                                                    Internal Attachments <CBadge color="success" v-if="internal_files_v2.length > 0">( {{internal_files_v2.filter(x => x.attachment_id).length }} )</CBadge>
                                                                </template>
                                                                <template v-if="(parent_tab == 0 && edit)
                                                                                    || (parent_tab == 1)
                                                                                        || (parent_tab == 2 && document_creator)
                                                                                            || (parent_tab == 3 && document_creator)">
                                                                    <CRow>
                                                                        <CCol lg="3">
                                                                            <label>Select Document Category</label>
                                                                                <v-select 
                                                                                    :disabled="!edit 
                                                                                                && parent_tab != 1 
                                                                                                    && parent_tab != 2 
                                                                                                        && parent_tab != 3"
                                                                                    label="category"
                                                                                    :options="templates_lists"
                                                                                    v-model="selected_template"
                                                                                    :class="!selected_template ? 'has-error' : 'has-success'"
                                                                                />
                                                                        </CCol>
                                                                        <CCol lg="4">
                                                                            <CInput 
                                                                                readonly
                                                                                label="Select Reference Number" 
                                                                                v-model="selected_reference"
                                                                            >
                                                                                        
                                                                                <template #append >
                                                                                    <CButton color="primary"
                                                                                    :disabled="!selected_template"
                                                                                    @click="inputSearch(selected_template_reference_number)">
                                                                                        <font-awesome-icon icon="search"/>
                                                                                    </CButton>
                                                                                </template>
                                                                            </CInput>
                                                                        </CCol>
                                                                        <!-- <CCol lg="2"> -->
                                                                            <!-- <label>Attachment</label> -->
                                                                            <!-- :selectable="(option) => tagged_attachments_id.filter(x => x == option.id).length <= 0" -->
                                                                            <!-- <v-select 
                                                                                :disabled="!selected_reference"
                                                                                label="name"
                                                                                :options="attachments_checklist"
                                                                                v-model="selected_attachment"
                                                                                :class="has_required_attachments && !selected_attachment ? 'has-error' : 'has-success'"
                                                                            /> -->
                                                                        <!-- </CCol> -->
                                                                        <CCol lg="3">
                                                                            <CTextarea
                                                                                :disabled="(!edit && parent_tab == 4) || !selected_template "
                                                                                class="grp-text-area-cust"
                                                                                placeholder="Remarks.."
                                                                                label="Enter remarks"
                                                                                autocomplete="off"  
                                                                                v-tocapitalize
                                                                                v-model="selected_reference_remarks"
                                                                            />
                                                                        </CCol>
                                                                        <CCol lg="2">
                                                                            <!-- <CButton 
                                                                                :disabled="!edit 
                                                                                            && parent_tab != 1 
                                                                                                && parent_tab != 2 
                                                                                                    && parent_tab != 3"
                                                                                color="info"
                                                                                block
                                                                                style="margin-top:28px;"
                                                                                @click="addInternalFile()"
                                                                            >
                                                                            
                                                                                <font-awesome-icon icon="plus"/> Add Attachment
                                                                            </CButton> -->

                                                                            <!-- parent_tab != 1 
                                                                                            && parent_tab != 2 
                                                                                                && parent_tab != 3 -->
                                                                            <CDropdown                                                                            
                                                                                :disabled="!selected_template || !selected_reference || is_getting_file_to_add_internally"
                                                                                style="margin-top:28px;"
                                                                                color="info"
                                                                                :togglerText="is_getting_file_to_add_internally ? 'Getting...' : 'Get File(s)'" 
                                                                            >
                                                                                <CDropdownItem @click="addInternalFile('main_file')">Main File</CDropdownItem>
                                                                                <CDropdownItem @click="addInternalFile('all_file')">All File(s) - Attachments included</CDropdownItem>
                                                                            </CDropdown>

                                                                        </CCol>
                                                                    </CRow>
                                                                </template>
                                                                <CRow>
                                                                    <CCol lg="12">
                                                                        <CDataTable
                                                                            :key="internal_file_v2_key"
                                                                            :items="internal_files_v2"
                                                                            :fields="internal_files_field_v2"
                                                                            border
                                                                            @row-clicked="parentRowClickedInternal"
                                                                            sorter
                                                                            hover
                                                                            size="sm"
                                                                        >
                                                                        
                                                                            <template #filename="{item}">
                                                                                <template v-if="!item.hidden">
                                                                                    <td v-if="item.parent">
                                                                                        <strong>{{item.reference_number}}</strong>
                                                                                    </td>
                                                                                    <td v-else-if="item.main" :title="item.filename+'.'+item.fileext">
                                                                                        <small><i>Main File</i></small><br>
                                                                                        {{ item.filename.length > 30 ? item.filename.substring(0, 30) + '...' : item.filename}}{{'.'+item.fileext}}
                                                                                    </td>
                                                                                    <td v-else :title="item.filename+'.'+item.fileext">
                                                                                        <small><i>Supporting File(s)</i></small><br>
                                                                                        {{ item.filename.length > 30 ? item.filename.substring(0, 30) + '...' : item.filename}}{{'.'+item.fileext}}
                                                                                    </td>
                                                                                </template>
                                                                                <template v-else></template>
                                                                            </template>
                                                                            <template #filetype="{item}">
                                                                                <template v-if="!item.hidden">
                                                                                    <td>
                                                                                        <center>
                                                                                            <a :href="item.url" target="_blank">
                                                                                                <img class="attachment-icon" :src="'/static/img/image-attachment-placeholder.jpg'"  v-if="item.filetype.split('/')[0] == 'image'"/> 
                                                                                                <img class="attachment-icon" :src="'/static/img/pdf-attachment-placeholder.png'"  v-if="item.filetype == 'application/pdf'"/> 
                                                                                                <img class="attachment-icon" :src="'/static/img/excell-attachment-placeholder.jpg'"  v-if="item.filetype == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || item.filetype == 'application/vnd.ms-excel'"/> 
                                                                                            </a>
                                                                                        </center>
                                                                                    </td>
                                                                                </template>
                                                                                <template v-else></template>
                                                                            </template>
                                                                            <template #filesize={item}>
                                                                                <template v-if="!item.hidden">
                                                                                    <td>
                                                                                        {{item.filesize}}
                                                                                    </td>
                                                                                </template>
                                                                                <template v-else></template>
                                                                            </template>
                                                                            
                                                                            <template #remarks="{item}"> 
                                                                                <template v-if="!item.hidden">
                                                                                    <template v-if="parent_tab == 0 && edit
                                                                                                || parent_tab == 1 && !item.id
                                                                                                    || parent_tab == 2 && !item.id
                                                                                                        || parent_tab == 3 && !item.id">
                                                                                        <td v-if="!item.parent"> 
                                                                                            <span v-if="!edit"></span>
                                                                                            <CTextarea
                                                                                                class="grp-text-area"
                                                                                                placeholder="Remarks.."
                                                                                                autocomplete="off"  
                                                                                                v-tocapitalize
                                                                                                v-model="item.remarks"
                                                                                            /> 
                                                                                        </td>
                                                                                        <td v-else></td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td :title="item.remarks" v-if="!item.parent">
                                                                                            {{ item.remarks.length > 15 ? item.remarks.substring(0, 15) + '...' : item.remarks}} 
                                                                                        </td>
                                                                                        <td v-else></td>
                                                                                    </template>
                                                                                </template>
                                                                                <template v-else></template>
                                                                            </template>
                                                                            
                                                                            <template #checklist="{item, index}">
                                                                                <template v-if="!item.hidden">
                                                                                    <template v-if="(parent_tab == 0 && edit || 
                                                                                            parent_tab == 1 && !item.id ||
                                                                                            parent_tab == 2 && document_creator && !item.id ||
                                                                                            parent_tab == 3 && document_creator)
                                                                                            && attachments_checklist.length > 0">
                                                                                            <td v-if="!item.parent">
                                                                                                <!-- <v-select 
                                                                                                    label="name"
                                                                                                    @option:selected="selectChangeChecklistOnListInt(item, index)"
                                                                                                    :options="attachments_checklist"
                                                                                                    :class="has_required_attachments && !item.checklist ? 'has-error' : 'has-success'"
                                                                                                    v-model="item.checklist"
                                                                                                /> -->
    
    
    
                                                                                                <CRow>
                                                                                                    <CCol :lg="item.checklist ? '10' : '12'" :style="item.checklist ? 'padding-right: 0 !important;' : ''">
                                                                                                        <v-select 
                                                                                                            :clearable="false"
                                                                                                            label="name"
                                                                                                            @option:selected="selectChangeChecklistOnListInt(item, index)"
                                                                                                            :options="attachments_checklist"
                                                                                                            :class="has_required_attachments && !item.checklist ? 'has-error' : 'has-success'"
                                                                                                            v-model="item.checklist"
                                                                                                        />
                                                                                                    </CCol>
                                                                                                    <CCol lg="2" style="padding-left: 2px !important;" v-show="item.checklist">
                                                                                                        <CButton title="Clear selection." color="dark" class="cstm-btn" @click="clearInternalChecklist(item)">
                                                                                                            <font-awesome-icon icon="times"/>
                                                                                                        </CButton>
                                                                                                    </CCol>
                                                                                                 </CRow>
                                                                                            </td>
                                                                                            <td v-else></td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td v-if="!item.parent">
                                                                                            {{item.checklist?.name}}
                                                                                        </td>
                                                                                        <td v-else></td>
                                                                                    </template>
                                                                                </template>
                                                                                <template v-else></template>
                                                                            </template>
                                                                            
                                                                            <template #date_uploaded="{item}"> 
                                                                                <template v-if="!item.hidden">
                                                                                    <template v-if="!item.parent">
                                                                                        <td v-if="!item.id">
                                                                                            <small><CBadge color="success">Recently Added</CBadge></small>
                                                                                        </td>
                                                                                        <td v-else>
                                                                                            <small>{{ item.date_uploaded }}</small>
                                                                                        </td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td></td>
                                                                                    </template>
                                                                                </template>
                                                                                <template v-else></template>
                                                                            </template>
                                                                            <template #uploaded_by="{item}"> 
                                                                                <template v-if="!item.hidden">
                                                                                    <td v-if="!item.parent">
                                                                                        <small>{{ item.uploaded_by }}</small>
                                                                                    </td>
                                                                                    <td v-else></td>
                                                                                </template>
                                                                                <template v-else></template>
                                                                            </template>
                                                                            <template #action="{item, index}">
                                                                                
                                                                                <template v-if="document_creator || item.uploaded == $store.getters['getUserDetails'].id">
                                                                                    <template v-if="item.parent">
                                                                                        <td>
                                                                                            <center>
                                                                                                <CButton 
                                                                                                
                                                                                                    :disabled="internal_files_v2_backup.filter(x => {return x.reference_number == item.reference_number}).length > 0 
                                                                                                        && parent_tab == 3"
                                                                                                    color="danger" 
                                                                                                    shape="pill" 
                                                                                                    title="Remove Attachment"
                                                                                                    size="sm"
                                                                                                    @click="removeInternalFileV2('parent_file', item, index)"
                                                                                                >
                                                                                                    Remove Attachment
                                                                                                </CButton>
                                                                                            </center>
                                                                                        </td>
                                                                                    </template>
                                                                                    <template v-else-if="item.main">
                                                                                        <template v-if="!item.hidden">
                                                                                            <td>
                                                                                                <center>
                                                                                                    <CButton 
                                                                                                        :disabled="item.id && parent_tab == 3"
                                                                                                        color="warning" 
                                                                                                        shape="pill" 
                                                                                                        title="Remove Attachment"
                                                                                                        @click="removeInternalFileV2('main_file', item, index)"
                                                                                                        size="sm"
                                                                                                    >
                                                                                                        <font-awesome-icon icon="times"/> 
                                                                                                    </CButton>
                                                                                                </center>
                                                                                            </td>
                                                                                        </template>
                                                                                        <template v-else></template>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <template v-if="!item.hidden">
                                                                                            <td>
                                                                                                <center>
                                                                                                    <CButton 
                                                                                                        :disabled="item.id && parent_tab == 3"
                                                                                                        color="warning" 
                                                                                                        shape="pill" 
                                                                                                        title="Remove Attachment"
                                                                                                        @click="removeInternalFileV2('supporting_file', item, index)"
                                                                                                        size="sm"
                                                                                                    >
                                                                                                        <font-awesome-icon icon="times"/> 
                                                                                                    </CButton>
                                                                                                </center>
                                                                                            </td>
                                                                                        </template>
                                                                                        <template v-else></template>
                                                                                    </template>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <template v-if="item.parent">
                                                                                        <td></td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <template v-if="!item.hidden">
                                                                                            <td>----</td>
                                                                                        </template>
                                                                                        <template v-else></template>
                                                                                    </template>
                                                                                </template>
                                                                            </template>
                                                                        </CDataTable>
                                                                    </CCol>
                                                                </CRow>
                                                                <CRow>
                                                                    <CCol lg="12" >
                                                                        <template v-if="(parent_tab == 1 
                                                                                        && (document_creator || document_signatory)
                                                                                            && has_attachment_changes)

                                                                                    || (parent_tab == 2 
                                                                                        && document_creator
                                                                                            && has_attachment_changes)

                                                                                    || (parent_tab == 3 
                                                                                        && document_creator
                                                                                            && has_attachment_changes)
                                                                                            ">
                                                                            <CButton
                                                                                color="secondary" 
                                                                                @click="resetAttachmentChanges()"
                                                                            >
                                                                                <font-awesome-icon icon="eraser"/> Reset Attachment Changes
                                                                            </CButton>
                                                                            &nbsp;
                                                                            <CButton
                                                                                :disabled="!$store.getters['can']('document-routing-update-internal-attachments') || warnings.length > 0"
                                                                                color="info" 
                                                                                @click="saveAttachmentChanges()"
                                                                            > 
                                                                                <font-awesome-icon icon="save"/>  {{external_files_counter > 0 || internal_files_counter > 0 ? 'Update Attachment Changes' : 'Save Attachment Changes'}}  
                                                                            </CButton>
                                                                            
                                                                            <a-popover title="WARNING!" placement="left">
                                                                                <template slot="content">
                                                                                    <span v-for="(item, index) in warnings" v-bind:key="index">
                                                                                        {{index + 1}}. {{item}} <br>
                                                                                    </span>
                                                                                </template>
                                                                                <font-awesome-icon 
                                                                                    v-if="warnings.length > 0"
                                                                                    style="color: orange; margin-left:10px" 
                                                                                    icon="triangle-exclamation" 
                                                                                    size="2xl"/>
                                                                            </a-popover>
                                                                        </template>
                                                                    </CCol>
                                                                </CRow>
                                                            </CTab>
                                                            <CTab>
                                                                <template slot="title">
                                                                    Remarks <CBadge color="success" v-if="signatory_remarks.length > 0">( {{signatory_remarks.length}} )</CBadge>
                                                                </template>
                                                                <template v-if="(parent_tab == 0 && edit && !signatory_added_remarks && $store.getters['can']('document-routing-save-remarks'))
                                                                                    || (parent_tab == 1 && !signatory_added_remarks && $store.getters['can']('document-routing-save-remarks'))
                                                                                        || (parent_tab == 2 && document_creator && !signatory_added_remarks && $store.getters['can']('document-routing-save-remarks'))
                                                                                            || (parent_tab == 3 && document_creator && !signatory_added_remarks && $store.getters['can']('document-routing-save-remarks'))">
                                                                    <CRow>
                                                                        <CCol lg="12">
                                                                            <CButton
                                                                                color="success"
                                                                                @click="addSignatoryRemarks()"
                                                                            >
                                                                                <font-awesome-icon icon="comment-alt"/> Add Remarks
                                                                            </CButton>
                                                                        </CCol>
                                                                    </CRow>
                                                                </template>
                                                                
                                                                <CRow>
                                                                    <CCol lg="12">
                                                                        <CDataTable
                                                                                :key="signatory_remarks_key"
                                                                                :items="signatory_remarks"
                                                                                :fields="signatory_remarks_field"
                                                                                :items-per-page="10"
                                                                                border
                                                                                size="sm"
                                                                                hover
                                                                                sorter
                                                                            >
                                                                            <template #action="{item, index}">
                                                                                <template v-if="parent_tab == 0 && item.owner_id == $store.getters.getUserDetails.id">
                                                                                    <td>
                                                                                        <CButton 
                                                                                            block
                                                                                            color="info" 
                                                                                            shape="pill" 
                                                                                            size="sm"
                                                                                            @click="item.recently_added ? saveMyRemarks(item, 'add') : saveMyRemarks(item, 'update') "
                                                                                        >
                                                                                            <font-awesome-icon icon="save"/> {{item.recently_added ? 'Save Remarks' : 'Update Remarks'}}
                                                                                        </CButton>
                                                                                        <CButton 
                                                                                            block
                                                                                            color="danger" 
                                                                                            shape="pill" 
                                                                                            size="sm"
                                                                                            @click="removedMyRemarks(item, index)"
                                                                                        >
                                                                                            <font-awesome-icon icon="times"/> Remove My Remarks
                                                                                        </CButton>
                                                                                    </td>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <template v-if="item.owner_id == $store.getters.getUserDetails.id">
                                                                                        <td>
                                                                                            <CButton 
                                                                                                block
                                                                                                color="info" 
                                                                                                shape="pill" 
                                                                                                size="sm"
                                                                                                @click="item.recently_added ? saveMyRemarks(item, 'add') : saveMyRemarks(item, 'update') "
                                                                                            >
                                                                                                <font-awesome-icon icon="save"/> {{item.recently_added ? 'Save Remarks' : 'Update Remarks'}}
                                                                                            </CButton>
                                                                                            <CButton 
                                                                                                block
                                                                                                color="danger" 
                                                                                                shape="pill" 
                                                                                                size="sm"
                                                                                                @click="removedMyRemarks(item, index)"
                                                                                            >
                                                                                                <font-awesome-icon icon="times"/> Remove My Remarks
                                                                                            </CButton>
                                                                                        </td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td>
                                                                                            ----
                                                                                        </td>
                                                                                    </template>
                                                                                </template>
                                                                            </template>
                                                                            <template #remarks="{item}"> 
                                                                                <template v-if="parent_tab == 0 && item.owner_id == $store.getters.getUserDetails.id">
                                                                                    <td v-if="edit">
                                                                                        <CTextarea
                                                                                            class="grp-text-area"
                                                                                            placeholder="Content.."
                                                                                            autocomplete="off"  
                                                                                            v-model="item.remarks"
                                                                                        />
                                                                                    </td>
                                                                                    <td v-else :title="item.remarks">
                                                                                        {{ item.remarks.length > 100 ? item.remarks.substring(0, 100) + '...' : item.remarks}} 
                                                                                    </td>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <template v-if="item.owner_id == $store.getters.getUserDetails.id">
                                                                                        <td>
                                                                                            <CTextarea
                                                                                                class="grp-text-area"
                                                                                                placeholder="Content.."
                                                                                                autocomplete="off"  
                                                                                                v-model="item.remarks"
                                                                                            />
                                                                                        </td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td :title="item.remarks">
                                                                                            {{ item.remarks.length > 100 ? item.remarks.substring(0, 100) + '...' : item.remarks}} 
                                                                                        </td>
                                                                                    </template>
                                                                                </template>
                                                                            </template>
                                                                            <template #date="{item}"> 
                                                                                <template v-if="!item.recently_added">
                                                                                    <td>
                                                                                        <small>{{ item.date }}</small> 
                                                                                    </td>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <td>
                                                                                        <small><CBadge color="success">Recently Added</CBadge></small>
                                                                                    </td>
                                                                                </template>
                                                                            </template>
                                                                        </CDataTable>
                                                                    </CCol>
                                                                </CRow>
                                                            </CTab>
                                                        </CTabs>
                                                    </CCol>
                                                </CRow>
                                            </CCardBody>
                                        </CCard>
                                    </CCol>
                                </CRow>
                            </CTab>
                            
                            <CTab title="Document Timeline" v-if="$store.getters['can']('view-document-routing-timeline')">
                                <CRow>
                                    <CCol lg="12">
                                        <CCard>
                                            <CCardBody>
                                                <a-timeline>
                                                     <a-timeline-item
                                                        v-for="(routing, parent_index) in routing_timeline.slice().reverse()" 
                                                        v-bind:key="parent_index"
                                                        :color="getColorStatus(routing.status)">

                                                        <template v-if="routing.status == 't-past'">
                                                            {{routing.date}} <br>
                                                            {{routing.message}} <br><br>
                                                            <CRow>
                                                                <CCol lg="6" v-if="routing.requirements.length > 0">
                                                                    <span>Signature Requirements<br></span>
                                                                    <span v-for="(requirement, past_index) in routing.requirements" v-bind:key="requirement.id">
                                                                        {{past_index+1}}. {{requirement.requirement}} <br>
                                                                    </span>
                                                                </CCol>
                                                                <CCol lg="6" v-if="routing.send_back_remarks.length > 0">
                                                                    <span >Send Back Remarks<br></span>
                                                                    <span v-for="(remark, past_remark_index) in routing.send_back_remarks" v-bind:key="remark.id">
                                                                        {{past_remark_index+1}}. {{remark.reason}}, {{changeDateFormat(remark.created_at)}} <br>
                                                                    </span>
                                                                </CCol>
                                                            </CRow>
                                                        </template>

                                                        <template v-if="routing.status == 't-cancelled'">
                                                            <span v-if="routing.date" style="color: #e25f5f">{{routing.date}} <br></span>
                                                            <span style="color: #e25f5f">{{routing.message}}</span> <br>
                                                            <i v-if="routing.remarks" style="color: #e25f5f">Reason/Remarks: {{routing.remarks}}<br><br></i> 
                                                            <span style="color: #e25f5f" v-if="routing.requirements.length > 0">Signature Requirements <br></span>
                                                            <span style="color: #e25f5f" v-for="(requirement, future_index) in routing.requirements" v-bind:key="requirement.id">
                                                                {{future_index+1}}. {{requirement.requirement}} <br>
                                                            </span>
                                                        </template>

                                                        <template v-if="routing.status == 't-present'">
                                                            <span v-if="routing.date" style="color: #4caf50">{{routing.date}} <br></span>
                                                            <span style="color: #4caf50"><strong>{{routing.message}}</strong></span> <br><br>
                                                            <CRow>
                                                                <CCol lg="6"  v-if="routing.requirements.length > 0">
                                                                    <span style="color: #4caf50">Signature Requirements<br></span>
                                                                    <span style="color: #4caf50" v-for="(requirement, present_index) in routing.requirements" v-bind:key="requirement.id">
                                                                        {{present_index+1}}. {{requirement.requirement}} <br>
                                                                    </span>
                                                                </CCol>
                                                                <CCol lg="6" v-if="routing.send_back_remarks.length > 0">
                                                                    <span style="color: #4caf50" >Send Back Remarks<br></span>
                                                                    <span style="color: #4caf50" v-for="(remark, past_remark_index) in routing.send_back_remarks" v-bind:key="remark.id">
                                                                        {{past_remark_index+1}}. {{remark.reason}}, {{changeDateFormat(remark.created_at)}} <br>
                                                                    </span>
                                                                </CCol>
                                                            </CRow>
                                                        </template>

                                                        <template v-if="routing.status == 't-future'">
                                                            <span v-if="routing.date">{{routing.date}} <br></span>
                                                            <span style="color: #a39e9e">{{routing.message}}</span><br><br>
                                                            <CRow>
                                                                <CCol lg="6" v-if="routing.requirements.length > 0">
                                                                    <span style="color: #a39e9e" >Signature Requirements<br></span>
                                                                    <span style="color: #a39e9e" v-for="(requirement, future_index) in routing.requirements" v-bind:key="requirement.id">
                                                                        {{future_index+1}}. {{requirement.requirement}} <br>
                                                                    </span>
                                                                </CCol>
                                                                <CCol lg="6" v-if="routing.send_back_remarks.length > 0">
                                                                    <span style="color: #a39e9e" >Send Back Remarks<br></span>
                                                                    <span style="color: #a39e9e" v-for="(remark, future_remark_index) in routing.send_back_remarks" v-bind:key="remark.id">
                                                                        {{future_remark_index+1}}. {{remark.reason}}, {{changeDateFormat(remark.created_at)}} <br>
                                                                    </span>
                                                                </CCol>
                                                            </CRow>
                                                        </template>
                                                    </a-timeline-item>
                                                </a-timeline>
                                            </CCardBody>
                                        </CCard>
                                    </CCol>
                                </CRow>
                            </CTab>
                        </CTabs>
                    </CCol>
                </CRow>
            </div>
        </div>
        <DRSignModal ref="DRSignModal" @fromModal="modalClose()"/>
        <UsersSelectableModal ref="usersSelectableModal" @selectedUser="userSelected($event)"/>
        <UploadFileViaUrlModal ref="_uploadFileViaURLModal" @documentFoundViaURLConfirmed="confirmedDocument($event)"/>
        <CModal 
            color="warning" 
            :show.sync="return_back_modal" 
            :closeOnBackdrop="false"
            centered
        >
            <template #header>
                <h5><font-awesome-icon icon="backspace" /> Return Document</h5>
                <CButton
                    class="pull-right"
                    color="light"
                    shape="pill"
                    size="sm"
                    @click="return_back_modal = false"
                >
                    <font-awesome-icon icon="times" />
                </CButton>
            </template>
            <template #body-wrapper>
                
            <CCardBody>
                <CRow>
                    <CCol lg="12">
                        <label>Select Signatory</label>
                        <v-select
                            label="name"
                            :options="signed_signatories"
                            v-model="send_back.user"
                        >
                        </v-select>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol lg="12">
                        <CTextarea
                            label="Remarks"
                            placeholder="Content..."
                            autocomplete="off"  
                            rows="3"
                            v-tocapitalize
                            v-model="send_back.reason"
                        />
                    </CCol>
                </CRow>
            </CCardBody>
            </template>
            <template #footer>
                <CRow>
                    <CButton
                        size="sm"
                        color="info" 
                        :disabled="!send_back.user || !send_back.reason"
                        @click="sendBack()"
                    >
                        <font-awesome-icon icon="backspace" /> Return
                    </CButton>
                </CRow>

            </template>
        </CModal>
        <CModal 
            color="dark" 
            :show.sync="change_signatory_modal" 
            centered
        >
            <template #header>
                <h5><font-awesome-icon icon="paperclip" /> Change Signatory</h5>
                <CButton
                    class="pull-right"
                    color="light"
                    shape="pill"
                    size="sm"
                    @click="change_signatory_modal = false"
                >
                    <font-awesome-icon icon="times" />
                </CButton>
            </template>
            <template #body-wrapper>
                
            <CCardBody>
                <CRow>
                    <CCol lg="12">
                        Selected Signatory: <i><h5>{{change_signatory.selected}}</h5></i>
                    </CCol>
                </CRow>
                <br>
                <CRow>
                    <CCol lg="12">
                        <label>Replace Selected Signatory to</label>
                        <v-select
                            label="signatory_name"
                            :options="template_signatory_list"
                            
                            v-model="change_signatory.new_signatory"
                            :class="!change_signatory.new_signatory ? 'has-error' : 'has-success'"
                        >
                            <template #option = "{signatory_name, signatory_purpose, signatory_requirements}">
                                <h6 style="margin: 0">{{ signatory_name }}</h6>
                                <small> Purpose : {{ signatory_purpose}}</small> <br>
                                <small> Requirements : {{ signatory_requirements.length}}</small>
                            </template>
                        </v-select>
                    </CCol>
                </CRow>
                <template v-if="change_signatory.new_signatory && change_signatory.new_signatory.signatory_requirements.length > 0">
                    <br>
                    <CRow>
                        <CCol lg="12">
                            <span style="color: red;">Please select requirement(s)*</span>
                        </CCol>
                        <br><br>
                        <template>
                            <span v-for="(requirement, index) in change_signatory.new_signatory.signatory_requirements" v-bind:key="index">
                                <CCol lg="6" >
                                    <p-check 
                                        class="p-icon p-bigger" 
                                        color="success" 
                                        :value="requirement.id"
                                        v-model="change_signatory.selected_requirements">
                                            <font-awesome-icon class="icon" icon="check"/>
                                            {{requirement.requirement}}
                                    </p-check> 
                                </CCol>
                            </span>
                        </template>
                    </CRow>
                </template>
                <br>
                <CRow>
                    <CCol lg="12">
                        <CTextarea
                            label="Reason"
                            placeholder="Content..."
                            autocomplete="off"  
                            rows="3"
                            v-tocapitalize
                            v-model="change_signatory.reason"
                        />
                    </CCol>
                </CRow> 
            </CCardBody>
            </template>
            <template #footer>
                <CRow>
                    <CButton
                        size="sm"
                        color="primary" 
                        :disabled="!change_signatory.new_signatory || !change_signatory.reason || (change_signatory.new_signatory.signatory_requirements.length > 0 && change_signatory.selected_requirements.length <= 0)"
                        @click="submitChangeSignatory()"
                    >
                        <font-awesome-icon icon="save" /> Submit Changes
                    </CButton>
                </CRow>

            </template>
        </CModal>
        <CModal 
            color="success" 
            :show.sync="add_signatory_modal" 
            centered
        >
            <template #header>
                <h5><font-awesome-icon icon="user" /> Add New Signatory</h5>
                <CButton
                    class="pull-right"
                    color="light"
                    shape="pill"
                    size="sm"
                    @click="add_signatory_modal = false"
                >
                    <font-awesome-icon icon="times" />
                </CButton>
            </template>
            <template #body-wrapper>
                
            <CCardBody>
                <CRow>
                    <CCol lg="12">
                        <label>Select New Signatory</label>
                        <!-- <v-select
                            label="signatory_name"
                            :options="template_signatory_list"
                            v-model="add_signatory.new_signatory"
                            :class="!add_signatory.new_signatory ? 'has-error' : 'has-success'"
                        >
                        </v-select> -->
                        <v-select
                            label="signatory_name"
                            :options="template_signatory_list"
                            v-model="add_signatory.new_signatory"
                            :class="!add_signatory.new_signatory ? 'has-error' : 'has-success'"
                        >
                            <template #option = "{signatory_name, signatory_purpose, signatory_requirements}">
                                <h6 style="margin: 0">{{ signatory_name }}</h6>
                                <small> Purpose : {{ signatory_purpose}}</small> <br>
                                <small> Requirements : {{ signatory_requirements.length}}</small>
                            </template>
                        </v-select>
                    </CCol>
                </CRow>
                <br>
                <CRow>
                    <CCol lg="4">
                        <label>Order</label>
                        <CInput 
                            :disabled="!document_creator"
                            type="text" 
                            maxlength="2"
                            onkeyup="if (/\D/g.test(this.value)) this.value = this.value.replace(/\D/g,'') "
                            placeholder="Order (1)" 
                            autocomplete="off"  
                            v-model="add_signatory.order"
                            :class="!add_signatory.order ? 'has-inp-error' : 'has-inp-success'"
                        />
                    </CCol>
                </CRow>
                <template v-if="add_signatory.new_signatory && add_signatory.new_signatory.signatory_requirements.length > 0">
                    <br>
                    <CRow>
                        <CCol lg="12">
                            <span style="color: red;">Please select requirement(s)*</span>
                        </CCol>
                        <br><br>
                        <template>
                            <span v-for="(requirement, index) in add_signatory.new_signatory.signatory_requirements" v-bind:key="index">
                                <CCol lg="6">
                                    <p-check 
                                        class="p-icon p-bigger" 
                                        color="success" 
                                        :value="requirement.id"
                                        v-model="add_signatory.selected_requirements">
                                            <font-awesome-icon class="icon" icon="check"/>
                                            {{requirement.requirement}}
                                    </p-check> 
                                </CCol>
                            </span>
                        </template>
                    </CRow>
                </template>
            </CCardBody>
            </template>
            <template #footer>
                <CRow>
                    <CButton
                        v-if="$store.getters['can']('document-routing-save-added-signatory')"
                        size="sm"
                        color="primary" 
                        @click="submitNewSignatory()"
                    >
                        <font-awesome-icon icon="user" /> Submit New Signatory
                    </CButton>
                </CRow>

            </template>
        </CModal>
        <CModal
            :show.sync="show_attachment_list"
            color="primary"
        >
            <template #header>
                <h5> <font-awesome-icon icon="tasks"/> Attachments Checklist </h5>
                <CButton 
                    class="pull-right" 
                    color="light" 
                    shape="pill" 
                    size="sm"
                    @click="show_attachment_list = false"
                >
                    <font-awesome-icon icon="times"/>
                </CButton>
            </template>
            
            <template #body-wrapper>
                <CRow>
                    <CCol lg="12">
                        <CListGroup>
                            <template v-if="template_change_status == 0 && routing.document_template">
                                <CListGroupItem v-for="(item, index) in routing.document_template.document_template_attachment_checklist" v-bind:key="index">
                                {{index+1}}. <strong>{{item.name}}</strong>
                                </CListGroupItem>
                            </template>
                        </CListGroup>
                    </CCol>
                </CRow>
            </template>
            <template #footer>
                <hr hide>
            </template>
        </CModal>
        <CModal
            :show.sync="large_list_modal"
            color="primary"
            size="lg"
            >
            <template #header>
                <h6> <font-awesome-icon icon="list"/> Select Reference Number </h6>
                <CButton 
                    class="pull-right" 
                    color="light" 
                    shape="pill" 
                    size="sm"
                    @click="large_list_modal = false"
                >
                    <font-awesome-icon  size="sm" icon="times"/>
                </CButton>
            </template>
            <template #footer>
                <hr>
            </template>
            <template #body-wrapper>
                <CCardBody>
                    <CRow>
                        <CCol lg="6">
                            <CInput 
                                type="text" 
                                placeholder="Search" 
                                autocomplete="off" 
                                v-model="large_list_search"
                            />
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol lg="12">
                            <CDataTable
                                :key="large_list_modal_key"
                                :items="large_list_items"
                                :fields="large_list_field"
                                border
                                sorter
                                @row-clicked="largeListSelectedItem"
                                pagination
                            >
                                <template #action="{item, index}">
                                    <td>
                                        <center>
                                            <CButton 
                                                color="danger" 
                                                @click="removeSignatoryRequirement(item, index)" 
                                                shape="pill" 
                                                size="sm"
                                            >
                                                <font-awesome-icon icon="times"/> Remove
                                            </CButton>
                                        </center>
                                    </td>
                                </template>
                            </CDataTable>
                        </CCol>
                    </CRow>
                </CCardBody>
            </template>
        </CModal>
    </div>
</template>
<script>

import { required,minLength } from "vuelidate/lib/validators"
import DRSignModal from '../document-routing/DRSignModal'
import UploadFileViaUrlModal from '../../modals/UploadFileViaURLModal'
import UsersSelectableModal from '../../modals/UsersSelectableModal'
import DocumentRoutingJS from './js/document-routing';
export default {
    name: 'DocumentRoutingTabsListsDetails',
    components: {
        DRSignModal,
        UploadFileViaUrlModal,
        UsersSelectableModal,
    },
    data() {
        return {
            internal_file_v2_key: 0,
            internal_files_v2: [],
            internal_files_v2_backup: [],

            tagged_attachments_id: [],
            tagged_attachments_id_backup: [],
            
            attachments_display_field: [
                {key: 'name'},
                // {key: 'remarks'},
                {key: 'status', label: 'Tag Status'},
            ],



            is_getting_file_to_add_internally: false,
            external_files_key:0,
            external_files_list: [],
            external_files_list_backup:[],
            
            large_list_search: '',
            large_list_modal: false,
            large_list_modal_key: 0,
            
            large_list: [],
            large_list_field: [
                { key: 'id'},
                { key: 'reference_number' },
            ],


            show_attachment_list: false,
            signatory_locked: false,

            add_signatory_modal: false,

            add_signatory:{
                new_signatory:null,
                selected_requirements:[],
                order: null
            },




            signatory_added_remarks: false,

            user_shared: {
                name: null,
                user_id: null
            },

            users_shared_routing_list: [],

            signatory_remarks_key: 0,
            signatory_remarks: [],
            signatory_remarks_backup: [],

            has_external_attachment: false,

            template_file_remarks_added: false,
            template_file_remarks_added_by_user: false,
            template_file_remarks:[],

            
            template_file_remarks_backup:[],

            order_input:[],

            order_input_NoCT:[],

            main_file_change: false,
            
            change_signatory_modal: false,
            template_signatory_list:[],
            change_signatory: {
                selected: null,
                selected_line_id: null,
                new_signatory:null,
                selected_requirements:[],
                reason: null,
            },

            
            return_back_modal: false,
            

            signed_signatories: [],
            
            send_back: {
                user: null,
                reason: null,
            },

            
            additional_slot:1,
            external_files_counter:0,
            external_file_max_file:20,
            internal_files_counter:0,
            
            signatories_and_share_tab:0,

            child_tab: 0,

            parent_tab: null,

            parent_tab_current_page: null,

            template_file: [],

            template_file_backup: [],

            show_lists: 0,

            data_loaded: false,

            routing: {
                id: null,
                description: null,
            },
            routing_backup: {
                description: null,
            },

            
            selected_template: null,
            selected_template_reference_number: [],

            
            selected_reference: null,
            selected_reference_remarks: '',
            selected_attachment: '',
            
            checked_internal_attachments: [],
            checked_internal_attachments_id: [],
            
            current_signatory: null,
            
            external_files:[],
            external_file_remarks:[],

            external_files_backup:[],
            external_file_remarks_backup:[],


            
            internal_files: [
            ],
            internal_files_fields: [
                {key: 'reference_number'},
                {key: 'checklist', label: 'Attachment' },
                {key: 'remarks'},
                {key: 'uploaded_by'},
                {key: 'date_uploaded'},
                {key: 'action'},
            ],

            internal_files_backup: [],

            routing_timeline: [],
            
            template_change_status: 0,

            document_routing_lines: [],
            signatories: [],

            
            checked_requirements_id: [],

            checked_requirements_old_id:[],

            disable: 1,
            edit: false,
            edit_order: false,
            change_template: false,

            editPanel: false,
            disablePanel: 1,

            user_template_lists_backup: [],

            templates_lists: [],

            editOrder: false,
            
            editOrderNoCT: false,


            recently_removed_external_attachments:[],
            recently_removed_internal_attachments:[],

            first_signatory: null,

            main_file_not_found: false,

            
            can_upload_file_via_url: false,
            main_file_via_url:false,

            removing_shared: false,
            removing_shared_id: null,
            tabBeforeAction:null,
            pageBeforeAction:null,
        }
    },
    computed: {
        isValid () { return !this.$v.routing.$invalid },
        isDirty () { return this.$v.routing.$anyDirty },
        document_routin_lines_data: function () {
            for (let i = 0; i < this.routing.document_routing_lines.length; i++) {
                if(this.routing.document_routing_lines[i].cancelled_at) {
                    this.routing.document_routing_lines[i]._classes = this.$store.getters.getDarkMode ? 'table-danger-dm' : 'table-danger';
                } else if(this.routing.document_routing_lines[i].signed_at) {
                    this.routing.document_routing_lines[i]._classes = this.$store.getters.getDarkMode ? 'table-success-dm' : 'table-success';
                }
            }
            return this.routing.document_routing_lines;
        },
        internal_files_children: function () {
            let custom_field = [
                {key: 'checkbox', label: ''},
                {key: 'file_name', label: 'File Name'},
                {key: 'file_type', label: ''},
            ]
            return custom_field;
        },
        document_signatory: function() {
            return this.routing.document_routing_lines.some((item) => { 
                                                            return item.document_template_signature.user_id == this.$store.getters.getUserDetails.id && item.cancelled_at == null
                                                        }
                                                    );
        },
        document_creator: function () {
            return this.$store.getters.getUserDetails.id == this.routing.user_id
        },
        last_signatory_id: function () {
            if(this.routing.document_routing_lines) {
                let signatories = this.routing.document_routing_lines.filter((signatories) => signatories.signature_id == null && signatories.cancelled_at == null);
                if(signatories.length > 1) {
                    return 0;
                }
                return signatories.at(-1).id;
            }
        },
        last_signatory_user_id: function () {
            if(this.routing.document_routing_lines) {
                let signatories = this.routing.document_routing_lines.filter((signatories) => signatories.signature_id == null && signatories.cancelled_at == null);
                if(signatories.length > 1) {
                    return 0;
                }
                return signatories.at(-1).document_template_signature.user_id;
            }
        },
        users_shared_routing_list_fields: function () {
            let custom_fields = [
                { key: 'name', label: 'Routing shared to'},
                { key: 'added_by'},
                { key: 'date_added'}
            ]
            if(this.parent_tab == 0 && this.edit) {
                custom_fields.push({key: 'action', _classes: 'th-fixed-width' })
            } else if(this.parent_tab == 1 || this.parent_tab == 2 || this.parent_tab == 3) { //
                custom_fields.push({key: 'action', _classes: 'th-fixed-width' })
            }
            return custom_fields;
        },
        signatory_remarks_field: function () {
            let custom_fields = [
                { key: 'remarks'},
                { key: 'remarks_by'},
                { key: 'date'},
            ]
            
            if(this.parent_tab == 0 && this.edit) {
                custom_fields.push({key: 'action', _classes: 'th-fixed-width' })
            } else if(this.parent_tab == 1 || this.parent_tab == 2 || this.parent_tab == 3) { //
                custom_fields.push({key: 'action', _classes: 'th-fixed-width' })
            }
            return custom_fields;
        },
        external_files_field: function () {
            let custom_fields= [
                {key: 'filetype', label: ''},
                {key: 'filename'},
                {key: 'filesize'},
                {key: 'remarks'},
                {key: 'checklist', label: 'Attachment' },
                {key: 'date_uploaded', _classes: 'td-fxd-wdth-upldd'},
                {key: 'uploaded_by', _classes: 'td-fxd-wdth-upldd'},
            ]

            if(this.parent_tab == 0 && this.edit) {
                custom_fields.push({key: 'action', _classes: 'td-actn-fxd-wdth' })
            } else if(this.parent_tab == 1 || this.parent_tab == 2 || this.parent_tab == 3) { //
                custom_fields.push({key: 'action', _classes: 'td-actn-fxd-wdth' })
            }
            // else {
            //     custom_fields.push({key: 'action' })
            // }
            return custom_fields;
        },
        internal_files_field_v2: function () {
            


            let custom_fields= [
                // {key: '', label: ''},
                {key: 'filetype', label: '', _classes: 'td-fxd-wdth'},
                {key: 'filename'},
                {key: 'filesize'},
                {key: 'remarks'},
                {key: 'checklist', label: 'Attachment' },
                {key: 'date_uploaded', _classes: 'td-fxd-wdth-upldd'},
                {key: 'uploaded_by', _classes: 'td-fxd-wdth-upldd'},
            ]

            if(this.parent_tab == 0 && this.edit) {
                custom_fields.push({key: 'action', _classes: 'td-actn-fxd-wdth' })
            } else if(this.parent_tab == 1 || this.parent_tab == 2 || this.parent_tab == 3) { //
                custom_fields.push({key: 'action', _classes: 'td-actn-fxd-wdth' })
            }
            // else {
            //     custom_fields.push({key: 'action' })
            // }
            return custom_fields;
        },
        document_routing_lines_field: function () {
            let custom_fields= [
                {key: 'name'},
                {key: 'purpose'},
                {key: 'order',},
                {key: 'required_signature', label: 'Signature'},
            ]

            if(this.template_change_status == 0) {
                custom_fields.push({key: 'signed', label: 'Status'})
            }

            custom_fields.push({key: 'requirements'})
            
            if(this.parent_tab == 0 
                && this.$store.getters.can('document-routing-manage-signatory') 
                    && ( this.$store.getters.getUserDetails.id == this.routing.user_id) 
                        && this.template_change_status == 0 && !this.signatory_locked) { 

                custom_fields.push({ key: 'action', sorter: false, _classes: 'th-fixed-width'})
            }
            else if(this.parent_tab == 1 
                && ( this.document_creator
                    && !this.signatory_locked
                        && this.$store.getters.can('document-routing-manage-signatory') ) ||
                        (this.parent_tab == 1
                            && this.document_signatory 
                                && !this.document_creator
                                        &&this.$store.getters.can('document-routing-manage-signatory'))
                         ) { 
                custom_fields.push({ key: 'action', sorter: false, _classes: 'th-fixed-width'})
            } 
            else if(this.parent_tab == 2
                && this.$store.getters.can('document-routing-manage-signatory') 
                    && this.document_creator
                        && !this.signatory_locked) {
                custom_fields.push({ key: 'action', sorter: false, _classes: 'th-fixed-width'})
            }

            return custom_fields;
        },
        large_list_items: function () {
            return this.large_list.filter((e) => e.reference_number.toLowerCase().indexOf(this.large_list_search.toLowerCase()) > -1);
        },
        attachments_checklist: function () {
            if(!this.routing.dtcs_id) {
                // return this.routing.document_template.document_template_category.document_template_category_set_attachment_checklist;
                return []
            } else {
                return this.routing.document_template_category_sets.document_template_category_set_attachment_checklist
            }
        },
        attachment_display_data: function () {
            return this.attachments_checklist.map(item => {
                const match = this.tagged_attachments_id.find(obj => obj === item.id);
                return match ? { ...item, status: 'used' } : { ...item, status: 'unused'} ;
            });
        },
        has_required_attachments: function () {
            if(this.attachments_checklist.filter(x => parseInt(x.required) == 1).length > 0) {
                return true;
            } 
            return false;
        },
        attachment_have_not_been_tagged: function () {
            if (this.has_required_attachments) {
                let required_attachment_id = this.attachments_checklist.filter(x => parseInt(x.required) == 1).map(x => x.id);
                let tagged_attachments_id = [...new Set(this.tagged_attachments_id)]
                if(!required_attachment_id.every(value => tagged_attachments_id.includes(value))) {
                    return true;
                } return false;
            }
        },
        warnings_for_saving_as_draft: function () {
            let list = []

            if(!this.routing.reference_number) {
                list.push("Reference number cannot be empty.")
            }

            if(this.template_file.length <= 0) {
                list.push("Main file cannot be empty.")
            }

            if(this.all_signatory_has_requirements && this.checked_requirements_id.length <= 0) {
                list.push("No default signatory found. Please select in the list.")
            }
            
            return list;
        },
        warnings_for_starting_routing: function () {
            let start_routing_list = []
            start_routing_list = JSON.parse(JSON.stringify(this.warnings_for_saving_as_draft));

            if(this.attachment_have_not_been_tagged) {
                start_routing_list.push("Some of the required attachment have not been tagged.")
            }
            return start_routing_list;
        },
        warnings: function () {
            return this.warnings_for_starting_routing
        },
        has_attachment_changes: function () {
            if(this.has_external_attachment_changes || this.has_internal_attachment_changes) {
                return true;
            } return false;
        },
        has_external_attachment_changes: function (){
            let newly_added_external = this.external_files_list.filter(x => !x.id);
            if(newly_added_external.length > 0 || this.recently_removed_external_attachments.length > 0) {
                return true;
            } return false;

        },
        has_internal_attachment_changes: function (){
            let newly_added_internal = this.internal_files_v2.filter(y => !y.id && !y.parent);
            if( newly_added_internal.length > 0|| this.checked_internal_attachments_id.length > 0) {
                return true;
            }
            return false
        },
    },
    validations: {
        routing: {
            document_template_id: { required },
            reference_number: { required, minLength: minLength(5)  },
        },
    },
    created() {
        this.getTemplates();
    },
    methods: {
        parentRowClickedInternal: function (item, index, column, event) {
            if(item.parent) {
                this.internal_files_v2.forEach(function (x) {
                    if(x.reference_number == item.reference_number && !x.parent) {
                        if(!x.hidden) {
                            x.hidden = true; // Update the age key directly
                        } else {
                            x.hidden = false;
                        }
                    }
                });
            }
        },
        clearExternalChecklist: function (item) {
            item.checklist = null;
            DocumentRoutingJS.removeSingleInstance(this.tagged_attachments_id, parseInt(item.checklist_old_id))
            item.checklist_old_id = null;
        },
        clearInternalChecklist: function (item) {
            item.checklist = null;
            DocumentRoutingJS.removeSingleInstance(this.tagged_attachments_id, parseInt(item.checklist_old_id))
            item.checklist_old_id = null;
        },
        withWarnings: function () {
            if(this.warnings.length > 0) {
                let x = []
                for (let i = 0; i < this.warnings.length; i++) {
                    x.push(`<li>${this.warnings[i]}</li>`)
                }
                this.$swal({
                    icon: "error",
                    title: "Routing cannot be started!",
                    html: `<ul style="text-align: left;">${x.join('')}</ul>`, 
                })
                return true;
            }
            return false;
        },
        resetAttachmentChanges: function () {
            this.external_files_key++;
            this.internal_file_v2_key++;
            this.internal_files_v2 = JSON.parse(JSON.stringify(this.internal_files_v2_backup))
            this.external_files_list = JSON.parse(JSON.stringify(this.external_files_list_backup))
            this.tagged_attachments_id = JSON.parse(JSON.stringify(this.tagged_attachments_id_backup))
            this.recently_removed_external_attachments = []
            this.checked_internal_attachments_id = []
        },
        selectChangeChecklistOnListExt: function (item, index) {
            DocumentRoutingJS.removeSingleInstance(this.tagged_attachments_id, parseInt(item.checklist_old_id))
            this.tagged_attachments_id.push(item.checklist.id)
            this.external_files_list.filter((object) => {
                if(object.checklist && object.checklist.id == item.checklist.id && object.filename == item.filename) {
                    object.checklist_old_id = item.checklist.id;
                }
            })
        },
        selectChangeChecklistOnListInt: function (item, index) {
            DocumentRoutingJS.removeSingleInstance(this.tagged_attachments_id, parseInt(item.checklist_old_id))
            this.tagged_attachments_id.push(item.checklist.id)
            this.internal_files_v2.filter((object) => {
                if(object.checklist && object.checklist.id == item.checklist.id && object.filename == item.filename) {
                    object.checklist_old_id = item.checklist.id;
                }
            })
        },
        submitDocument: function () {
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html: `<h4>You are trying to submit this document routing.</h4>
                Please be certain, Once this document routing has been <b>submitted</b> it is unable to be <i>undone</i>.`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                showLoaderOnConfirm: true,
                preConfirm: (cancel_remarks) => { 
                    this.$Progress.start()
                    return axios.post('/drs/document-routing/no-signature/submit', 
                    {document_routing_header_id: this.routing.id}, 
                    {validateStatus: () => true})
                    .then(response => {
                         if ( response.status == 200 ) {
                            this.getUserNotification(); // Manual Notification Refresh
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                html: `<b>${this.routing.reference_number}</b> Document Routing has been submitted.`,
                            }).then(() => {
                                this.getRoutingDetails();
                                // this.$emit('changeView', {current_tab: this.parent_tab, current_page: this.parent_tab_current_page})
                            })
                        }
                    })
                }
            })
        },
        completeDocument: function () {
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html: `<h4>You are trying to complete this document routing.</h4>
                Please be certain, Once this document routing has been <b>completed</b> it is unable to be <i>undone</i>.`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                showLoaderOnConfirm: true,
                preConfirm: (cancel_remarks) => { 
                    this.$Progress.start()
                    return axios.post('/drs/document-routing/no-signature/submit', 
                    {document_routing_header_id: this.routing.id}, 
                    {validateStatus: () => true})
                    .then(response => {
                         if ( response.status == 200 ) {
                            this.getUserNotification(); // Manual Notification Refresh
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                html: `<b>${this.routing.reference_number}</b> Document Routing has been completed.`,
                            }).then(() => {
                                this.getRoutingDetails();
                                // this.$emit('changeView', {current_tab: this.parent_tab, current_page: this.parent_tab_current_page})
                            })
                        }
                    })
                }
            })
        },
        removeExternalAttachment: function (item, index) {
            if(item.id != null) {
                this.recently_removed_external_attachments.push(item.id)
            }
            let f = this.external_files_list.indexOf(item);
            this.external_files_list.splice(f, 1);
            
            this.external_files_key++;
            if(item.checklist) {
                DocumentRoutingJS.removeSingleInstance(this.tagged_attachments_id, parseInt(item.checklist_old_id))
            }
            // if(item.checklist) {
            //     let arr = this.tagged_attachments_id.filter(obj => obj !== parseInt(item.checklist.id));
            //     this.tagged_attachments_id = arr;
            // }
        },
        onFileChange:function (e)
        {
            let files = e.target.files || e.dataTransfer.files;
            if(files.length > 0) {
                for (let index = 0; index < files.length; index++) {
                    const element = files[index];
                    if(element.type.split('/')[0] != 'image' 
                        && element.type != 'application/pdf' 
                            && element.type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
                                && element.type != 'application/vnd.ms-excel') 
                    {
                        this.$swal({
                            customClass: 'ta-left',
                            toast: true,
                            position: 'top-right',
                            timer: 5000,
                            html: `<div class="swal2-header">
                                    <ul class="swal2-progress-steps" style="display: none;"></ul>
                                    <div class="swal2-icon swal2-error swal2-icon-show" style="display: flex;">
                                        <span class="swal2-x-mark">
                                            <span class="swal2-x-mark-line-left"></span>
                                            <span class="swal2-x-mark-line-right"></span>
                                        </span>
                                    </div>
                                    <h5>Files Invalid. <br>Only accept pdf, images, and the latest excel file.</h5>
                                </div>`,
                            showConfirmButton: false,
                            timerProgressBar: true,
                        })
                        continue;
                    }


                    if (element.size / Math.pow(1024,1) > 8000) {
                        this.$swal({
                            customClass: 'ta-left',
                            toast: true,
                            position: 'top-right',
                            timer: 5000,
                            html: `<div class="swal2-header">
                                    <ul class="swal2-progress-steps" style="display: none;"></ul>
                                    <div class="swal2-icon swal2-error swal2-icon-show" style="display: flex;">
                                        <span class="swal2-x-mark">
                                            <span class="swal2-x-mark-line-left"></span>
                                            <span class="swal2-x-mark-line-right"></span>
                                        </span>
                                    </div>
                                    <h5>${element.name.length > 10 ? element.name.substring(0, 10) + '...' +  element.name.split('.')[1] : element.name} is  too large. Maximum size per file is 8MB.</h5>
                                </div>`,
                            showConfirmButton: false,
                            timerProgressBar: true,
                        })
                        continue;
                    } 

                    this.external_files_key++;
                    this.external_files_list.push(
                        {
                            file: element,
                            filetype: element.type,
                            filename: element.name,
                            filesize: this.formatBytes(element.size),
                            remarks: '',
                            uploaded: this.$store.getters['getUserDetails'].id,
                            uploaded_by: this.$store.getters['getUserDetails'].first_name,
                            date_uploaded: 'Recently Added',
                            id: null,
                            document_header_id: this.routing.id,
                            url: `#`,
                        }
                    )
                }
            }
            
            const input = this.$refs._externalFiles;
            input.type = 'text';
            input.type = 'file';
        },
        inputSearch: function ( list) { 
            this.large_list_modal_key+1;
            this.large_list = list;
            this.large_list_modal = true;
        },
        largeListSelectedItem: function (item, index, column, event) {
            this.selected_reference = item.reference_number
            this.selected_id = item.id
            this.large_list_modal = false;
        },
        updateReference: function() {
            if(JSON.stringify(this.routing.reference_number) === JSON.stringify(this.routing_backup.reference_number) &&
                    JSON.stringify(this.routing.description) === JSON.stringify(this.routing_backup.description)) {
                this.$swal({
                    icon: "info",
                    title: "Opps!",
                    text: "No changes has been made.",
                })
                return;
            }
            if(!this.routing.reference_number){
                this.$swal({
                    toast: true,
                    position: 'top-right',
                    timer: 3000,
                    icon: 'error',
                    title: 'Please fill up required fields.',
                    showConfirmButton: false,
                    timerProgressBar: true,
                });
                return;
            }
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                text: "You are trying to update the reference or description of the Document.",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    this.$Progress.start()
                    let data = {
                        reference_number: this.routing.reference_number,
                        description: this.routing.description,
                    }
                    return axios.post('/drs/document-routing/update-refence-number/' + this.routing.id, data, {validateStatus: () => true})
                    .then(response => {
                        if( response.status == 200 ) {
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                html: `<b>${this.routing.reference_number}</b> has been updated.`,
                            }).then(() => {
                                this.routing_backup.reference_number = JSON.parse(JSON.stringify(this.routing.reference_number))
                                this.routing_backup.description = JSON.parse(JSON.stringify(this.routing.description))
                                this.template_file = []
                                let main_file = {
                                    name: this.routing.reference_number+'.pdf',
                                    type: "application/pdf",
                                    size: this.routing.file_size,
                                    custom_size: 4000000,
                                    url: `${this.$backend_url_with_auth}/file-viewer/internal-attachments/${this.routing.id}/${this.routing.reference_number}?token=${this.$store.getters.getUserToken}&org_key=${this.$store.getters.getOrganizationKey}`
                                }

                                this.template_file.push(main_file)
                                
                                this.toggleEditPanel()
                                this.$Progress.finish()
                            })
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
            return;

        },
        returnPanel: function () {
            this.routing.reference_number = JSON.parse(JSON.stringify(this.routing_backup.reference_number))
            this.routing.description = JSON.parse(JSON.stringify(this.routing_backup.description))
        },
        toggleEditPanel: function () {
            this.editPanel = !this.editPanel
            this.disablePanel = (this.disablePanel + 1) % 2
        },
        addNewSignatory(document_creator, order=null){
            if(!document_creator && order) {
                this.add_signatory.order = parseInt(order) + 1;
            } else {
                this.add_signatory.order = ''
            }
            this.template_signatory_list = []
            this.$Progress.start()
            axios.post('/drs/document-routing/add-signatory-list', {document_routing_header_id: this.routing.id, document_template_id: this.routing.document_template_id}, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    for (let i = 0; i < response.data.data.length; i++) {
                        let _row = {
                            id: response.data.data[i].id,
                            user_id: response.data.data[i].user_id,
                            signatory_name: response.data.data[i].user.first_name + ' ' + response.data.data[i].user.last_name,
                            signatory_purpose : response.data.data[i].purpose,
                            signatory_requirements:response.data.data[i].document_signature_requirements,
                        }
                        this.template_signatory_list.push(_row)
                    }
                    this.add_signatory_modal = true;
                }
            })
            this.$Progress.finish()
        },
        submitNewSignatory: function (){
            if(!this.add_signatory.new_signatory || !this.add_signatory.order) {
                return this.$swal({
                    toast: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    icon: 'error',
                    title: `Please fill-up required fields.`,
                    showConfirmButton: false,
                    timerProgressBar: true,
                }) 
            }

            if(this.add_signatory.new_signatory.signatory_requirements.length > 0 && this.add_signatory.selected_requirements.length == 0){
                 return this.$swal({
                    toast: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    icon: 'error',
                    title: `Please select Requirements.`,
                    showConfirmButton: false,
                    timerProgressBar: true,
                }) 
            }

            let _rawData = {
                document_template_signature_id: this.add_signatory.new_signatory.id,
                user_id: parseInt(this.add_signatory.new_signatory.user_id),
                order: this.add_signatory.order
            }
            
            if(this.add_signatory.selected_requirements.length > 0) {
                _rawData.signature_requirement_ids = this.add_signatory.selected_requirements;
            }
            

            return this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html: `You are trying to add <b>${this.add_signatory.new_signatory.signatory_name}</b> as a signatory number <b>${this.add_signatory.order}</b> to <i>${this.routing.reference_number}</i>.`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    this.$Progress.start()
                    return axios.post('/drs/document-routing/save-add-signatory/'+this.routing.id, _rawData, {validateStatus: () => true})
                    .then(response => {
                        if ( response.status == 200 ) {
                            this.getUserNotification(); // Manual Notification Refresh
                            this.add_signatory_modal = false
                            this.$Progress.finish()
                            this.getRoutingDetails();
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                html: `<b>${this.add_signatory.new_signatory.signatory_name}</b> has been added as a Signatory!`,
                            })
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
        },
        openUploadFileViaURLModal: function (){
            this.$refs._uploadFileViaURLModal.modal_form = true;
            this.$refs._uploadFileViaURLModal.template = this.routing.document_template;
        },
        confirmedDocument: function (object){
            
            this.main_file_change = true;
            this.template_file = [];
            this.template_file.push(object.file)
            this.main_file_via_url = true;
            if(object.reference_number) {
                this.routing.reference_number = object.reference_number
            }
        },
        pullOutDocument: function (item) {
            if (!JSON.parse(JSON.stringify(this.$store.getters.getUserDetails.signature))) {
                return this.$swal({
                    toast: true,
                    customClass: 'ta-left',
                    position: 'top-right',
                    timer: 3000,
                    icon: 'error',
                    title: 'No e-signature found.',
                    html: `This will redirect you to profile page.`,
                    timerProgressBar: true,
                    showConfirmButton: false,
                }).then(() => {
                    this.$router.push(`/${this.$store.getters.getOrganizationName}/me`)
                });
            }

            if(this.signatory_locked) {
                this.$swal({
                    toast: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 4000,
                    // icon: 'error',
                    html: `<div class="swal2-header">
                                <ul class="swal2-progress-steps" style="display: none;"></ul>
                                <div class="swal2-icon swal2-error swal2-icon-show" style="display: flex;">
                                    <span class="swal2-x-mark">
                                        <span class="swal2-x-mark-line-left"></span>
                                        <span class="swal2-x-mark-line-right"></span>
                                    </span>
                                </div>
                                <h5>Adding, Changing or Removing of signatory is not allowed. <br> Template signatory is locked.</h5>
                            </div>`,
                    showConfirmButton: false,
                    timerProgressBar: true,
                })
                return;
            }
            let selected_signatory = item.document_template_signature.user.first_name + ' ' + item.document_template_signature.user.last_name;
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html: `You are trying remove <b>${selected_signatory}</b> as signatory to the routing.
                <br>
                <br>
                Add reason to continue.`,
                input: 'textarea',
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Enter reason to proceed",
                showLoaderOnConfirm: true,
                preConfirm: (pull_out_reason) => { 
                    this.$Progress.start()
                    if(!pull_out_reason) {
                        this.$swal.showValidationMessage('Reason cannot be empty.')
                        this.$Progress.fail()
                        return;
                    }

                    if(pull_out_reason.length < 5 ) {
                        this.$swal.showValidationMessage('Reason must not be less than 5 Characters.')
                        this.$Progress.fail()
                        return;
                    }
                     let _rawData = {
                        pull_out_line_id: item.id,  
                        remarks: pull_out_reason
                    }
                    return axios.post('/drs/document-routing/save-change-signatory/'+this.routing.id, _rawData, 
                    {validateStatus: () => true})
                    .then(response => {
                         if ( response.status == 200 ) {
                            this.getUserNotification(); // Manual Notification Refresh
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                html: `Signatory has been removed.`,
                            })
                            this.$Progress.finish()
                            this.getRoutingDetails();
                        }
                    })
                }
            })
        },
        submitChangeSignatory: function () {
            let _rawData = {
                pull_out_line_id: this.change_signatory.selected_line_id,  
                document_template_signature_id: this.change_signatory.new_signatory.id,
                user_id: parseInt(this.change_signatory.new_signatory.user_id),
                remarks: this.change_signatory.reason
                
            }
            if(this.change_signatory.selected_requirements.length > 0) {
                _rawData.signature_requirement_ids = this.change_signatory.selected_requirements;
            }
            return this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html: `You are trying to remove <b>${this.change_signatory.selected}</b> as a signatory to this routing with reference number: <i>${this.routing.reference_number}</i> and replace it with <b>${this.change_signatory.new_signatory.signatory_name}</b>.`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    this.$Progress.start()
                    return axios.post('/drs/document-routing/save-change-signatory/'+this.routing.id, _rawData, {validateStatus: () => true})
                    .then(response => {
                        if ( response.status == 200 ) {
                            this.getUserNotification(); // Manual Notification Refresh
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                html: `Signatory has been changed.`,
                            })
                            this.change_signatory_modal = false
                            this.$Progress.finish()
                            this.getRoutingDetails();
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
        },
        changeSignatory: function (item) {
            if (!JSON.parse(JSON.stringify(this.$store.getters.getUserDetails.signature))) {
                return this.$swal({
                    toast: true,
                    customClass: 'ta-left',
                    position: 'top-right',
                    timer: 3000,
                    icon: 'error',
                    title: 'No e-signature found.',
                    html: `This will redirect you to profile page.`,
                    timerProgressBar: true,
                    showConfirmButton: false,
                }).then(() => {
                    this.$router.push(`/${this.$store.getters.getOrganizationName}/me`)
                });
            }
            if(this.signatory_locked) {
                this.$swal({
                    toast: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 4000,
                    // icon: 'error',
                    html: `<div class="swal2-header">
                                <ul class="swal2-progress-steps" style="display: none;"></ul>
                                <div class="swal2-icon swal2-error swal2-icon-show" style="display: flex;">
                                    <span class="swal2-x-mark">
                                        <span class="swal2-x-mark-line-left"></span>
                                        <span clgetRoutingDetailsass="swal2-x-mark-line-right"></span>
                                    </span>
                                </div>
                                <h5>Adding, Changing or Pulling-Out of signatory is not allowed. <br> Template signatory is locked.</h5>
                            </div>`,
                    showConfirmButton: false,
                    timerProgressBar: true,
                })
                return;
            }

            this.$Progress.start()
            this.template_signatory_list = []
            this.change_signatory.selected =  item.document_template_signature.user.first_name + ' ' + item.document_template_signature.user.last_name
            this.change_signatory.selected_line_id = item.id
            axios.post('/drs/document-routing/change-signatory-list', {document_routing_header_id: this.routing.id}, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    let signatory_list = this.document_routin_lines_data
                    let exist_signatory = signatory_list.filter(x => x.signed_at == null && x._classes == null);
                    let uid_list = [];

                    for (let i = 0; i < response.data.data.length; i++) {
                        let _row = {
                            id: response.data.data[i].id,
                            user_id: response.data.data[i].user_id,
                            signatory_name: response.data.data[i].user.first_name + ' ' + response.data.data[i].user.last_name,
                            signatory_purpose : response.data.data[i].purpose,
                            signatory_requirements:response.data.data[i].document_signature_requirements,
                        }

                        exist_signatory.forEach(element => {
                            let uid = element.document_template_signature.user_id;
                            uid_list.push(uid);
                        });

                        if(!uid_list.includes(_row.user_id)){
                            this.template_signatory_list.push(_row);
                        }
                        // this.template_signatory_list.push(_row)
                    }
                    this.change_signatory_modal = true;
                    this.$Progress.finish()
                }
            })
        },
        showUserSelectableModal: function () {
            let selected_id = [];
            selected_id = this.routing.document_routing_lines.
                filter(x => !x.cancelled_at). // allow cancelled / removed user to be added in shared routing
                    map(x => parseInt(x.document_template_signature.user_id));
            this.users_shared_routing_list.forEach(function(obj){
                selected_id.push(parseInt(obj.shared_to_user_id));
            })
            selected_id.push(parseInt(this.routing.user_id))
            this.$refs.usersSelectableModal.users_selectable_modal = true,
            this.$emit('show_users_selectable_modal', selected_id);
        },
        removedMyRemarks: function (item, index) {
            this.signatory_remarks_key++;
            if(!item.recently_added) {
                axios.post('/drs/document-routing/remarks/remove', {document_routing_header_id: this.routing.id}, {validateStatus: () => true})
                .then(response => {
                    if(response.status == 200) {
                        
                        this.routing.document_routing_remarks.splice(index, 1)
                        this.routing_backup.document_routing_remarks.splice(index, 1)
                        this.signatory_remarks.splice(index, 1)
                        this.signatory_remarks_backup.splice(index, 1)

                        this.$swal({
                            toast: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 3000,
                            icon: 'success',
                            title: `Remarks succesfully removed.`,
                            showConfirmButton: false,
                            timerProgressBar: true,
                        })
                        // this.getRoutingDetails()
                    }
                })
            } else {
                this.signatory_remarks.splice(index, 1)
                this.signatory_added_remarks = false
            }
        },
        saveMyRemarks: function (item, _func) {
            if(!item.remarks) {
                return this.$swal({
                    toast: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    icon: 'error',
                    title: `Remarks cannot be empty.`,
                    showConfirmButton: false,
                    timerProgressBar: true,
                }) 
            }
            this.signatory_remarks_key++;

            let _data = {
                document_routing_header_id: this.routing.id,
                remarks: item.remarks,
            }

            let swalText = _func == 'add' ? 'You are trying to add remarks for this routing.' : 'You are trying to update your remarks for this routing.'
            let swalConfiText = _func == 'add' ? 'Remarks added!' : 'Remarks updated!'

            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                text: swalText,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    this.$Progress.start()
                    return axios.post('/drs/document-routing/remarks/save', _data, {validateStatus: () => true}).then(response => {
                        if ( response.status == 200 ) {
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                text: swalConfiText,
                            })
                            this.getRoutingDetails()
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
        },
        getUserShared: function() {
            this.$Progress.start()
            axios.get('/drs/document-routing/shared/' + this.routing.id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.users_shared_routing_list.length = 0;
                    let data = response.data.data
                    for (let i = 0; i < data.length; i++) {
                        let _row = {
                            id: data[i].id,
                            shared_by_user_id: data[i].shared_by_user_id,
                            added_by:  data[i].source == 'Default' ? 'Avante Admin' :  `${data[i].shared_by_user.first_name} ${data[i].shared_by_user.last_name}${data[i].shared_by_user.suffix ? ' '+data[i].shared_by_user.suffix: ''}`, 
                            shared_to_user_id: data[i].shared_to_user_id,
                            name: `${data[i].shared_to_user.first_name} ${data[i].shared_to_user.last_name}${data[i].shared_to_user.suffix ? ' '+data[i].shared_to_user.suffix: ''}`,
                            date_added: data[i].source  == 'Default' ? '----' : this.changeDateFormat(data[i].created_at),
                            recently_added: false, 
                            source: data[i].source, 
                        }
                        this.users_shared_routing_list.push(_row)
                    }
                    this.$Progress.finish()
                }
            })
        },
        saveUserShared: function () {
            let _data = {
                document_routing_header_id: this.routing.id,
                share: this.users_shared_routing_list.filter(item => item.recently_added === true)
            }
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                text: "You are trying to share this routing to the added user(s)",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    this.$Progress.start()
                    return axios.post('/drs/document-routing/shared/add', _data, {validateStatus: () => true}).then(response => {
                        if ( response.status == 200 ) {
                            this.$Progress.finish()
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                text: `Users added in shared routing.`,
                            })
                            this.getUserShared()
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
        },
        removeUserForSharedRouting: function (item, index) {
            this.removing_shared = true;
            this.removing_shared_id = item.id;
            this.$Progress.start()
            if(!item.recently_added) {
                let _row = {
                    id: item.id,
                    document_routing_header_id: this.routing.id,
                    shared_by_user_id: this.$store.getters['getUserDetails'].id,
                }
                axios.post('/drs/document-routing/shared/remove', _row, {validateStatus: () => true})
                .then(response => {
                    this.removing_shared_id = null;
                    this.removing_shared = false;
                    if(response.status == 200) {
                        this.$Progress.finish();
                        this.users_shared_routing_list.splice(index, 1)
                        return this.$swal({
                            toast: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 3000,
                            icon: 'success',
                            title: `${item.name} succesfully removed.`,
                            showConfirmButton: false,
                            timerProgressBar: true,
                        })
                    }
                })
            }
        },
        addUserForSharedRouting: function () {
            if(!this.users_shared_routing_list.filter(e => e.shared_to_user_id === this.user_shared.user_id).length > 0) {
                let _row = {
                    shared_by_user_id: this.$store.getters['getUserDetails'].id,
                    shared_to_user_id: this.user_shared.user_id,
                    name: this.user_shared.name,
                    added_by: this.$store.getters.getCurrentlyUserName,
                    recently_added: true,
                }

                this.user_shared.user_id = null;
                this.user_shared.name = null;

                this.users_shared_routing_list.push(_row)
            } else {
                 return this.$swal({
                    toast: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    icon: 'error',
                    title: `${this.user_shared.name} already exists.`,
                    showConfirmButton: false,
                    timerProgressBar: true,
                }) 
            }
        },
        userSelected: function (object) {
            let _data = {
                document_routing_header_id: this.routing.id,
                share: []
            }
            if (object.length > 0) {
                for (let i = 0; i < object.length; i++) {
                   let _row = {
                        shared_by_user_id: this.$store.getters['getUserDetails'].id,
                        shared_to_user_id: object[i],
                   }
                   _data.share.push(_row)
                }
            }
            this.$Progress.start()
            axios.post('/drs/document-routing/shared/add', _data, {validateStatus: () => true}).then(response => {
                if ( response.status == 200 ) {
                    this.$emit('show_users_selectable_modal_closed');
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        icon: 'success',
                        title: `User(s) added successfully!`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    }) 
                    
                    this.$Progress.finish()
                    this.getUserShared()
                }
            })
        },
        addTemplateFileRemarks: function () {
            this.signatory_added_remarks = true;
            this.template_file_remarks.push({
                recently_added: true,
                owner_id: this.$store.getters['getUserDetails'].id,
                owner_name: this.$store.getters['getUserDetails'].first_name,
                value: ''
            })
        },
        addSignatoryRemarks: function () {
            this.signatory_remarks_key++;
            this.signatory_added_remarks = true;
            let row = {
                id: null,
                recently_added: true,
                owner_id: this.$store.getters.getUserDetails.id,
                remarks_by: this.$store.getters.getUserDetails.first_name,
                date: null,
                remarks: '',
            }
            this.signatory_remarks.push(row);
        },
        checkIfValid: function (fieldName) {
            const field = this.$v.routing[fieldName]
            if (!field.$dirty) {
                return null
            } 
            return !(field.$invalid || field.$model === '')
        },
        goBack: function() {
            if(this.$route.query.tab != undefined) {
                this.$router.push(this.$route.path)
                this.change_view = false
            }
            this.$emit('DETAILS_TO_LIST', {current_tab: this.parent_tab, current_page: this.parent_tab_current_page, isGoBack: true})

        },
        changeTab: function(item) {
            this.child_tab = item
            this.routing_backup = [];
            this.template_file_backup = [];
            this.external_files_backup = [];
            this.external_file_remarks_backup = [];
            this.internal_files_backup = [];
            this.template_file_remarks_backup = [];
            
            item ? this.getRoutingTimeline() : this.getRoutingDetails()
        },
        signAndShareChangeTab: function(item) {
            this.signatories_and_share_tab = item
            switch(item) {
                case 1: 
                    // this.getUserShared();
                    break;
                case 2:
                    break;
                case 3:
                    break;
                case 4:
                    break;
            }
        },
        signDocument: function() {
            if (!JSON.parse(JSON.stringify(this.$store.getters.getUserDetails.signature))) {
                return this.$swal({
                    toast: true,
                    customClass: 'ta-left',
                    position: 'top-right',
                    timer: 3000,
                    icon: 'error',
                    title: 'No e-signature found.',
                    html: `This will redirect you to profile page.`,
                    timerProgressBar: true,
                    showConfirmButton: false,
                }).then(() => {
                    this.$router.push(`/${this.$store.getters.getOrganizationName}/me`)
                });
            }

            if(this.routing.MobileLink != undefined) {
                window.open(this.routing.MobileLink);
                return;
            }
            this.$refs.DRSignModal.sign_modal = true
            this.$refs.DRSignModal.document_routing_header_id = this.routing.id
        },
        toggleDetails:function(item, index) {
            this.$set(this.routing.document_routing_lines[index], '_toggled', !item._toggled)
        },
        toggleInternalAttachment: function(item, index) {
            this.$set(this.internal_files[index], '_toggled', !item._toggled)
        },
        toggleEdit: function() {
            this.edit = !this.edit
            this.disable = (this.disable + 1) % 2
            if(!this.disable) { 
                this.$v.$touch()
            }
            else {
                this.$v.$reset()
            }
        },
        returnInformation: function() {
            
            this.external_files_key++;
            this.internal_file_v2_key++;


            this.routing = JSON.parse(JSON.stringify(this.routing_backup))
            this.template_file = JSON.parse(JSON.stringify(this.template_file_backup))
            this.external_files = JSON.parse(JSON.stringify(this.external_files_backup))
            this.external_files_list = JSON.parse(JSON.stringify(this.external_files_list_backup))
            
            this.external_file_remarks = JSON.parse(JSON.stringify(this.external_file_remarks_backup))
            this.internal_files = JSON.parse(JSON.stringify(this.internal_files_backup))
            this.internal_files_v2 = JSON.parse(JSON.stringify(this.internal_files_v2_backup))
            this.signatory_remarks = JSON.parse(JSON.stringify(this.signatory_remarks_backup))
            this.checked_internal_attachments_id = []

            
            this.tagged_attachments_id = JSON.parse(JSON.stringify(this.tagged_attachments_id_backup))

            this.template_change_status = 0;
            
            this.main_file_change = false;

            if(this.routing.document_routing_remarks.find((u) => parseInt(u.user_id) === this.$store.getters['getUserDetails'].id)) {
                this.signatory_added_remarks = true
            } else {
                this.signatory_added_remarks = false
            }
            
            this.recently_removed_lines = []
            this.recently_removed_external_attachments = []
            this.recently_removed_internal_attachments = []

        },
        deleteTemplateFile: function (file) {
            var k = this.template_file.indexOf(file);
            if(k == 0) { // This is the default file
                this.main_file_change = true;
            }
            if (k !== -1) {
                this.template_file.splice(k, 1);
            } 
        },
        getTemplates: function() {
            axios.get('/drs/document-template-category/active-list', {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    for(let i = 0; i < response.data.data.length; i++) {
                        let option = {
                            id: response.data.data[i].id,
                            category: response.data.data[i].category,
                        }
                        this.templates_lists.push(option)
                    }
                }
            })
        },
        getRoutingDetails: function() {
            this.parent_tab_current_page = this.parent_tab_current_page
            this.data_loaded = false;
            this.$Progress.start();
            axios.get('/drs/document-routing/edit/' + this.routing.id, {validateStatus: () => true})
            .then(response => {
                if(response.status >= 200 && response.status <= 299) { // has partial data
                    if(response.data.message == 'File not found.') {
                        this.main_file_not_found = true;
                        this.$swal({
                            icon: "info",
                            title: "Partial Content!",
                            html: response.data.message,
                        })
                    }
                    this.routing = null
                    this.template_file = []
                    this.external_files = []
                    this.external_files_list= []
                    this.template_change_status = 0
                    this.recently_removed_external_attachments = []
                    this.recently_removed_internal_attachments = [];
                    this.checked_internal_attachments_id = [];
                    this.assignData(response.data.data)
                    
                }
            })
        },
        assignData: function(data) {
            // Main Data
            this.routing = data
            if(this.routing.dtcs_id && parseInt(this.routing.document_template.document_template_category.document_template_category_set_count) > 1) {
                // ivan
                this.routing.document_template.name = `${this.routing.document_template.name} ( ${this.routing.document_template_category_sets.name} )`
            }
            this.routing.status = this.getDocumentStatus(data.status)
            this.signatory_locked =data.document_template.signatory_lock  == '1' ? true : false

            if(this.routing.document_template.document_template_file_api) {
                this.can_upload_file_via_url = true;
            }

            //Setting Up Current Signatory ID
            this.signed_signatories = []
            for (let i = 0; i < this.routing.document_routing_lines.length; i++) {
                
                if((i+1) == this.routing.document_routing_lines.length) {
                    // this.last_signatory_v2 = this.routing.document_routing_lines[i].document_template_signature.user_id;
                }

                if(this.routing.document_routing_lines[i].order == "1") {
                    this.first_signatory = this.routing.document_routing_lines[i].id
                }
                if(this.routing.document_routing_lines[i].signed_at == null && this.routing.document_routing_lines[i].cancelled_at == null)  {
                    this.current_signatory = this.routing.document_routing_lines[i].document_template_signature;
                    break;
                } else {
                    this.current_signatory = null;
                    let signed_signatories_row = {
                        name: `${this.routing.document_routing_lines[i].document_template_signature.user.first_name} ${this.routing.document_routing_lines[i].document_template_signature.user.last_name} ${this.routing.document_routing_lines[i].document_template_signature.user.suffix ? this.routing.document_routing_lines[i].document_template_signature.user.suffix: ''}`,
                        document_routing_header_id: this.routing.document_routing_lines[i].document_routing_header_id,
                        document_routing_line_id: this.routing.document_routing_lines[i].id
                    }
                    if(!this.signed_signatories.some(s_signatories => s_signatories.document_routing_line_id === signed_signatories_row.document_routing_line_id)) {
                        this.signed_signatories.push(signed_signatories_row)
                    }
                }

            }

            // Lines
            this.checked_requirements_old_id = []
            for (let i = 0; i < this.routing.document_routing_lines.length; i++) {
                for (let j = 0; j < this.routing.document_routing_lines[i].document_signature_requirements.length; j++) {
                    this.checked_requirements_old_id.push(`${this.routing.document_routing_lines[i].document_signature_requirements[j].id}_${this.routing.document_routing_lines[i].id}_${this.routing.document_routing_lines[i].document_signature_requirements[j].requirement}_${this.routing.document_routing_lines[i].document_template_signature.user.first_name} ${this.routing.document_routing_lines[i].document_template_signature.user.last_name}`)
                }
            }

            //Template File
            
                // size: this.routing.file_size >= 4000000 ? 4000000 : this.routing.file_size,
            
            
            this.template_file = []
            this.template_file_backup = []
            let main_file = {
                name: this.routing.reference_number+'.pdf',
                type: "application/pdf",
                size: this.routing.file_size,
                custom_size: 4000000,
                url: `${this.$backend_url_with_auth}/file-viewer/internal-attachments/${this.routing.id}/${DocumentRoutingJS.encodedAndCleanFileName(this.routing.reference_number)}?token=${this.$store.getters.getUserToken}&org_key=${this.$store.getters.getOrganizationKey}`
            }
            if(!this.main_file_not_found) { // if file is found in the server.
                this.template_file.push(main_file)
            }

            this.template_file_remarks = []
            this.signatory_remarks = []
            this.signatory_added_remarks = false
            for (let i = 0; i < this.routing.document_routing_remarks.length; i++) {
                
                if(this.$store.getters.getUserDetails.id == this.routing.document_routing_remarks[i].user_id) {
                    this.signatory_added_remarks = true;
                }

                let _row = {
                    id: this.routing.document_routing_remarks[i].id,
                    recently_added: false,
                    owner_id: this.routing.document_routing_remarks[i].user.id,
                    owner_name: this.routing.document_routing_remarks[i].user.first_name,
                    value: this.routing.document_routing_remarks[i].remarks,
                }

                
                let row = {
                    id: this.routing.document_routing_remarks[i].id,
                    recently_added: false,
                    owner_id: this.routing.document_routing_remarks[i].user.id,
                    remarks_by: this.routing.document_routing_remarks[i].user.first_name,
                    date: this.changeDateFormat(this.routing.document_routing_remarks[i].created_at),
                    remarks: this.routing.document_routing_remarks[i].remarks,
                }

                this.template_file_remarks.push(_row)
                this.signatory_remarks.push(row)

            }

            

            // External Attachments
            this.internal_files = []
            let temp_internal_file=[]
            this.tagged_attachments_id = []

            // this.routing_attachments_carousel = []
            for (let index = 0; index < this.routing.document_routing_attachments.length; index++) {
                const element = this.routing.document_routing_attachments[index];
                this.tagged_attachments_id.push(parseInt(element.dtcsac_id))
                if(element.attachment_id == null) {
                    this.external_files_counter++;
                    this.has_external_attachment = true;
                    this.external_files_list.push(
                        {
                            filetype: element.file_mime,
                            checklist: this.attachments_checklist.filter(x => x.id == parseInt(element.dtcsac_id))[0],
                            checklist_old_id: parseInt(element.dtcsac_id),
                            filename: `${element.name}.${element.file_ext}`,
                            filesize: this.formatBytes(element.file_size),
                            remarks: element.remarks,
                            uploaded: element.user_id,
                            uploaded_by: `${element.user.first_name}`,
                            date_uploaded: this.changeDateFormat(element.created_at),
                            id: element.id,
                            document_header_id: this.routing.id,
                            url: `${this.$backend_url_with_auth}/file-viewer/external-attachments/${element.id}/${this.routing.id}/${DocumentRoutingJS.encodedAndCleanFileName(element.name)}?token=${this.$store.getters.getUserToken}&org_key=${this.$store.getters.getOrganizationKey}`,
                        }
                    )

                } else {
                    temp_internal_file.push(element)
                    if(!this.internal_files.some((arr) => { return arr.id === element.previous_document_routing_header.id})) {
                        this.internal_files.push(element.previous_document_routing_header);
                    }
                }
            }

            // this.tagged_attachments_id = [...new Set()]; // removing duplicate values

            if(this.external_files_counter > this.external_file_max_file) {
                this.external_file_max_file = this.external_files_counter
            }
            



            // Internal Files V2
            
            // let data_ = this.internal_files
            // let parent_file = {
            //     parent: true, 
            //     main:false,
            //     reference_number: data.reference_number, 
            //     attachment_id: null,
            //     filename: "", 
            //     file:"",
            //     filetype: "", 
            //     filesize: "", 
            //     fileext: "", 
            //     remarks: "", 
            //     checklist: null,
            //     checklist_old_id: null,
            //     _classes: 'parent-row',
            //     url: null,
            // }





            // this.internal_files_v2 = this.internal_files.sort((a, b) => a.price - b.price);
 
            let attachment_id = 0;
            this.internal_files_v2 = []

            for (let idx = 0; idx < temp_internal_file.length; idx++) {
                this.internal_files_counter++;
                const element = temp_internal_file[idx];
                let file = {
                    id: element.id,
                    parent: false, 
                    main: element.previous_document_routing_header.reference_number == element.name ? true : false,
                    reference_number: element.previous_document_routing_header.reference_number, 
                    attachment_id: element.attachment_id,
                    filename: element.name,
                    file: element.file,
                    filetype: element.file_mime,
                    fileext: element.file_ext,
                    filesize: this.formatBytes(element.file_size),
                    remarks: element.remarks,
                    checklist: element.document_template_category_set_attachment_checklist,
                    checklist_old_id: element.dtcsac_id,
                    _classes: '',
                    hidden: false,
                    date_uploaded: this.changeDateFormat(element.created_at),
                    uploaded_by: element.user.first_name,
                    uploaded: element.user_id,
                    url: `${this.$backend_url_with_auth}/file-viewer/external-attachments/${element.id}/${this.routing.id}/${DocumentRoutingJS.encodedAndCleanFileName(element.name)}?token=${this.$store.getters.getUserToken}&org_key=${this.$store.getters.getOrganizationKey}`,
                }
                if(attachment_id != parseInt(element.attachment_id)) {
                    let parent_file = {
                        id:null,
                        parent: true, 
                        main:false,
                        reference_number: element.previous_document_routing_header.reference_number, 
                        attachment_id: null,
                        filename: "", 
                        file:"",
                        filetype: "", 
                        hidden: false,
                        filesize: "", 
                        fileext: "", 
                        remarks: "", 
                        checklist: null,
                        checklist_old_id: null,
                        _classes: `parent-row ${this.$store.getters.getDarkMode ? 'parent-row-dark' : 'parent-row-light'}`,
                        date_uploaded: "",
                        uploaded_by: "",
                        uploaded: element.user_id,
                        url: null,
                    }
                    this.internal_files_v2.push(parent_file) // pushing parent file
                    this.internal_files_v2.push(file) // pushing main file
                    attachment_id = element.attachment_id;
                } else {
                    this.internal_files_v2.push(file) // pushing supporting file
                }
            }


            this.users_shared_routing_list.length = 0;
            let shareds = data.shareds;
            for (let n = 0; n < shareds.length; n++) {
                const element = shareds[n];
                let row = {
                    id: element.id,
                    shared_by_user_id: element.shared_by_user_id,
                    added_by: element.document_template_id ? 'Avante Admin' : element.shared_by_user.first_name,
                    shared_to_user_id: element.shared_to_user_id,
                    date_added: element.document_template_id ? '----' : this.changeDateFormat(element.created_at),
                    name: `${element.shared_to_user.first_name} ${element.shared_to_user.last_name}`,
                    recently_added: false, 
                }
                this.users_shared_routing_list.push(row)
            }

            // for (let i = 0; i < data.length; i++) {
            //     let _row = {
            //         id: data[i].id,
            //         shared_by_user_id: data[i].shared_by_user_id,
            //         added_by:  data[i].source == 'Default' ? 'Avante Admin' :  `${data[i].shared_by_user.first_name} ${data[i].shared_by_user.last_name}${data[i].shared_by_user.suffix ? ' '+data[i].shared_by_user.suffix: ''}`, 
            //         shared_to_user_id: data[i].shared_to_user_id,
            //         name: `${data[i].shared_to_user.first_name} ${data[i].shared_to_user.last_name}${data[i].shared_to_user.suffix ? ' '+data[i].shared_to_user.suffix: ''}`,
            //         recently_added: false, 
            //         source: data[i].source, 
            //     }
            //     this.users_shared_routing_list.push(_row)
            // }



            // Internal Attachments
            // let internal_counter = 0;
            // for (let i = 0; i < this.internal_files.length; i++) {
            //     let _tempAttachments = []
            //     let _tempRemarks;
            //     let _userUploader;
            //     let _userIDUploader;
            //     let _dateUploaded;
            //     let _checklist;
            //     let _checklist_old_id;
            //     for (let j = 0; j < this.routing.document_routing_attachments.length; j++) {
            //         if(this.routing.document_routing_attachments[j].attachment_id != null) { 
            //             if(this.internal_files[i].id == this.routing.document_routing_attachments[j].attachment_id) {
            //                 delete this.routing.document_routing_attachments[j].previous_document_routing_header
            //                 let _row;
            //                     _row = {
            //                         name: this.routing.document_routing_attachments[j].name,
            //                         file: this.routing.document_routing_attachments[j].file,
            //                         file_mime: this.routing.document_routing_attachments[j].file_mime,
            //                         remarks:this.routing.document_routing_attachments[j].remarks,
            //                         attachment_id: this.routing.document_routing_attachments[j].attachment_id,
            //                         id: this.routing.document_routing_attachments[j].id,
            //                         url: `${this.$backend_url_with_auth}/file-viewer/external-attachments/${this.routing.document_routing_attachments[j].id}/${this.routing.id}/${DocumentRoutingJS.encodedAndCleanFileName(this.routing.document_routing_attachments[j].name)}?token=${this.$store.getters.getUserToken}&org_key=${this.$store.getters.getOrganizationKey}`,
            //                     }
            //                 let attachment_object = this.attachments_checklist.filter(x => x.id == parseInt(this.routing.document_routing_attachments[j].dtcsac_id))[0]
            //                 _tempAttachments.push(_row)
            //                 _tempRemarks = this.routing.document_routing_attachments[j].remarks;
            //                 _checklist = attachment_object;
            //                 _checklist_old_id = this.routing.document_routing_attachments[j].dtcsac_id;
            //                 _userUploader = this.routing.document_routing_attachments[j].user.first_name;
            //                 _userIDUploader = this.routing.document_routing_attachments[j].user_id;
            //                 _dateUploaded = this.changeDateFormat(this.routing.document_routing_attachments[j].created_at);
            //             }
            //         }
            //     }
            //     this.internal_files[i].attachments = _tempAttachments
            //     this.internal_files[i].remarks = _tempRemarks
            //     this.internal_files[i].checklist = _checklist
            //     this.internal_files[i].checklist_old_id = _checklist_old_id
            //     this.internal_files[i].uploaded_by = _userUploader
            //     this.internal_files[i].uploaded = _userIDUploader
            //     this.internal_files[i].date_uploaded = _dateUploaded

            // }

            // Check Internal Attachments
            // this.checked_internal_attachments = []
            // this.$nextTick( () => {
            // for (let i = 0; i < this.internal_files.length; i++) {
            //     for (let j = 0; j < this.internal_files[i].attachments.length; j++) {
            //         let data = this.internal_files[i].attachments[j]
            //         let val = data.attachment_id+'@spacer@'+data.file+'@spacer@'+data.name+'@spacer@'+this.internal_files[i].reference_number+'@spacer@'+data.id
            //         this.checked_internal_attachments.push(val)
            //     }
            // }
            // })

            // If Completed tab and if the current loggedin has signatory, put it to last row.
            if(this.parent_tab == 3) {
                for(let x in this.template_file_remarks) this.template_file_remarks[x].owner_id == this.$store.getters.getUserDetails.id ? this.template_file_remarks.push( this.template_file_remarks.splice(x,1)[0] ) : 0;
            }

            
            // Backup
            this.routing_backup = JSON.parse(JSON.stringify(this.routing))
            this.template_file_backup.push(JSON.parse(JSON.stringify(main_file)))
            this.external_files_backup = JSON.parse(JSON.stringify(this.external_files))
            
            this.external_files_list_backup = JSON.parse(JSON.stringify(this.external_files_list))
            this.external_file_remarks_backup = JSON.parse(JSON.stringify(this.external_file_remarks))
            this.internal_files_backup = JSON.parse(JSON.stringify(this.internal_files))
            this.internal_files_v2_backup = JSON.parse(JSON.stringify(this.internal_files_v2))

            this.tagged_attachments_id_backup = JSON.parse(JSON.stringify(this.tagged_attachments_id))

            this.template_file_remarks_backup = JSON.parse(JSON.stringify(this.template_file_remarks))
            this.signatory_remarks_backup = JSON.parse(JSON.stringify(this.signatory_remarks))

            this.data_loaded = true;
            this.$Progress.finish()

            
            
            // this.$emit('dynamicChangeTab', {current_tab: (this.routing.status == 'draft') ? 0
            //                                                 : (this.routing.status == 'signing' && this.current_signatory_id == this.$store.getters.getUserDetails.id) ? 1 
            //                                                     : (this.routing.status == 'signing' && this.current_signatory_id != this.$store.getters.getUserDetails.id) ? 1
            //                                                     // : (this.routing.status == 'signing' && this.current_signatory_id != this.$store.getters.getUserDetails.id) ? 2
            //                                                         : (this.routing.status == 'completed') ? 3
            //                                                             : 4, 
            //                                 current_page: this.parent_tab_current_page,
            //                                 }, 
            //                         )

            if(this.routing.status == 'draft') {
                this.$emit('dynamicChangeTab', {current_tab: 0, current_page: this.parent_tab_current_page});
            } else if(this.routing.status == 'signing' && parseInt(this.current_signatory.user_id) == this.$store.getters.getUserDetails.id) {
                this.$emit('dynamicChangeTab', {current_tab: 1, current_page: this.parent_tab_current_page});
            } else if(this.routing.status == 'signing' && parseInt(this.current_signatory.user_id) != this.$store.getters.getUserDetails.id) {
                if(this.parent_tab == 2 || this.$route.query.tab && this.$route.query.tab == 'processing-list') {
                    this.$emit('dynamicChangeTab', {current_tab: 2, current_page: this.parent_tab_current_page});
                    this.$emit('LIST_LOADED')
                    return;
                }
                if(this.$route.query.tab != undefined) {
                    this.$router.replace({'query': null});
                }
                this.$emit('DETAILS_TO_LIST', {current_tab: this.parent_tab, current_page: this.parent_tab_current_page, removed_row: this.routing.id})
            } else if(this.routing.status == 'completed') {
                this.tabBeforeAction = this.parent_tab;
                this.pageBeforeAction = this.parent_tab_current_page
                this.$emit('dynamicChangeTab', {current_tab: 3, current_page: this.parent_tab_current_page, removed_row: this.routing.id, tabBeforeAction: this.tabBeforeAction, pageBeforeAction:this.pageBeforeAction});
            } else {
                this.$emit('dynamicChangeTab', {current_tab: 4, current_page: this.parent_tab_current_page, removed_row: this.routing.id, tabBeforeAction: this.tabBeforeAction, pageBeforeAction:this.pageBeforeAction});
            }
            this.$emit('LIST_LOADED')
        },
        startRouting: function () {
            if(this.withWarnings()) {
                return;
            }



            let requirements_counter = 0;
            var tbl = '';
            tbl += `<table border=1 class="table table-bordered table-sm" style="${this.$store.getters.getDarkMode ? 'color:white;' : 'color:#545454;'}">`;
            tbl += '<thead>';
            tbl += '<tr style="font-size: 1rem;">';
            tbl += '<td>Signatories</td><td>Requirements</td>';
            tbl += '</tr>';
            tbl += '</thead>';
            tbl += '<tbody>';
            for (let i = 0; i < this.checked_requirements_old_id.length; i++) {
                let statement = this.checked_requirements_old_id[i].split("_")
                let cancelled_signatory = this.document_routin_lines_data.filter(x => x.id == statement[1])[0];
                if(!cancelled_signatory.cancelled_at) {
                    requirements_counter++;
                    tbl += `<tr style="font-size: 12px;"><td style="text-align: left">${statement[3]}</td><td style="text-align: left">${statement[2]}</td></tr>`
                }
            }

           tbl += '</tbody>';
           tbl += '</table>';

            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html: `<h4>You are trying to start the routing.</h4>
                Please be certain, Once this routing <b>started</b> it will not be <i>editable</i> and unable to be <i>undone</i>.<br><br>
                ${requirements_counter > 0 ? ` The following table contains of checked signature requirements.<br><br>${tbl} `: ''}
                `,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Start routing",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    this.$Progress.start()
                    return axios.post('/drs/document-routing/start/'+ this.routing.id, {start_from_draft: true}, {validateStatus: () => true})
                    .then(response => {
                        if ( response.status == 200 ) {
                            this.getUserNotification(); // Manual Notification Refresh
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                html: `<b>${this.routing.reference_number}</b> Routing has been started.`,
                            }).then(() => {
                                this.$emit('DETAILS_TO_LIST', {current_tab: this.parent_tab, current_page: this.parent_tab_current_page, removed_row: this.routing.id})
                            })
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
        },
        getRoutingTimeline: function () {
            this.$Progress.start()
            axios.get('/drs/document-routing/timeline/' + this.routing.id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.routing_timeline = [];

                    let _created = {
                        date: `${this.changeDateFormat(response.data.data.created_at)}`,
                        message: `Document Created by ${response.data.data.user.first_name} ${response.data.data.user.last_name}`,
                        requirements: [],
                        send_back_remarks: [],
                    }
                    _created.status = !response.data.data.started_at ? 't-present': 't-past';

                    let _started = {
                        date: `${this.changeDateFormat(response.data.data.started_at)}`,
                        message: `Document Routing Started by ${response.data.data.user.first_name} ${response.data.data.user.last_name}`,
                        status: 't-past',
                        requirements: [],
                        send_back_remarks: [],
                    }

                    this.routing_timeline.push(_created)
                    if(response.data.data.started_at) {
                        this.routing_timeline.push(_started)
                    }
                    
                    let activity = response.data.data.document_routing_timeline_views

                    let signatory_counter=0;
                    if(activity.length > 0 ){ 
                        for (let i = 0; i < activity.length; i++) {
                            if(activity[i].signed_at) signatory_counter++;
                            let _row = {
                                message: `${activity[i].messages ? activity[i].messages : 'wala'}`,
                                requirements: activity[i].document_signature_requirements,
                                send_back_remarks: activity[i].document_routing_sendback_reasons,
                            }
                            if(activity[i].status == 1) {
                                _row.date = `${activity[i].signed_at ? this.changeDateFormat(activity[i].signed_at) : ''}`,
                                _row.status = 't-future'
                            } else if (activity[i].status == 2) {
                                _row.date = `${activity[i].signed_at ? this.changeDateFormat(activity[i].signed_at) : ''}`,
                                _row.status = !response.data.data.started_at ? 't-future' : 't-present'
                            } else if (activity[i].status == 3) {
                                _row.date = `${activity[i].signed_at ? this.changeDateFormat(activity[i].signed_at) : ''}`,
                                _row.status = 't-past'
                            } else if (activity[i].status == 4) {
                                _row.date = `${this.changeDateFormat(activity[i].cancelled_at)}`,
                                _row.remarks = activity[i].remarks
                                _row.status = 't-cancelled'
                            }
                            this.routing_timeline.push(_row)
                        }
                    }

                    let _completion = {
                        date: '',
                        message: response.data.data.status != 4 ? 'Document Completed.' : 'Document Cancelled.',
                        status:  response.data.data.status == 4 ? 't-cancelled-routing' : response.data.data.status == 3 ? 't-present' : signatory_counter == activity.length ? 't-present' : 't-future',
                        requirements: [],
                        send_back_remarks: [],
                    }
                    if(signatory_counter == activity.length) {
                        _completion.date = `${this.changeDateFormat(response.data.data.updated_at)}`
                    }
                    this.routing_timeline.push(_completion)
                    
                    this.$Progress.finish()
                }
            })
        },
        modalClose: function() {
            this.getRoutingDetails();
            this.getUserNotification(); // Manual Notification Refresh
        },
        cancelDocument: function () {
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html: `<h4>You are trying to cancel the routing.</h4>
                Please be certain, Once this routing has been <b>cancelled</b> it is unable to be <i>undone</i>.`,
                input: 'textarea',
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Enter remarks to proceed",
                showLoaderOnConfirm: true,
                preConfirm: (cancel_remarks) => { 
                    this.$Progress.start()
                    if(!cancel_remarks) {
                        this.$swal.showValidationMessage('Remarks cannot be empty.')
                        this.$Progress.fail()
                        return;
                    }

                    if(cancel_remarks.length < 5 ) {
                        this.$swal.showValidationMessage('Remarks must not be less than 5 Characters.')
                        this.$Progress.fail()
                        return;
                    }
                    return axios.post('/drs/document-routing/cancel/'+ this.routing.id, 
                    {remarks: cancel_remarks}, 
                    {validateStatus: () => true})
                    .then(response => {
                         if ( response.status == 200 ) {
                            this.getUserNotification(); // Manual Notification Refresh
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                html: `<b>${this.routing.reference_number}</b> Routing has been cancelled.`,
                            }).then(() => {
                                this.tabBeforeAction = this.parent_tab;
                                this.pageBeforeAction = this.parent_tab_current_page

                                this.getRoutingDetails();
                                // this.$emit('changeView', {current_tab: this.parent_tab, current_page: this.parent_tab_current_page})
                            })
                        }
                    })
                }
            })
        },
        validateRouting: function () // JS Validation
        {
            if( !this.routing.reference_number ||
                !this.routing.document_template_id ||
                ((this.template_change_status != 2)  ? this.routing.document_routing_lines : this.routing.document_routing_new_lines) <= 0
                ) return false;
            return true;
        },
        updateTemplate: function() {
            // console.log(this.external_files_list)
            // return;

            // if(this.has_required_attachments) {
            //     let _required_attachment_id = this.attachments_checklist.filter(x => parseInt(x.required) == 1).map(x => x.id);
            //     let _tagged_attachments_id = this.tagged_attachments_id
            //     if (_required_attachment_id.size !== _tagged_attachments_id.size) {
            //         return this.$swal({
            //             toast: true,
            //             position: 'top-right',
            //             timer: 3000,
            //             icon: 'error',
            //             title: `Some required attachments have not been tagged.`,
            //             showConfirmButton: false,
            //             timerProgressBar: true,
            //         }) 
            //     }

            //     const sortedArr1 = _required_attachment_id.slice().sort();
            //     const sortedArr2 = _tagged_attachments_id.slice().sort();

            //     // Compare sorted arrays
            //     for (let i = 0; i < sortedArr1.length; i++) {
            //         if (sortedArr1[i] !== sortedArr2[i]) {
            //             return this.$swal({
            //                 toast: true,
            //                 position: 'top-right',
            //                 timer: 3000,
            //                 icon: 'error',
            //                 title: `Some required attachments have not been tagged.`,
            //                 showConfirmButton: false,
            //                 timerProgressBar: true,
            //             }) 
            //         }
            //     }
            // }

            if(this.warnings_for_saving_as_draft.length > 0) {
                for (let i = 0; i < this.warnings_for_saving_as_draft.length; i++) {
                    x.push(`<li>${this.warnings_for_saving_as_draft[i]}</li>`)
                }
                this.$swal({
                    icon: "warning",
                    title: "Warning!",
                    html: `<ul style="text-align: left;">${x.join('')}</ul>`, 
                })
                return;
            }

            let added_lines=[];
            let requirements_ids=[];

            for (let k = 0; k < this.routing.document_routing_lines.length; k++) {
                if(this.routing.document_routing_lines[k].document_signature_requirements.length == 0) {
                    let row = {
                        order: this.routing.document_routing_lines[k].order,
                        document_template_signature_id: this.routing.document_routing_lines[k].document_template_signature_id,
                        document_routing_header_id : this.routing.id,
                        id: this.routing.document_routing_lines[k].id,
                    }
                    added_lines.push(row) 
                }
            }

            for (let j = 0; j < this.checked_requirements_old_id.length; j++) {
                const array_id = this.checked_requirements_old_id[j].split("_");
                for (let k = 0; k < this.routing.document_routing_lines.length; k++) {
                    let child_lists = this.routing.document_routing_lines[k].document_signature_requirements
                    for (let l = 0; l < child_lists.length; l++) {
                        if(array_id[0] == child_lists[l].id && array_id[1] == child_lists[l].pivot.document_routing_lines_id) {
                            requirements_ids.push(array_id[0])
                        }
                    }
                    if(array_id[1] == this.routing.document_routing_lines[k].id) {
                        let row = {
                            signature_requirement_ids: requirements_ids,
                            order: this.routing.document_routing_lines[k].order,
                            document_template_signature_id: this.routing.document_routing_lines[k].document_template_signature_id,
                            document_routing_header_id : this.routing.id,
                            id: this.routing.document_routing_lines[k].id,
                        }
                        if (!added_lines.filter(e => e.signature_requirement_ids === row.signature_requirement_ids && 
                                        e.document_template_signature_id === row.document_template_signature_id && 
                                        e.order === row.order ).length > 0) {
                            added_lines.push(row)
                            requirements_ids=[];
                        }
                    }
                }
            }
            
            let obj = {}
            added_lines.forEach((item)=>{
                if(obj[item.document_template_signature_id] && item.signature_requirement_ids){
                        let sample = obj[item.document_template_signature_id].signature_requirement_ids + "," +item.signature_requirement_ids
                        obj[item.document_template_signature_id].signature_requirement_ids = sample.split(",")
                }else{
                    obj[item.document_template_signature_id] = item
                }
            })

            let valuesArr = Object.values(obj)
            this.routing.document_routing_new_lines = valuesArr
        



            if( this.template_file.length <= 0 ) {
                return this.$swal({
                    toast: true,
                    position: 'top-right',
                    timer: 3000,
                    icon: 'error',
                    title: 'Please upload the selected file.',
                    timerProgressBar: true,
                    showConfirmButton: false,
                });
            } 

            let _dta = new FormData();

            _dta.append('document_template_id', this.routing.document_template_id);
            _dta.append('reference_number', this.routing.reference_number);
            _dta.append('description', this.routing.description);

            if(this.main_file_change)  {
                _dta.append('removed_original_file', 1)

                if(!this.main_file_via_url) {
                    for (const i of Object.keys(this.$refs._templateFile.fileRecords)) {
                        _dta.append('file[' + i + ']', this.$refs._templateFile.fileRecords[i].file)
                    }
                } else {
                    _dta.append('path', this.template_file[0].response_url)
                }
            } else {
                _dta.append('removed_original_file', 0)
                if(this.$refs._templateFile.fileRecords.length > 1) {
                    let c_index = 0;
                    for (const j of Object.keys(this.$refs._templateFile.fileRecords)) {
                        if(this.$refs._templateFile.fileRecords[j].file.lastModified != undefined) {
                            _dta.append('file[' + c_index + ']', this.$refs._templateFile.fileRecords[j].file)
                            c_index++;
                        }
                    }
                }
            }
            
            _dta.append("lines", JSON.stringify(this.routing.document_routing_new_lines))
            _dta.append("recently_removed_lines", JSON.stringify(this.routing.recently_removed_lines ? this.routing.recently_removed_lines : []))

            for (let e_attachment = 0; e_attachment < this.external_files_list.length; e_attachment++) {
                const element = this.external_files_list[e_attachment];
                _dta.append('external_attachment[' + e_attachment + '][document_routing_header_id]', this.routing.id)
                _dta.append('external_attachment[' + e_attachment + '][remarks]', element.remarks)
                _dta.append('external_attachment[' + e_attachment + '][name]', element.filename)
                _dta.append('external_attachment[' + e_attachment + '][dtcsac_id]', element.checklist_old_id ? element.checklist_old_id : null)
                if(element.id) {
                    _dta.append('external_attachment[' + e_attachment + '][id]', element.id)
                } else {
                    _dta.append('external_attachment[' + e_attachment + '][id]', "")
                    _dta.append('external_attachment[' + e_attachment + '][file]', element.file)
                }
            }
            _dta.append("recently_removed_external_attachments", JSON.stringify(this.recently_removed_external_attachments.length > 0 ? this.recently_removed_external_attachments : []))

            let _internalAttachments = [];
            for (let el = 0; el < this.internal_files_v2.length; el++) {
                const element = this.internal_files_v2[el];
                if(element.attachment_id) {
                    let row = {
                        id: element.id,
                        document_routing_header_id:  this.routing.id,
                        attachment_id: element.attachment_id,
                        file: element.file,
                        name: element.filename,
                        dtcsac_id: element.checklist_old_id,
                        remarks: element.remarks,
                    }
                    _internalAttachments.push(row)
                }
            }


            // for (let i = 0; i < this.checked_internal_attachments.length; i++) {
            //     let _iFile = this.checked_internal_attachments[i].split('@spacer@');
            //     let int_file = this.internal_files.filter(e => e.reference_number == _iFile[3])[0];
            //     let _internalAttachmentRow = {
            //         id:  parseInt(_iFile[4] != undefined ? _iFile[4] : null),
            //         document_routing_header_id:  this.routing.id,
            //         attachment_id: _iFile[0],
            //         file:  _iFile[1],
            //         name:  _iFile[2],
            //         remarks:  int_file.remarks,
            //         dtcsac_id:  int_file.checklist_old_id ? int_file.checklist_old_id : null,
            //     }
            //     _internalAttachments.push(_internalAttachmentRow);
            // }
              
            this.recently_removed_internal_attachments = this.checked_internal_attachments_id;
            
            if(_internalAttachments.length > 0) {
                _dta.append("internal_attachment", JSON.stringify(_internalAttachments))
            }
            _dta.append('recently_removed_internal_attachments', JSON.stringify(this.recently_removed_internal_attachments.length > 0 ? this.recently_removed_internal_attachments : []))

            if(this.validateRouting()) { 
                return this.$swal({
                    icon: "warning",
                    title: "Are you sure?",
                    html: `You are trying to update this Document Routing.`,
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes",
                    showLoaderOnConfirm: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    preConfirm: () => { 
                        this.$Progress.start()
                        return axios.post('/drs/document-routing/update/'+this.routing.id, _dta, {validateStatus: () => true}, { headers: { "Content-Type": "multipart/form-data" }} )
                        .then(response => {
                            if ( response.status == 200 ) {
                                this.$swal({
                                    icon: "success",
                                    title: "Success!",
                                    html: `${this.routing.reference_number} has been updated.`,
                                })
                                this.$Progress.finish()
                                this.getRoutingDetails();
                                this.toggleEdit();
                            }
                        })
                    }
                }).then((result) => {
                    if(!result.value){
                        this.$swal('Cancelled!','','error');
                    }
                })
            }
            this.$swal({
                toast: true,
                position: 'top-right',
                timer: 3000,
                icon: 'error',
                title: 'Please fill up required fields.',
                timerProgressBar: true,
                showConfirmButton: false,
            });
            return;
        },
        confirmChangeOrderNoCT: function () {
            let list = this.document_routin_lines_data;
            let order_value = [];


            for (let i = 0; i < list.length; i++) {
                const element = list[i];
                if(!element.order){
                    return this.$swal({
                        toast: true,
                        position: 'top-right',
                        timer: 3000,
                        icon: 'error',
                        title: `Order field cannot be empty.`,
                        timerProgressBar: true,
                        showConfirmButton: false,
                    })
                }
                
            }

            // for (let i = 0; i < list.length; i++) {
            //     // const input_new_value = document.getElementById("item_order_"+list[i].id).value;
            //     const input_new_value = this.order_input_NoCT[i];
            //     if(!input_new_value) {
            //         return this.$swal({
            //             toast: true,
            //             position: 'top-right',
            //             timer: 3000,
            //             icon: 'error',
            //             title: `Order field cannot be empty.`,
            //             timerProgressBar: true,
            //             showConfirmButton: false,
            //         })
            //     }
            //     order_value.push(input_new_value)
            // }

            var valueArr = list.map(function(item){ return item.order });
            var isDuplicate = valueArr.some(function(item, idx){ 
                return valueArr.indexOf(item) != idx 
            });

            if(isDuplicate){
                return this.$swal({
                    toast: true,
                    position: 'top-right',
                    timer: 3000,
                    icon: 'error',
                    title: `Order cannot be duplicate`,
                    timerProgressBar: true,
                    showConfirmButton: false,
                })
            }


            // if(list.some(x => list.indexOf(x) !== list.lastIndexOf(x))) {
            //     return this.$swal({
            //         toast: true,
            //         position: 'top-right',
            //         timer: 3000,
            //         icon: 'error',
            //         title: `Order cannot be duplicate`,
            //         timerProgressBar: true,
            //         showConfirmButton: false,
            //     })
            // } else {        
            //     for (let i = 0; i < list.length; i++) {
            //         // const value = document.getElementById("item_order_"+list[i].id).value;
            //         const value = this.order_input[i];
            //         list[i].order = value;
            //     }
            // }
            this.editOrderNoCT = false
            list.sort(function (x, y) {
                return x.order - y.order;
            });
        },
        confirmChangeOrder: function () {
            let list = this.signatories;
            let order_value = [];
            for (let i = 0; i < list.length; i++) {
                // const input_new_value = document.getElementById("item_order_"+list[i].id).value;
                const input_new_value = this.order_input[i];
                if(!input_new_value) {
                    return this.$swal({
                        toast: true,
                        position: 'top-right',
                        timer: 3000,
                        icon: 'error',
                        title: `Order field cannot be empty.`,
                        timerProgressBar: true,
                        showConfirmButton: false,
                    })
                }
                order_value.push(input_new_value)
            }
            if(order_value.some(x => order_value.indexOf(x) !== order_value.lastIndexOf(x))) {
                return this.$swal({
                    toast: true,
                    position: 'top-right',
                    timer: 3000,
                    icon: 'error',
                    title: `Order cannot be duplicate`,
                    timerProgressBar: true,
                    showConfirmButton: false,
                })
            } else {        
                for (let i = 0; i < list.length; i++) {
                    // const value = document.getElementById("item_order_"+list[i].id).value;
                    const value = this.order_input[i];
                    list[i].order = value;
                }
            }
            this.editOrder = false
            list.sort(function (x, y) {
                return x.order - y.order;
            });
        },
        getReference:function() {
            this.selected_template_reference_number = []
            axios.get('/drs/document-routing/reference-number/'+this.selected_template.id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    for(let i = 0; i < response.data.data.length; i++) {
                        let option = {
                            id: response.data.data[i].id,
                            reference_number: response.data.data[i].reference_number,
                        }
                        this.selected_template_reference_number.push(option)
                    }
                }
            })
        },
        removeInternalAttachment: function (item,index) {
            for (let j = 0; j < item.attachments.length; j++) {
                let data = item.attachments[j]
                let val;
                if(item.date_uploaded == 'Recently Added') {
                    val = data.attachment_id+'@spacer@'+data.file+'@spacer@'+data.name+'@spacer@'+item.reference_number+'@spacer@null'
                } else {
                    val = data.attachment_id+'@spacer@'+data.file+'@spacer@'+data.name+'@spacer@'+item.reference_number+'@spacer@'+data.id
                    
                    if(!this.checked_internal_attachments_id.includes(data.id)) {
                        this.checked_internal_attachments_id.push(data.id)
                    }
                }
                let q = this.checked_internal_attachments.indexOf(val)
                this.checked_internal_attachments.splice(q, 1);
            }
            let f = this.internal_files.indexOf(item);
            this.internal_files.splice(f, 1);

            if(item.checklist) {
                let arr = this.tagged_attachments_id.filter(obj => obj !== parseInt(item.checklist.id));
                this.tagged_attachments_id = arr;
            }
        },
        removeInternalFileV2(type, item, index) {
            // ivan
            this.internal_file_v2_key++;
            let group_internal_base_on_item = this.internal_files_v2.filter(f => f.reference_number == item.reference_number)
            if(type == 'parent_file') { // all file
                
                let all_item_with_id = group_internal_base_on_item.filter(t => t.id).map(y => y.id);
                for (let f = 0; f < all_item_with_id.length; f++) {
                    const element = all_item_with_id[f];
                    this.checked_internal_attachments_id.push(element);
                }

                let get_checklist_old_id_in_the_childs = group_internal_base_on_item.filter(j => j.checklist_old_id).map(j => j.checklist_old_id);
                for (let y = 0; y < get_checklist_old_id_in_the_childs.length; y++) {
                    const element = get_checklist_old_id_in_the_childs[y];
                    DocumentRoutingJS.removeSingleInstance(this.tagged_attachments_id, parseInt(element))
                }
                this.internal_files_v2 = this.internal_files_v2.filter(x => x.reference_number != item.reference_number);
            } else if (type == 'main_file') { // main file
                this.internal_files_v2.splice(index, 1)
                if(item.id) this.checked_internal_attachments_id.push(item.id);
            } else { // supporting file
                this.internal_files_v2.splice(index, 1)
                if(item.id) this.checked_internal_attachments_id.push(item.id);
            }

            if(group_internal_base_on_item.length == 2) {
                this.internal_files_v2 = this.internal_files_v2.filter(h => h.reference_number != item.reference_number)
            }

            if(item.checklist) {
                DocumentRoutingJS.removeSingleInstance(this.tagged_attachments_id, parseInt(item.checklist_old_id))
            }
        },
        addInternalFile: function(type) {
            if( !this.selected_template ||
                !this.selected_reference ) {
                return this.$swal({
                    toast: true,
                    position: 'top-right',
                    timer: 3000,
                    icon: 'error',
                    title: 'Please select reference number.',
                    timerProgressBar: true,
                    showConfirmButton: false,
                }) 
            }

            if(this.internal_files_v2.filter(x => x.reference_number == this.selected_reference).length > 0) {
                return this.$swal({
                    toast: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    icon: 'error',
                    title: `Reference number <b>${this.selected_reference}</b> already exists.`,
                    showConfirmButton: false,
                    timerProgressBar: true,
                }) 
            }

            this.is_getting_file_to_add_internally = true;
            let document_routing_id = this.selected_id;
            let internal_attachment_remarks = this.selected_reference_remarks;
            this.internal_file_v2_key++;
            this.$Progress.start()
            axios.get('/drs/document-routing/attachments/'+document_routing_id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    let data = response.data.data
                    let parent_file = {
                        id: null,
                        parent: true, 
                        main:false,
                        reference_number: data.reference_number, 
                        attachment_id: null,
                        filename: "", 
                        file:"",
                        filetype: "", 
                        filesize: "", 
                        hidden: false,
                        fileext: "", 
                        remarks: "", 
                        checklist: null,
                        checklist_old_id: null,
                        _classes: `parent-row ${this.$store.getters.getDarkMode ? 'parent-row-dark' : 'parent-row-light'}`,
                        uploaded_by: this.$store.getters['getUserDetails'].first_name,
                        uploaded: this.$store.getters['getUserDetails'].id,
                        url: null,
                    }
                    this.internal_files_v2.push(parent_file) // push parent file
                    let main_file = {
                        id: null,
                        parent: false, 
                        main: true,
                        hidden: false,
                        reference_number: data.reference_number,
                        attachment_id: data.id,
                        filename: data.reference_number,
                        file: data.file,
                        filetype:"application/pdf",
                        filesize: this.formatBytes(data.file_size),
                        fileext: "pdf", 
                        remarks: internal_attachment_remarks ? internal_attachment_remarks : '',
                        checklist: null,
                        checklist_old_id: null,
                        uploaded_by: this.$store.getters['getUserDetails'].first_name,
                        uploaded: this.$store.getters['getUserDetails'].id,
                        _classes: '',
                        url: `${this.$backend_url_with_auth}/file-viewer/internal-attachments/${document_routing_id}/${data.reference_number}?token=${this.$store.getters.getUserToken}&org_key=${this.$store.getters.getOrganizationKey}`
                        
                    }
                    this.internal_files_v2.push(main_file) // push main file
                    if(type == 'all_file') { // all file 
                        let extra_file = data.document_routing_attachments
                        for (let i = 0; i < extra_file.length; i++) {
                            let supporting_file_row = {
                                id: null,
                                parent: false, 
                                main: false,
                                reference_number: data.reference_number,
                                attachment_id: data.id,
                                hidden: false,
                                filename: extra_file[i].name,
                                file: extra_file[i].file,
                                filetype: extra_file[i].file_mime,
                                filesize: this.formatBytes(extra_file[i].file_size),
                                fileext: extra_file[i].file_ext, 
                                remarks: internal_attachment_remarks ? internal_attachment_remarks : '',
                                checklist: null,
                                uploaded_by: this.$store.getters['getUserDetails'].first_name,
                                checklist_old_id: null,
                                _classes: '',
                                url: `${this.$backend_url_with_auth}/file-viewer/external-attachments/${extra_file[i].id}/${document_routing_id}/${DocumentRoutingJS.encodedAndCleanFileName(extra_file[i].name)}?token=${this.$store.getters.getUserToken}&org_key=${this.$store.getters.getOrganizationKey}`,
                            }
                            this.internal_files_v2.push(supporting_file_row); // push supporting file
                        }
                    }
                    this.$Progress.finish()
                }
                
                this.is_getting_file_to_add_internally = false;
            })

            this.selected_template = null,
            this.selected_reference = null;
            this.selected_reference_remarks = null;
            this.selected_id = null;
            
            return;
            // this.$Progress.start()
            // let _row = {
            //     reference_number: this.selected_reference,
            //     checklist: this.selected_attachment ?? null,
            //     checklist_old_id: this.selected_attachment ? this.selected_attachment.id : null,
            //     uploaded_by: this.$store.getters['getUserDetails'].first_name,
            //     uploaded: this.$store.getters['getUserDetails'].id,
            //     date_uploaded: 'Recently Added',
            //     remarks: this.selected_reference_remarks ? this.selected_reference_remarks : ''
            // }
            // let attachments = [];
            
            // let dr_id = this.selected_id;
            // let atach_id = this.selected_attachment ? this.selected_attachment.id : null;

            // axios.get('/drs/document-routing/attachments/'+dr_id, {validateStatus: () => true})
            // .then(response => {
            //     if(response.status == 200) {

            //         let meyn_fayl = {
            //             name: response.data.data.reference_number,
            //             file: response.data.data.file,
            //             file_mime: "application/pdf",
            //             remarks: _row.remarks,
            //             attachment_id: response.data.data.id,
            //             id: null,
            //             url: `${this.$backend_url_with_auth}/file-viewer/internal-attachments/${dr_id}/${_row.reference_number}?token=${this.$store.getters.getUserToken}&org_key=${this.$store.getters.getOrganizationKey}`
            //         }

            //         if(type == 'all_file') { // all file 
            //             attachments.push(meyn_fayl);
            //             let file_attachments = response.data.data.document_routing_attachments
            //             for (let i = 0; i < file_attachments.length; i++) {
            //                 let _innerRow = {
            //                     name: file_attachments[i].name,
            //                     file: file_attachments[i].file,
            //                     file_mime: file_attachments[i].file_mime,
            //                     remarks: _row.remarks,
            //                     attachment_id: response.data.data.id,
            //                     id: null,
            //                     attachment_id: response.data.data.id,
            //                     url: `${this.$backend_url_with_auth}/file-viewer/external-attachments/${file_attachments[i].id}/${dr_id}/${DocumentRoutingJS.encodedAndCleanFileName(file_attachments[i].name)}?token=${this.$store.getters.getUserToken}&org_key=${this.$store.getters.getOrganizationKey}`,
            //                 }
            //                 attachments.push(_innerRow);
            //             }
            //         } else {
            //             attachments.push(meyn_fayl);
            //         }
                    
            //         _row.attachments = attachments;
            //         this.internal_files.push(_row);
            //         this.tagged_attachments_id.push(atach_id)

            //         this.$nextTick(() => {
            //             this.checked_internal_attachments = []
            //             for (let i = 0; i < this.internal_files.length; i++) {
            //                 for (let j = 0; j < this.internal_files[i].attachments.length; j++) {
            //                     let data = this.internal_files[i].attachments[j]
            //                     let val = data.attachment_id+'@spacer@'+data.file+'@spacer@'+data.name+'@spacer@'+this.internal_files[i].reference_number+'@spacer@'+data.id
            //                     this.checked_internal_attachments.push(val)
            //                 }
            //             }
            //         })
            //         this.$Progress.finish()
            //     }
            // })

            // this.selected_template = null,
            // this.selected_reference = null;
            // this.selected_attachment = null;
            // this.is_getting_file_to_add_internally = false;
            // this.selected_reference_remarks = null;
        },
        clickInternalAttachments: function(id) {
            if(id != null) {
                if(!this.checked_internal_attachments_id.includes(id)) {
                    this.checked_internal_attachments_id.push(id)
                } else {
                    const index = this.checked_internal_attachments_id.indexOf(id);
                    if (index > -1) {
                        this.checked_internal_attachments_id.splice(index, 1);
                    }
                }
            }
        },
        sendBack: function () {
            let data = {
                reason: this.send_back.reason,
                document_routing_header_id: this.send_back.user.document_routing_header_id,
                document_routing_line_id: this.send_back.user.document_routing_line_id,
            }
            let message = `You are trying to return this document to <b>${this.send_back.user.name}</b>.
                            <br> This action will remove all signatures after <i>${this.send_back.user.name}</i>.`;
            if(this.first_signatory == data.document_routing_line_id) {
                message = `You are trying to return this document to <b>${this.send_back.user.name}</b>.
                            <br>Please be certain. This action will also <b>remove</b> <u>all the signatures</u> added to the document.`
            }

            let index = this.routing.document_routing_lines.findIndex(x => x.id === data.document_routing_line_id)
            if(this.routing.document_routing_lines[index].document_template_signature.user_id == this.$store.getters['getUserDetails'].id) {
                message = `You are trying to return this document to <b>draft</b>.`
            }

            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html: message,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                preConfirm: () => { 
                    this.$Progress.start()
                    return axios.post('/drs/document-routing/return-document', data, {validateStatus: () => true})
                    .then(response => {
                        if ( response.status == 200 ) {
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                html: `<b>${this.routing.reference_number}</b> Document has been returned.`,
                            }).then(() => {
                                this.getUserNotification(); // Manual Notification Refresh
                                if(this.first_signatory != data.document_routing_line_id) {
                                    this.return_back_modal = false;
                                    this.getRoutingDetails();
                                } else {
                                    this.$emit('DETAILS_TO_LIST', {current_tab: this.parent_tab, current_page: this.parent_tab_current_page, removed_row: this.routing.id})
                                }
                            })
                        }
                    })
                },
                allowOutsideClick: () => false
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
        },
        returnCreator: function () {
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html: `<h4>You are trying return this document routing to ${parseInt(this.current_signatory.user_id) == this.routing.user_id ? 'draft' : 'creator'}.</h4>
                Please be certain, ${parseInt(this.current_signatory.user_id) == this.routing.user_id ? `this action is unable to be <i>undone</i>` : `this action will remove all the signatures added to the document.`}. <br>
                <br>
                Add reason to continue.`,
                input: 'textarea',
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Enter reason to proceed",
                showLoaderOnConfirm: true,
                preConfirm: (return_reason) => { 
                    this.$Progress.start()
                    if(!return_reason) {
                        this.$swal.showValidationMessage('Reason cannot be empty.')
                        this.$Progress.fail()
                        return;
                    }

                    if(return_reason.length < 5 ) {
                        this.$swal.showValidationMessage('Reason must not be less than 5 Characters.')
                        this.$Progress.fail()
                        return;
                    }
                    let data = {
                        reason: return_reason,
                        document_routing_header_id: this.routing.id,
                        document_routing_line_id: this.routing.document_routing_lines[0].id,
                    }
                    return axios.post('/drs/document-routing/return-document', data, 
                    {validateStatus: () => true})
                    .then(response => {
                         if ( response.status == 200 ) {
                            this.getUserNotification(); // Manual Notification Refresh
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                html: `<b>${this.routing.reference_number}</b> has been succesfully returned to ${parseInt(this.current_signatory.user_id) == this.routing.user_id ? 'draft' : 'creator'}.`,
                            }).then(() => {
                                this.$emit('DETAILS_TO_LIST', {current_tab: 1, current_page: 1, removed_row: this.routing.id})
                            })
                        }
                    })
                }
            })
        },
        saveAttachmentChanges: function () {
            if(this.has_external_attachment_changes && !this.has_internal_attachment_changes) {
                // has changes in external
                this.saveExternalAttachment();
            } else if(this.has_internal_attachment_changes && !this.has_external_attachment_changes) {
                // has changes in internal
                this.saveInternalAttachment();
            } else {
                this.$swal({
                    icon: "warning",
                    title: "Are you sure?",
                    text: `You are trying to ${this.external_files_counter > 0 || this.internal_files_counter > 0 ? 'update' : 'save'} the attachment changes.`,
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes",
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        this.$Progress.start()
                        return this.saveExternalAxios().then(resp => {
                            if(resp.status == 200) {
                                return this.saveInternalAxios().then(rsp => {
                                    if(rsp.status == 200) {
                                        this.$Progress.finish()
                                        this.$swal({
                                            icon: "success",
                                            title: "Success!",
                                            text: `Attachment has been updated.`,
                                        })
                                        this.getRoutingDetails();
                                    } else if (rsp.status == 300) {
                                        // this.checked_internal_attachments_id = [];
                                        // this.getRoutingDetails();
                                    }
                                })
                            } else if (resp.status == 300) {
                                this.recently_removed_external_attachments = [];
                                this.getRoutingDetails();
                            }
                        })
                    }
                })
            }
        },
        saveExternalAxios: function () {
            let _dta = new FormData();
            _dta.append('document_template_id', this.routing.document_template_id);


            for (let e_attachment = 0; e_attachment < this.external_files_list.length; e_attachment++) {
                const element = this.external_files_list[e_attachment];
                if(element.id) {
                    _dta.append('external_attachment[' + e_attachment + '][id]', element.id)
                    _dta.append('external_attachment[' + e_attachment + '][document_routing_header_id]', this.routing.id)
                    _dta.append('external_attachment[' + e_attachment + '][remarks]', element.remarks)
                    _dta.append('external_attachment[' + e_attachment + '][user_id]', element.uploaded)
                    _dta.append('external_attachment[' + e_attachment + '][name]', element.filename)
                    _dta.append('external_attachment[' + e_attachment + '][dtcsac_id]', element.checklist_old_id ? element.checklist_old_id : null)
                } else {
                    _dta.append('external_attachment[' + e_attachment + '][id]', "")
                    _dta.append('external_attachment[' + e_attachment + '][document_routing_header_id]', this.routing.id)
                    _dta.append('external_attachment[' + e_attachment + '][file]', element.file)
                    _dta.append('external_attachment[' + e_attachment + '][user_id]', element.uploaded)
                    _dta.append('external_attachment[' + e_attachment + '][remarks]', element.remarks)
                    _dta.append('external_attachment[' + e_attachment + '][name]', element.filename)
                    _dta.append('external_attachment[' + e_attachment + '][dtcsac_id]', element.checklist_old_id ? element.checklist_old_id : null)
                }
            }

            // for (const i of Object.keys(this.$refs._attachmentFiles.fileRecords)) {
            //     if(this.$refs._attachmentFiles.fileRecords[i].file.lastModified != undefined) {
            //         _dta.append('external_attachment[' + i + '][id]', "")
            //         _dta.append('external_attachment[' + i + '][document_routing_header_id]', this.routing.id)
            //         _dta.append('external_attachment[' + i + '][file]', this.$refs._attachmentFiles.fileRecords[i].file)
            //         _dta.append('external_attachment[' + i + '][remarks]', this.external_file_remarks[i] ? this.external_file_remarks[i] : '')
            //         _dta.append('external_attachment[' + i + '][name]', this.$refs._attachmentFiles.fileRecords[i].file.name)
            //     } else {
            //         let url = this.$refs._attachmentFiles.fileRecords[i].urlValue.split('/');
            //         _dta.append('external_attachment[' + i + '][id]', url[7])
            //         _dta.append('external_attachment[' + i + '][document_routing_header_id]', this.routing.id)
            //         _dta.append('external_attachment[' + i + '][remarks]', this.external_file_remarks[i] ? this.external_file_remarks[i] : '')
            //         _dta.append('external_attachment[' + i + '][name]', this.$refs._attachmentFiles.fileRecords[i].file.name)
            //     }
            // }

            if(this.recently_removed_external_attachments.length > 0) {
                _dta.append("recently_removed_external_attachments", JSON.stringify(this.recently_removed_external_attachments))
            //    for (let i = 0; i < this.recently_removed_external_attachments.length; i++) {
            //         _dta.append('recently_removed_external_attachments[' + i +']', JSON.stringify(this.recently_removed_external_attachments[i]))
            //    }
            }
            return new Promise((resolve, reject) => { 
                axios.post('/drs/document-routing/external-attachment/update/'+ this.routing.id, _dta, {validateStatus: () => true})
                .then(response => {
                    resolve(response)
                }).catch(function (err) {
                    console.log(err);
                })
            })
        },
        saveInternalAxios: function () {
            let _dta = new FormData();
            _dta.append('document_template_id', this.routing.document_template_id);

            let _internalAttachments = [];
            for (let el = 0; el < this.internal_files_v2.length; el++) {
                const element = this.internal_files_v2[el];
                if(element.attachment_id) {
                    let row = {
                        id: element.id,
                        document_routing_header_id:  this.routing.id,
                        attachment_id: element.attachment_id,
                        file: element.file,
                        name: element.filename,
                        dtcsac_id: element.checklist_old_id,
                        remarks: element.remarks,
                    }
                    _internalAttachments.push(row)
                }
            }

            // for (let i = 0; i < this.checked_internal_attachments.length; i++) {
            //     let _iFile = this.checked_internal_attachments[i].split('@spacer@');
            //     let int_file = this.internal_files.filter(e => e.reference_number == _iFile[3])[0];
            //     let _internalAttachmentRow = {
            //         id:  parseInt(_iFile[4] != undefined ? _iFile[4] : null),
            //         document_routing_header_id:  this.routing.id,
            //         attachment_id: _iFile[0],
            //         file:  _iFile[1],
            //         name:  _iFile[2],
            //         remarks:  int_file.remarks,
            //         dtcsac_id:  int_file.checklist_old_id ? int_file.checklist_old_id : null,
            //     }
            //     _internalAttachments.push(_internalAttachmentRow);
            // }
            // _dta.append("internal_attachment", JSON.stringify(_internalAttachments))

            this.recently_removed_internal_attachments = this.checked_internal_attachments_id;
            
            if(_internalAttachments.length > 0) {
                _dta.append("internal_attachment", JSON.stringify(_internalAttachments))
            }
            if(this.recently_removed_internal_attachments.length > 0) {
                _dta.append('recently_removed_internal_attachments', JSON.stringify(this.recently_removed_internal_attachments))
            }


            
            return new Promise((resolve, reject) => { 
                axios.post('/drs/document-routing/internal-attachment/update/'+ this.routing.id, _dta, {validateStatus: () => true})
                .then(response => {
                    resolve(response)
                }).catch(function (err) {
                    console.log(err);
                })
            })
        },
        saveExternalAttachment: function () {
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                text: `You are trying to ${this.external_files_counter > 0 || this.internal_files_counter > 0 ? 'update' : 'save'} the attachment changes.`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                preConfirm: () => { 
                    this.$Progress.start()
                    return this.saveExternalAxios()
                    .then(response => {
                        if ( response.status == 200 ) {
                            this.$Progress.finish()
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                text: `Attachment has been updated.`,
                            })
                            // this.recently_removed_external_attachments = [];
                            this.resetAttachmentChanges();
                            this.getRoutingDetails();
                        } else if (response.status == 300) {
                            // this.recently_removed_external_attachments = [];
                            this.resetAttachmentChanges();
                            this.getRoutingDetails();
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
        },
        saveInternalAttachment: function () {
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                text: `You are trying to ${this.external_files_counter > 0 || this.internal_files_counter > 0 ? 'update' : 'save'} the attachment changes.`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    this.$Progress.start()
                    return this.saveInternalAxios()
                    .then(response => {
                        if ( response.status == 200 ) {
                            this.$Progress.finish()
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                text: `Attachment has been updated.`,
                            })
                            // this.checked_internal_attachments_id = [];
                            this.resetAttachmentChanges();
                            this.getRoutingDetails();
                        } else if (response.status == 300) {
                            // this.checked_internal_attachments_id = [];
                            // this.resetAttachmentChanges();
                            // this.getRoutingDetails();
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
        }
    },
    watch: {
        template_change_status: function (value) {
            if(value == 0) this.signatory_locked = this.routing.document_template.signatory_lock == '1' ? true : false
            if(value == 1) this.signatory_locked = false
        },
        selected_template: function(value) {
            if(value) {
                this.getReference();
                this.selected_reference = null;
                this.selected_id = null;
                this.selected_reference_remarks = null;
            } else {
                this.selected_reference = null;
                this.selected_id = null;
                this.selected_reference_remarks = null;
            }
        },
        '$route': function (to, from) {
            if(to.query.tab == undefined) {
                this.change_view = false
                this.$emit('DETAILS_TO_LIST', {current_tab: this.parent_tab, current_page: this.parent_tab_current_page})
            } else if(to.query.tab) {
                this.routing.id = to.query.reference_id
                this.parent_tab = 1
                // this.parent_tab_current_page = 1
            }
        },
        signatories: function(value) {
            this.order_input = []
            for (let index = 0; index < value.length; index++) {
                this.order_input.push(parseInt(value[index].order))
            }
        },
        change_signatory_modal: function (value) {
            if(!value) {
                this.change_signatory.new_signatory = null;
                this.change_signatory.reason = null;
                this.change_signatory.selected = null;
                this.change_signatory.selected_line_id = null;
                this.change_signatory.selected_requirements = [];
            }
        },
        'change_signatory.new_signatory': function (value) {
            this.change_signatory.selected_requirements.length = 0;
        },
        add_signatory_modal: function(value){
            if(!value){
                this.add_signatory.new_signatory = null;
                this.add_signatory.order = '';
                this.add_signatory.selected_requirements=[];
            }
        },
        'add_signatory.new_signatory': function (value) {
            this.add_signatory.selected_requirements.length = 0;
        },
        large_list_modal: function (value) {
            if(!value) {
                this.large_list_search = '';
            }
        },
        parent_tab: function (value) {
            switch (value) {
                case 1: // For signing, set the tab to attachments
                    this.signatories_and_share_tab = 2;
                    break;
                default:
                    break;
            }
        }
    }
}
</script>
