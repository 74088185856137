<template>
    <CModal
        class="modal-dialog-adjusted"
        :show.sync="sign_modal"
        color="primary"
        size="xl"
        :closeOnBackdrop="false"
    >
        <template #header>
            <h5><font-awesome-icon icon="signature" /> Sign Document</h5>
            <CButton
                class="pull-right"
                color="light"
                shape="pill"
                size="sm"
                @click="(sign_modal = false), (document_routing_header_id = null)"
            >
                <font-awesome-icon icon="times" />
            </CButton>
        </template>
        <template #body-wrapper>
            <CRow>
                <CCol lg="12">
                    <center v-if="!loaded">
                        <font-awesome-icon style="margin: 20%;" icon="spinner" pulse size="6x"/>
                    </center>
                    <iframe
                        v-if="loaded"
                        :src="iframe.src"
                        :style="iframe.style"
                        :height="iframe.style.height"
                        :width="iframe.style.width"
                        frameborder="0"
                    ></iframe>
                </CCol>
            </CRow>
        </template>
        <template #footer hidden>
            <hr hide />
        </template>
    </CModal>
</template>
<style>
    .modal-dialog-adjusted > div > .modal-dialog  {
        max-width: 99% !important;
        margin: 5px auto !important; 
    }
    .modal-header {
        padding: 0.4rem 0.4rem !important;
    }
</style>
<script>
export default {
    name: "DocumentRoutingSignModal",
    data() {
        return {
            sign_modal: false,
            document_routing_header_id: null,
            loaded: false,
            iframe: {
                style: null,
                wrapperStyle: null,
                src: null,
            },
        };
    },
    mounted: function () {
        this.iframe.style = {
            width: "100%",
            height: "88vh",
        };
        this.iframe.wrapperStyle = {
            overflow: "hidden",
        };
    },
    computed: {},
    created() {},
    methods: {
        loadSigning: function () {
            this.$Progress.start()
            let data = new FormData();
            data.append("documentRoutingHeaderId", this.document_routing_header_id);
            return axios
                .post("/drs/document-processing/signing", data)
                .then((response) => {
                if (response.status == 200) {
                    this.iframe.src = response.data;
                    this.loaded = true;
                    this.$Progress.finish()
                }
            });
        },
    },
    watch: {
        document_routing_header_id: function (value) {
            if (value != null) this.loadSigning();
        },
        sign_modal: function(value) {
            if(!value) {
                this.$emit('fromModal')
            }
        }
    },
};
</script>