<style scoped>
.cstm-btn{
    color: #0074D9 !important;
    border: 0 !important;
    background: none !important;
    box-shadow: none !important;
    border-radius: 0px !important;
}
.cstm-btn:hover {
    border: 0 !important;
    background: none !important;
    box-shadow: none !important;
    border-radius: 0px !important;
}
.cstm-btn:active {
    border: 0 !important;
    background: none !important;
    box-shadow: none !important;
    border-radius: 0px !important;
}
.cstm-btn:focus {
    border: 0 !important;
    background: none !important;
    box-shadow: none !important;
    border-radius: 0px !important;
}
.selected{
    color:white !important;
  }
</style>
<template>
    <div>
        
        <CRow v-if="!change_view">
            <CCol lg="2" md="2" sm="2" xs="2">
                <!-- <CButton
                    class="float-left"
                    color="success"
                    title="Advance Filter"
                    @click="refreshData()"
                >
                    <font-awesome-icon icon="arrows-rotate"/> Refresh Data
                </CButton> -->
            </CCol>
            <CCol lg="1" md="1" s="1" xs="1">
                <!-- <CButtonGroup>
                  <CButton 
                    @click="myViewPreference('table')"
                    :color="view_preference == 'table' ? 'primary' : 'light'" 
                    v-c-tooltip="{content:'View your Documents as Table View', placement: 'top'}"
                  >
                    <font-awesome-icon icon="th-list"/>
                  </CButton>
                  <CButton 
                    @click="myViewPreference('card')"
                    :color="view_preference == 'card' ? 'primary' : 'light'" 
                    v-c-tooltip="{content:'View your Documents as Card View', placement: 'top'}"
                  >
                    <font-awesome-icon icon="th"/>
                  </CButton>
                </CButtonGroup> -->
            </CCol>
            <CCol lg="2" md="4"  sm="4" xs="4">
                <!-- <VCalendar/> -->
            </CCol>
            <CCol lg="1" md="1" sm="1" xs="1">
                <CButton
                    :disabled="!list_loaded"
                    class="float-right"
                    color="primary"
                    title="Advance Filter"
                    @click="advanceFilter()"
                >
                    &nbsp;<font-awesome-icon icon="sliders-h" />&nbsp; 
                    <CBadge color="danger" shape="pill" v-if="numberOfFilter > 0">{{ numberOfFilter }}</CBadge>
                </CButton>
            </CCol>
            <CCol lg="4" md="4" sm="4" xs="4">
                <CInput 
                    :disabled="!list_loaded"
                    placeholder="Enter Reference Number or Description"
                    type="text" 
                    autocomplete="off" 
                    v-tocapitalize
                    v-nospecialcharacter
                    v-model="to_search.reference_number"
                    v-on:keyup.enter="searchData"
                >
                <template #append>
                    
                    <CButton 
                        :disabled="!list_loaded"
                        color="primary"
                        @click="searchData()"
                    >
                        <font-awesome-icon icon="search"/>
                    </CButton>
                </template>
                </CInput>
            </CCol>
            <CCol lg="2" md="2" sm="2" xs="2">
                <CButton
                    :disabled="!list_loaded"
                    class="float-right"
                    color="dark"
                    title="Advance Filter"
                    @click="clearSearch()"
                >
                    <font-awesome-icon icon="eraser"/> Remove Filter
                </CButton>
            </CCol>
        </CRow>
        <CRow>
            <CCol lg="12">
                <CTabs justified variant="pills" :active-tab="current_tab" @update:activeTab="changeTab" >
                    <CTab :disabled="!list_loaded">
                        <template slot="title">
                            <CButton
                                v-if="!change_view"
                                :disabled="!list_loaded"
                                :class="current_tab == 0 ? 'selected cstm-btn': 'cstm-btn'"
                                block
                                @click="refresh(0)"
                            >
                                Draft &nbsp;&nbsp;<font-awesome-icon icon="arrows-rotate" :pulse="!list_loaded" v-if="current_tab == 0"/>
                            </CButton>
                            <span v-if="change_view">
                                Draft
                            </span>
                        </template>
                    </CTab>
                    <CTab  :disabled="!list_loaded">
                        <template slot="title">
                            <CButton
                                v-if="!change_view"
                                :disabled="!list_loaded"
                                :class="current_tab == 1 ? 'selected cstm-btn': 'cstm-btn'"
                                block
                                @click="refresh(1)"
                            >
                                For Signing &nbsp;&nbsp;<font-awesome-icon icon="arrows-rotate" :pulse="!list_loaded" v-if="current_tab == 1"/>
                            </CButton>
                            <span v-if="change_view">
                                For Signing
                            </span>
                        </template>
                    </CTab>
                    <CTab title="" :disabled="!list_loaded"> 
                        <template slot="title">
                            <CButton
                                v-if="!change_view"
                                :disabled="!list_loaded"
                                :class="current_tab == 2 ? 'selected cstm-btn': 'cstm-btn'"
                                block
                                @click="refresh(2)"
                            >
                                In Process &nbsp;&nbsp;<font-awesome-icon icon="arrows-rotate" :pulse="!list_loaded" v-if="current_tab == 2"/>
                            </CButton>
                            <span v-if="change_view">
                                In Process
                            </span>
                        </template>
                    </CTab>
                    <CTab :disabled="!list_loaded">
                        <template slot="title">
                            <CButton
                                v-if="!change_view"
                                :disabled="!list_loaded"
                                :class="current_tab == 3 ? 'selected cstm-btn': 'cstm-btn'"
                                block
                                @click="refresh(3)"
                            >
                            Completed &nbsp;&nbsp;<font-awesome-icon icon="arrows-rotate" :pulse="!list_loaded" v-if="current_tab == 3"/>
                            </CButton>
                            <span v-if="change_view">
                                Completed
                            </span>
                        </template>
                    </CTab>
                    <CTab :disabled="!list_loaded">
                        <template slot="title">
                            <CButton
                                v-if="!change_view"
                                :disabled="!list_loaded"
                                :class="current_tab == 4 ? 'selected cstm-btn': 'cstm-btn'"
                                block
                                @click="refresh(4)"
                            >
                            Cancelled &nbsp;&nbsp;<font-awesome-icon icon="arrows-rotate" :pulse="!list_loaded" v-if="current_tab == 4"/>
                            </CButton>
                            <span v-if="change_view">
                                Cancelled
                            </span>
                        </template>
                    </CTab>
                </CTabs>
            </CCol>
        </CRow>
        <br>
        <DRTabsLists 
            :viewPreference='view_preference' 
            ref="document_lists" 
            @LIST_TO_DETAILS="detailsView($event)" 
            v-if="!change_view" 
            @LIST_LOADED="listLoaded()"
            @LIST_LOADED_FALSE="listLoadedFalse()"
            @REFRESH_DATA="refreshData()"
            :parentData="parentData" 
            @update-parent-data="handleParentDataUpdate"
            @update-parent-last-refresh-data="updateLastRefreshData"
            :lastRefreshData="last_refresh_data"

        />
        <DRTabsListsDetails 
            ref="document_details" 
            @DETAILS_TO_LIST="listsView($event)" 
            @LIST_LOADED="listLoaded()" 
            @dynamicChangeTab="changeTabFromChild($event)" 
            v-if="change_view"
        />
        <CModal
            :show.sync="custom_filter"
            color="primary"
            :closeOnBackdrop="false"
        >
            <template #header>
                <h5> <font-awesome-icon icon="sliders-h"/> Advance Filter </h5>
                <CButton 
                    class="pull-right" 
                    color="light" 
                    shape="pill" 
                    size="sm"
                    @click="custom_filter = false"
                >
                    <font-awesome-icon icon="times"/>
                </CButton>
            </template>
            <template #body-wrapper>
                <CCardBody>
                    <CRow>
                        <CCol lg="12">
                            <label>
                                Select Date Range:
                                <font-awesome-icon 
                                    style="color: #4caf50; " 
                                    icon="info-circle" 
                                    size="lg" 
                                    v-c-tooltip="{content:'The filter targets Date Updated, except in Drafts, filter targets Date Created.', placement: 'top'}"
                                />
                            </label>
                            <CDateRange :showClearBtn="true" ref="_CDateRange"/>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol lg="12">
                            <label> Document Creator  </label>
                            <v-select
                                :disbled="users.length < 0"
                                :options="users"
                                label="first_name"
                                v-model="to_search.account_manager"
                            >
                                <template #list-header>
                                    <li style="text-align: center">Search by first name</li>
                                </template>
                                <template v-slot:option="d_name">
                                    {{ d_name.first_name }} {{ d_name.last_name }} {{ d_name.suffix }}
                                </template>
                                <template v-slot:selected-option="s_name">
                                    {{ s_name.first_name }} {{ s_name.last_name }}{{ s_name.suffix }}
                                </template>
                            </v-select>
                        </CCol>
                    </CRow>
                    <br>
                    <CRow>
                        <CCol lg="12">
                            <label> Document Category </label>
                            <v-select
                                :disbled="templates.length < 0"
                                :options="templates"
                                label="category"
                                v-model="to_search.template"
                            >
                            </v-select>
                        </CCol>
                    </CRow>
                    <br>
                    <CRow>
                        <CCol lg="12">
                            <CButton
                                :disabled="submit_advance_filter"
                                class="float-right"
                                color="primary"
                                @click="!submit_advance_filter ? searchDataAdvanced() : ''"
                            >
                                <font-awesome-icon 
                                    :icon="!submit_advance_filter ? 'share-square' : 'circle-notch'"
                                    :pulse="submit_advance_filter"
                                /> Search
                            </CButton>
                        </CCol>
                    </CRow>
                </CCardBody>

            </template>
            <template #footer>
                <hr hide>
            </template>

        </CModal>
    </div>
</template>
<script>

import DRTabsLists from '../document-routing/DRTabsLists'
import DRTabsListsDetails from '../document-routing/DRTabsListsDetails'
import CDateRange from '../../components/CDateRange'
export default {
    name: 'DocumentRoutingsTab',
    components: {
        DRTabsLists,
        DRTabsListsDetails,
        CDateRange,
    },
    data() {
        return {
            view_preference: null,
            custom_filter: false,
            current_tab: null,
            selected_document: null,
            document_routing_id: null,
            submit_advance_filter: false,
            users: [],
            templates: [],
            to_search : {
                reference_number: null,
                account_manager: null,
                template: null,
                date_from: null,
                date_to: null,
            },
            change_view: this.$route.query.reference_id != undefined ? true : false,
            list_loaded: false,
            numberOfFilter : 0,
            draft_filter_count:0,
            signing_filter_count:0,
            processing_filter_count:0,
            completed_filter_count:0,
            cancelled_filter_count:0,
            filter_count:0,

            search:{
                reference_number: null,
                account_manager: null,
                template: null,
                date_from: null,
                date_to: null,
            },
            appliedFiltersByStatus: {
                reference_number: null,
                account_manager: null,
                template: null,
                date_from: null,
                date_to: null,   
            },
            parentData: {
                cancelledData: {}, 
                draftData: {},
                completedData: {},
                signingData:{},
                processingData:{}
            },
            is_updated_status:false,
            removed_row:{
                current_tab:null,
                id:null,
                current_page:null,
            },
            
            last_refresh_data: {
                drft: null,
                sgnng: null,
                prcssng: null,
                cmpltd: null,
                cnclld: null,
            },
            isFromList:false,
            isGoBackPage:false,
        }
    },
    created() {
        this.view_preference = this.$store.getters.getDocumentRouting;
        this.$emit('activeTab', 0);
       
        if(this.change_view) {
            this.current_tab = this.$route.query.tab == 'signing-list' ? 1 
                                : this.$route.query.tab == 'processing-list' ? 2 
                                    : this.$route.query.tab == 'completed-list' ? 3 
                                        : this.$route.query.tab == 'cancelled-list' ? 4 
                                            : 0
        } else {
            this.$nextTick(() => {
                let status = this.$route.query.tab ? this.$route.query.tab : 'draft-list'
                this.$refs.document_lists.slug = status
                this.$refs.document_lists.getDocuments();
                this.current_tab = this.$route.query.tab == 'signing-list' ? 1 
                                    : this.$route.query.tab == 'processing-list' ? 2 
                                        : this.$route.query.tab == 'completed-list' ? 3 
                                            : this.$route.query.tab == 'cancelled-list' ? 4 
                                                : 0
                                                
            })
        }
    },
    methods: {
        listLoadedFalse: function () {
            this.list_loaded = false;
        },
        refresh: function (val) {
            if(val == this.current_tab) {
                this.refreshData();
            }
        },
        updateLastRefreshData: function (obj) {
            switch(obj.slug) {
                case 'draft':
                    this.last_refresh_data.drft = obj.value;
                    break;
                case 'signing':
                    this.last_refresh_data.sgnng = obj.value;
                    break;
                case 'processing': 
                    this.last_refresh_data.prcssng = obj.value;
                    break;
                case 'completed':
                    this.last_refresh_data.cmpltd = obj.value;
                    break;
                case 'cancelled': 
                    this.last_refresh_data.cnclld = obj.value;
                    break;
            }

        },
        myViewPreference: function(value) {
            this.view_preference = value
        },
        listLoaded: function() {
            this.list_loaded = true;
        },
        clearSearch: function () {
            this.$refs.document_lists.isRemoveFilterClicked = true;
            this.to_search.reference_number = null,
            this.to_search.account_manager = null,
            this.to_search.template = null,
            this.to_search.date_from = null,
            this.to_search.date_to = null,
            this.$refs.document_lists.dashboard_reference_number = null
             
            this.$refs._CDateRange.range = null
            this.numberOfFilter = 0;

            this.appliedFiltersByStatus.account_manager = false;
            this.appliedFiltersByStatus.template = false;
            this.appliedFiltersByStatus.date_from = false;
            this.appliedFiltersByStatus.date_to = false;  

            this.current_tab == 0 ? this.$refs.document_lists.draft_data = {} 
                : this.current_tab == 1 ? this.$refs.document_lists.signing_data = {}
                    : this.current_tab == 2 ? this.$refs.document_lists.processing_data = {}
                        : this.current_tab == 3 ? this.$refs.document_lists.completed_data = {} 
                            : this.$refs.document_lists.cancelled_data = {}

            this.clearParentData();
            this.clearNumberOfFilterByStatus(this.current_tab);
            this.storeSelectedFilterByStatus(this.current_tab); 
            

            this.$refs.document_lists.getDocuments();
        },
        advanceFilter: function () {
            this.$Progress.start();
            this.getUsers();
            this.getTemplates();
            
            // Empty the reference number
            // this.to_search.reference_number = null
            this.custom_filter = true;
            this.$refs.document_lists.dashboard_reference_number = null
            // let today = new Date();
            // let minus2_month = today.setMonth(today.getMonth() - 1); // Today - 1 Month
            // let object = {
            //     end: new Date(),
            //     start: new Date(minus2_month)
            // }
            // this.$refs._CDateRange.range = object

        },
        searchDataAdvanced: function ()  {
            this.list_loaded = false
            this.submit_advance_filter = true;
            let to_search = {}
            
            // Empty the reference number
            this.to_search.reference_number = null

            if((this.$refs._CDateRange.range && this.$refs._CDateRange.range.start) && (this.$refs._CDateRange.range && this.$refs._CDateRange.range.end)){
                this.to_search.date_from = this.$refs._CDateRange.range.start.getFullYear() + "-" + ("0"+(this.$refs._CDateRange.range.start.getMonth()+1)).slice(-2) +"-"+("0" + this.$refs._CDateRange.range.start.getDate()).slice(-2);
                this.to_search.date_to = this.$refs._CDateRange.range.end.getFullYear() + "-" + ("0"+(this.$refs._CDateRange.range.end.getMonth()+1)).slice(-2) +"-"+("0" + this.$refs._CDateRange.range.end.getDate()).slice(-2);
                to_search.date_from = this.to_search.date_from;
                to_search.date_to = this.to_search.date_to;

                let initial_filter_count = this.filter_count;
                if(!this.appliedFiltersByStatus.date_from && !this.appliedFiltersByStatus.date_to){
                    this.filter_count = ++this.numberOfFilter;
                    this.clearParentData();
                }else{
                    this.filter_count = initial_filter_count;
                }

                this.numberOfFilter = this.filter_count;
                this.storeSelectedFilterByStatus(this.current_tab);
                this.appliedFiltersByStatus.date_from = true;
                this.appliedFiltersByStatus.date_to = true;

            } else if (this.appliedFiltersByStatus.date_from && this.appliedFiltersByStatus.date_to) {
                this.clearParentData();
                this.filter_count = --this.numberOfFilter;
                this.numberOfFilter = this.filter_count;
                this.appliedFiltersByStatus.date_from = false;
                this.appliedFiltersByStatus.date_to = false;

                this.to_search.date_from = null;
                this.to_search.date_to = null;
            }


            if(this.to_search.account_manager) {
                to_search.user_id = this.to_search.account_manager.id;
                let initial_filter_count = this.filter_count;
                if(!this.appliedFiltersByStatus.account_manager){
                    this.filter_count = ++this.numberOfFilter
                    this.clearParentData();
                }else{
                    this.filter_count = initial_filter_count
                }
                this.numberOfFilter = this.filter_count;
                this.storeSelectedFilterByStatus(this.current_tab);
                this.appliedFiltersByStatus.account_manager = true;

            } else if (this.appliedFiltersByStatus.account_manager) {
                this.clearParentData();
                this.filter_count = --this.numberOfFilter;
                this.numberOfFilter = this.filter_count;
                this.appliedFiltersByStatus.account_manager = false;
            }

            if(this.to_search.template) {
                to_search.document_template_category_id = this.to_search.template.id;
                let initial_filter_count = this.filter_count;
                if(!this.appliedFiltersByStatus.template){
                    this.filter_count = ++this.numberOfFilter
                    this.clearParentData();
                }else{
                    this.filter_count = initial_filter_count
                }
                this.numberOfFilter = this.filter_count;
                this.storeSelectedFilterByStatus(this.current_tab);
                this.appliedFiltersByStatus.template = true;

            } else if (this.appliedFiltersByStatus.template) {
                this.clearParentData();
                this.filter_count = --this.numberOfFilter;
                this.numberOfFilter = this.filter_count;
                this.appliedFiltersByStatus.template = false;
            }

            if(!this.to_search.form &&  this.to_search.date_from==null && this.to_search.date_to == null && this.to_search.account_manager==null && this.to_search.template==null) {
                this.submit_advance_filter = false;
                this.clearSearch();
               return this.$swal({
                    toast: true,
                    position: "top-right",
                    showConfirmButton: false,
                    timer: 3000,
                    icon: "error",
                    title: "Please Select a filter!",
                    timerProgressBar: true,
                })
            }

            this.$refs.document_lists.current_page = 1
            this.$refs.document_lists.slug = this.current_tab == 0 ? 'draft-list' 
                                                : this.current_tab == 1 ? 'signing-list'
                                                    : this.current_tab == 2 ? 'processing-list'
                                                        : this.current_tab == 3 ? 'completed-list' : 'cancelled-list'
            this.$refs.document_lists.isAlreadyFetched = true;
            this.$refs.document_lists.getDocuments(to_search);  
        },
        searchData: function () {
            // Empty advance search data


            this.to_search.account_manager = null
            this.to_search.template = null
            this.to_search.date_from = null
            this.to_search.date_to = null
            this.$refs._CDateRange.range = null

            this.change_view = false;
            this.$emit('emit_search', );
            this.numberOfFilter = 0;
            this.clearNumberOfFilterByStatus(this.current_tab);
            this.storeSelectedFilterByStatus(this.current_tab);
            this.$refs.document_lists.current_page = 1
            this.$refs.document_lists.slug = this.current_tab == 0 ? 'draft-list' 
                                                : this.current_tab == 1 ? 'signing-list'
                                                    : this.current_tab == 2 ? 'processing-list'
                                                        : this.current_tab == 3 ? 'completed-list' : 'cancelled-list'
            
            let isRefNumberEmpty = !this.to_search.reference_number || this.to_search.reference_number.trim().length === 0;
            isRefNumberEmpty ? this.clearSearch() : this.$refs.document_lists.getDocuments(this.to_search);
        },
        changeTab: function(item) {
            this.list_loaded = false
            
            if(this.$route.query.tab != undefined) {
                this.$router.push(this.$route.path)
                this.change_view = false
            }
            this.current_tab = item;
            this.change_view = false

            // this.clearChildData();
            

            this.$nextTick(() => {
                let status = (item == 0) ? 'draft-list' : 
                    (item == 1) ? 'signing-list' :
                    (item == 2) ? 'processing-list' :
                    (item == 3) ? 'completed-list' :
                    'cancelled-list';
                this.$refs.document_lists.dashboard_reference_number = null
                this.$refs.document_lists.slug = status
                this.$refs.document_lists.current_page = this.current_tab == 0 ? this.$refs.document_lists.draft_current_page 
                                                            : this.current_tab == 1 ? this.$refs.document_lists.signing_current_page
                                                                : this.current_tab == 2 ? this.$refs.document_lists.processing_current_page
                                                                    : this.current_tab == 3 ? this.$refs.document_lists.completed_current_page : this.$refs.document_lists.cancelled_current_page;

                this.$refs.document_lists.per_page = 12;

                this.$refs.document_lists.removed_row = this.removed_row.current_tab == 0 ? this.removed_row.id 
                                                            : this.removed_row.current_tab == 1 ? this.removed_row.id 
                                                                : this.removed_row.current_tab == 2 ? this.removed_row.id 
                                                                    : this.removed_row.current_tab == 3 ? this.removed_row.id  : this.removed_row.id ;

                if(this.is_updated_status){
                    this.$refs.document_lists.removed_row = null;
                }
                if(this.isGoBackPage){
                    this.$refs.document_lists.removed_row = null;
                }
                this.handleDataLoadedByStatus(this.current_tab);
                this.clearChildData();
                this.$refs.document_lists.isAlreadyFetched = true;
                this.isAdvancedFiltered() ? this.searchDataAdvanced() 
                    : this.isRefFiltered() ? this.$refs.document_lists.getDocuments(this.to_search) 
                        : this.$refs.document_lists.getDocuments();
            })
        },
        detailsView: function(object) {
            this.list_loaded = false
            this.change_view = true;
            this.$nextTick(function() {
                this.$refs.document_details.routing.id = object.id
                this.$refs.document_details.parent_tab = this.current_tab
                this.$refs.document_details.parent_tab_current_page = object.current_page
                this.$refs.document_details.getRoutingDetails();
                this.isFromList = object.isFromList;
            })

        },
        listsView: function (object) {
            this.list_loaded = false
            this.change_view = false;
            this.$nextTick(function() {
                let data =  {}
                
                if(this.to_search.date_from && this.to_search.date_to) {
                    data.date_from = this.to_search.date_from
                    data.date_to = this.to_search.date_to
                }
                if(this.to_search.reference_number) {
                    data.reference_number = this.to_search.reference_number
                }
                if(this.to_search.account_manager) {
                    data.user_id = this.to_search.account_manager.id
                }
                if(this.to_search.template) {
                    data.document_template_id = this.to_search.template.id
                }
                this.$refs.document_lists.slug = (this.current_tab == 0) ? 'draft-list' : 
                                                    (this.current_tab == 1) ? 'signing-list' :
                                                        (this.current_tab == 2) ? 'processing-list' :
                                                            (this.current_tab == 3) ? 'completed-list' :
                                                                'cancelled-list';
                this.$refs.document_lists.current_page = object.current_page;

                this.$refs.document_lists.per_page = 12;
                this.handleDataLoadedByStatus(this.current_tab);
                this.$refs.document_lists.removed_row = object.removed_row;
                this.is_updated_status = this.$refs.document_lists.removed_row == null ? false : true;  
                this.isGoBackPage = object.isGoBack             
                this.$refs.document_lists.getDocuments(data);
            });
            
        },
        changeTabFromChild: function(object) {
            this.current_tab = object.current_tab;
            this.$refs.document_details.parent_tab = this.current_tab
            this.$refs.document_details.parent_tab_current_page = object.current_page
            this.removed_row.current_tab = object.current_tab
            this.removed_row.id = object.removed_row
            this.removed_row.current_page = object.current_page
            if(object.removed_row){
                this.filterParentData(object.tabBeforeAction, object.removed_row, object.pageBeforeAction);
            }
        },
        getUsers:function () {
            axios.get('/users/active-list',  {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.users = response.data.data
                    this.$Progress.increase(50);
                }
            })
        },
        getTemplates:function () {
            axios.get('/drs/document-template-category/active-list',  {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.templates = response.data.data
                    this.$Progress.finish();
                }
            })
        },
        incrementNumberOfFilterByStatus: function(status){
            
            return  (status == 0) ? ++this.draft_filter_count : 
                                                    (status == 1) ? ++this.signing_filter_count :
                                                        (status == 2) ? ++this.processing_filter_count :
                                                            (status == 3) ? ++this.completed_filter_count :
                                                                ++this.cancelled_filter_count;
        },
        decrementNumberOfFilterByStatus: function(status){
            return  (status == 0) ? --this.draft_filter_count : 
                                                    (status == 1) ? --this.signing_filter_count :
                                                        (status == 2) ? --this.processing_filter_count :
                                                            (status == 3) ? --this.completed_filter_count :
                                                                --this.cancelled_filter_count;
        },
        displayNumberOfFilterByStatus: function(status){
            return  (status == 0) ? this.draft_filter_count : 
                                                    (status == 1) ? this.signing_filter_count :
                                                        (status == 2) ? this.processing_filter_count :
                                                            (status == 3) ? this.completed_filter_count :
                                                                this.cancelled_filter_count;
        },
       clearNumberOfFilterByStatus: function(status){
            return  (status == 0) ? this.draft_filter_count = 0 : 
                                                    (status == 1) ? this.signing_filter_count = 0 :
                                                        (status == 2) ? this.processing_filter_count = 0 :
                                                            (status == 3) ? this.completed_filter_count = 0:
                                                                this.cancelled_filter_count = 0;
        },
        displayAppliedFilterByStatus: function(status){
            this.to_search.template = this.search.template
            this.to_search.account_manager = this.search.account_manager
            this.$refs._CDateRange.range.start = this.search.date_from
            this.$refs._CDateRange.range.end = this.search.date_to
            this.to_search.reference_number = this.search.reference_number
        },
        storeSelectedFilterByStatus: function(status){
            this.search.template = this.to_search.template;
            this.search.account_manager = this.to_search.account_manager;
            this.search.date_from = this.to_search.date_from;
            this.search.date_to = this.to_search.date_to;
            this.search.reference_number = this.to_search.reference_number;
        },
        handleParentDataUpdate({ key, value }) {
            if (!this.parentData[key]) {
                this.$set(this.parentData, key, {});
            }
            this.parentData[key] = { ...this.parentData[key], ...value };
        },
        handleDataLoadedByStatus(status){
            if(status == 0 && Object.keys(this.parentData.draftData).length != 0){
                this.$refs.document_lists.draft_data_loaded = true;

            }else if(status == 1 && Object.keys(this.parentData.signingData).length != 0){
                this.$refs.document_lists.signing_data_loaded = true;

            }else if(status == 2 && Object.keys(this.parentData.processingData).length != 0){
                this.$refs.document_lists.processing_data_loaded = true;

            }else if(status == 3 && Object.keys(this.parentData.completedData).length != 0){
                this.$refs.document_lists.completed_data_loaded = true;

            }else if(status == 4 && Object.keys(this.parentData.cancelledData).length != 0){
                this.$refs.document_lists.cancelled_data_loaded = true;

            }
        },
        clearChildData(){
            if (this.$refs.document_lists) {
                this.$refs.document_lists.draft_data = {};
                this.$refs.document_lists.signing_data = {};
                this.$refs.document_lists.processing_data = {};
                this.$refs.document_lists.completed_data = {};
                this.$refs.document_lists.cancelled_data = {};
            }
        },
        clearParentDataByStatus(status){
            if(status == 0){
                this.parentData.draftData = {};
            }else if(status == 1){
                this.parentData.signingData = {};
            }else if(status == 2){
                this.parentData.processingData = {};
            }else if(status == 3){
                this.parentData.completedData = {};
            }else if(status == 4){
                this.parentData.cancelledData = {};
            }
        },
        refreshData(){
            let isRefNumberEmpty = !this.to_search.reference_number || this.to_search.reference_number.trim().length === 0;

            if(this.current_tab == 0){
                this.parentData.draftData = {}
                this.$refs.document_lists.draft_data = {}

                this.$refs.document_lists.draft_data_loaded = false;
                this.$refs.document_lists.slug = 'draft-list'

            }else if(this.current_tab == 1){
                this.parentData.signingData = {}
                this.$refs.document_lists.signing_data = {}

                this.$refs.document_lists.signing_data_loaded = false;
                this.$refs.document_lists.slug = 'signing-list'

            }else if(this.current_tab == 2){
                this.parentData.processingData = {}
                this.$refs.document_lists.processing_data = {}

                this.$refs.document_lists.processing_data_loaded = false;
                this.$refs.document_lists.slug = 'processing-list'

            }else if(this.current_tab == 3){
                this.parentData.completedData = {}
                this.$refs.document_lists.completed_data = {}

                this.$refs.document_lists.completed_data_loaded = false;
                this.$refs.document_lists.slug = 'completed-list'

            }else if(this.current_tab == 4){
                this.parentData.cancelledData = {}
                this.$refs.document_lists.cancelled_data = {}

                this.$refs.document_lists.cancelled_data_loaded = false;
                this.$refs.document_lists.slug = 'cancelled-list'
            }

            if(!this.to_search.form &&  this.to_search.date_from==null && this.to_search.date_to == null && this.to_search.account_manager==null && this.to_search.template==null && isRefNumberEmpty) {
                this.$refs.document_lists.current_page == 1 ? this.$refs.document_lists.getDocuments() :  this.$refs.document_lists.current_page = 1;
                
            }else{
                this.to_search.reference_number ? this.searchData() : this.searchDataAdvanced();
            }
        },
        filterParentData(status, removed_row = null, currentPage){
            this.is_updated_status =  removed_row == null ? false : true;

            if(status == 0){
                const draftDataArray = Object.values(this.parentData.draftData[currentPage])
                this.parentData.draftData[currentPage] = draftDataArray.filter(item => item.id !== removed_row);

            }else if(status == 1){
                const signingDataArray = Object.values(this.parentData.signingData[currentPage])
                this.parentData.signingData[currentPage] = signingDataArray.filter(item => item.id !== removed_row)
            }else if(status == 2){
                const processingDataArray = Object.values(this.parentData.processingData[currentPage])
                this.parentData.processingData[currentPage] = processingDataArray.filter(item => item.id !== removed_row)

            }else if(status == 3){
                const completedDataArray = Object.values(this.parentData.completedData[currentPage])
                this.parentData.completedData[currentPage] = this.isFromList ? this.parentData.completedData[currentPage] : completedDataArray.filter(item => item.id !== removed_row)
            }else if(status == 4){
                const cancelledDataArray = Object.values(this.parentData.cancelledData[currentPage]);
                this.parentData.cancelledData[currentPage] = cancelledDataArray.filter(item => item.id !== removed_row)
            }
        },
        clearParentData(){
            if(this.parentData){
                this.parentData.draftData = {};
                this.parentData.signingData = {};
                this.parentData.processingData = {};
                this.parentData.completedData = {};
                this.parentData.cancelledData = {};
            }
        },
        isAdvancedFiltered(){

            if(this.to_search.account_manager == null && this.to_search.date_from == null
                && this.to_search.date_from == null && this.to_search.template == null){
                return false
            }else{
                return true;
            }
        },
        isRefFiltered(){
            let isRefNumberEmpty = !this.to_search.reference_number || this.to_search.reference_number.trim().length === 0;

            if(isRefNumberEmpty){
                return false
            }else{
                return true;
            }
        }
    },
    watch: {
        current_tab: function (value) {
            if(this.$route.query.reference_id != undefined) {
                this.change_view = true;
                this.$nextTick(function() {
                    this.$refs.document_details.routing.id = this.$route.query.reference_id
                    this.$refs.document_details.parent_tab = 1
                    this.$refs.document_details.parent_tab_current_page = 1
                    this.$refs.document_details.getRoutingDetails();
                })
            }
        },
        '$route' (to, from) {
            if(to.query.tab != undefined) {
                this.current_tab = (to.query.tab == 'signing-list') ? 1 
                    : (to.query.tab == 'processing-list') ? 2 
                        : (to.query.tab == 'completed-list') ? 3 
                            : (to.query.tab == 'cancelled-list') ? 4 
                                : this.current_tab;
            }
            
            if(to.query.tab != undefined && to.query.reference_id != undefined) {
                this.change_view = true;
                this.$nextTick(function() {
                    this.$refs.document_details.routing.id = this.$route.query.reference_id
                    this.$refs.document_details.parent_tab = 1
                    this.$refs.document_details.parent_tab_current_page = 1
                    this.$refs.document_details.getRoutingDetails();
                })
            }

        },
        list_loaded: function(value) {
            if(value) {
                this.submit_advance_filter = false;
                this.custom_filter = false;
            }
        }
    }
}
</script>
